import { isAvailable as isAvailableNeighbourhood } from 'erpcore/screens/Projects/screens/ProjectNeighbourhood';
import { isAvailable as isAvailableBuildingDesign } from 'erpcore/screens/Projects/screens/ProjectBuildingDesign';
import { isAvailable as isAvailableFloorplans } from 'erpcore/screens/Projects/screens/ProjectFloorplans';
import { isAvailable as isAvailableHomeTour } from 'erpcore/screens/Projects/screens/ProjectHomeTour';
import { isAvailable as isAvailableFeatureAndOptions } from 'erpcore/screens/Projects/screens/ProjectFeatureAndOptions';
import { isAvailable as isAvailableDeveloper } from 'erpcore/screens/Projects/screens/ProjectDeveloper';
import { isAvailable as isAvailablePurchaseCalculator } from 'erpcore/screens/Projects/screens/ProjectPurchaseCalculator';
import {
    getSessionProspectSpecificDocuments,
    isAvailable as isAvailableDocuments
} from 'erpcore/screens/Projects/screens/ProjectDocuments';

const projectSectionMap = {
    'project-home': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    'demo-tour-overview': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    neighbourhood: {
        title: 'Neighbourhood',
        erpKey: 'neighbourhood'
    },
    'building-design': {
        title: 'Building design',
        erpKey: 'building-design'
    },
    floorplans: {
        title: 'Floor plans',
        erpKey: 'floor-plans'
    },
    'home-tour': {
        title: 'Home tour',
        erpKey: 'home-tour'
    },
    'features-and-options': {
        title: 'Features & Options',
        erpKey: 'features-and-options'
    },
    developer: {
        title: 'Developer',
        erpKey: 'developer'
    },
    'purchase-calculator': {
        title: 'Purchase Calculator',
        erpKey: 'purchase-calculator'
    },
    documents: {
        title: 'Documents',
        erpKey: 'documents'
    }
};

const getSectionFromPath = pathname => {
    if (pathname) {
        const foundSingleProjectFirstLevelSubPage = pathname.match(
            // /(?<=\/session\/presentation\/(\d+)\/project\/(\d+)\/)([^/]*|.*)/ positive lookbehind ES2018 only
            /\/session\/presentation\/(?:\d+)\/project\/(?:\d+)\/([^/]*|.*)/
        );
        if (foundSingleProjectFirstLevelSubPage?.[1]) {
            return foundSingleProjectFirstLevelSubPage[1];
        }

        const foundSingleProjectHome = pathname.match(
            /\/session\/presentation\/(\d+)\/project\/(\d+)/
        );
        if (foundSingleProjectHome?.[1]) {
            return 'project-home';
        }

        const foundListing = pathname.match(/\/session\/presentation\/(\d+)/);
        if (foundListing?.[0]) {
            return 'session-project-list';
        }
    }
    return null;
};

const getProjectSectionsData = (projectData = null, prospectHash, sessionDatabyHash) => {
    if (!projectData) return null;

    return {
        'project-home': {
            isNavItem: true,
            isAvailable: true,
            icon: 'home',
            title: 'Home',
            sessionTitle: 'Home Page',
            route: '',
            erpKey: 'home-page'
        },
        'demo-tour-overview': {
            isNavItem: false,
            isAvailable: true,
            title: 'Interactive Home Tour',
            sessionTitle: 'Home Page',
            route: '/demo-tour-overview',
            erpKey: 'home-page'
        },
        neighbourhood: {
            isNavItem: true,
            isAvailable: isAvailableNeighbourhood(projectData),
            icon: 'hood',
            title: 'Neighbourhood',
            sessionTitle: 'Neighbourhood',
            route: '/neighbourhood',
            erpKey: 'neighbourhood'
        },
        'building-design': {
            isNavItem: true,
            isAvailable: isAvailableBuildingDesign(projectData),
            icon: 'building',
            title: 'Building Design',
            sessionTitle: 'Building Design',
            route: '/building-design',
            erpKey: 'building-design'
        },
        floorplans: {
            isNavItem: true,
            isAvailable: isAvailableFloorplans(projectData),
            icon: 'floorplan',
            title: 'Floor Plans',
            sessionTitle: 'Floor Plans',
            route: '/floorplans',
            erpKey: 'floor-plans'
        },
        'home-tour': {
            isNavItem: true,
            isAvailable: isAvailableHomeTour(projectData),
            icon: 'tour',
            title: 'Home Tour',
            sessionTitle: 'Home Tour',
            route: '/home-tour',
            erpKey: 'home-tour'
        },
        'features-and-options': {
            isNavItem: true,
            isAvailable: isAvailableFeatureAndOptions(projectData),
            icon: 'features',
            title: 'Features & Options',
            sessionTitle: 'Features & Options',
            route: '/features-and-options',
            erpKey: 'features-and-options'
        },
        developer: {
            isNavItem: true,
            isAvailable: isAvailableDeveloper(projectData),
            icon: 'developer',
            title: 'Developer',
            sessionTitle: 'Developer',
            route: '/developer',
            erpKey: 'developer'
        },
        'purchase-calculator': {
            isNavItem: true,
            isAvailable: isAvailablePurchaseCalculator(projectData),
            icon: 'calculator',
            title: 'Purchase Calculator',
            sessionTitle: 'Purchase Calculator',
            route: '/purchase-calculator',
            erpKey: 'purchase-calculator'
        },
        documents: {
            isNavItem: true,
            isAvailable: isAvailableDocuments(
                projectData,
                getSessionProspectSpecificDocuments(projectData, prospectHash, sessionDatabyHash)
            ),
            icon: 'file',
            title: 'Documents',
            sessionTitle: 'Documents',
            route: '/documents',
            erpKey: 'documents'
        }
    };
};

const getLastAvailableSectionKey = (projectData = null) => {
    const projectSectionedData = getProjectSectionsData(projectData);

    let lastAvailableKey = null;

    const reversedSectionKeys = [...Object.keys(projectSectionedData)].reverse();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < reversedSectionKeys.length; i++) {
        const currentKey = reversedSectionKeys[i];
        if (
            projectSectionedData[currentKey].isNavItem &&
            projectSectionedData[currentKey].isAvailable
        ) {
            lastAvailableKey = reversedSectionKeys[i];
            break;
        }
    }

    return lastAvailableKey;
};

const getNextSection = (projectData = null, section) => {
    const projectSectionedData = getProjectSectionsData(projectData);
    const getNextKey = key => {
        const keys = Object.keys(projectSectionedData);
        const i = keys.indexOf(key);
        return i !== -1 && keys[i + 1];
    };

    const lastAvailableSectionKey = getLastAvailableSectionKey(projectData);

    if (section === lastAvailableSectionKey) {
        return projectSectionedData?.['project-home'];
    }

    const targetSection = projectSectionedData?.[getNextKey(section)];
    if (targetSection) {
        if (targetSection?.isNavItem && targetSection?.isAvailable) {
            return targetSection;
        }
        return getNextSection(projectData, getNextKey(section));
    }
    return null;
};

export { projectSectionMap, getSectionFromPath, getProjectSectionsData, getNextSection };
