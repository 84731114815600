import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import * as portals from 'react-reverse-portal';

import Button from 'erpcore/components/Button';
import Logo from 'erpcore/components/Layout/Logo';
import Image from 'erpcore/components/Image';
import Gallery from 'erpcore/components/Gallery';
import ProspectCounter from 'erpcore/components/Presentation/components/ProspectCounter';

import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import {
    getCountUnreadedChatMessages,
    getPresentationMyScreenShareActiveStatus,
    getShowChat
} from 'erpcore/screens/Presentation/Presentation.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';
import { getIdFromIri } from 'erpcore/utils/dto';
import './PresentationIntro.scss';
import PresentationChat from 'erpcore/components/Presentation/components/PresentationChat';

const PresentationIntro = ({
    // eslint-disable-next-line no-unused-vars
    sessionHelper,
    // eslint-disable-next-line no-unused-vars
    streamNames,
    connectionType,
    type,
    sessionData,
    getMyConnection,
    publishScreenshare,
    portalCameras,
    portalScreenPublisher,
    chatEnabled,
    getUserName,
    renderCameras
}) => {
    const dispatch = useDispatch();
    const isScreenshareActive = useSelector(getPresentationMyScreenShareActiveStatus);
    const showChat = useSelector(getShowChat);
    const countUnreadedChatMessages = useSelector(getCountUnreadedChatMessages);
    const [deviceSupportsScreenShare, setDeviceSupportsScreenShare] = useState(true);

    const { date, session_prospects: sessionProspects, sales_agent: salesAgent, projects = [] } = {
        ...sessionData
    };

    let prospectName = null;
    if (connectionType === 'one-to-one') {
        const { prospect } = { ...sessionProspects?.[0] };
        const { first_name: firstName = '', last_name: lastName = '' } = {
            ...prospect
        };
        prospectName = `${firstName} ${lastName}`;
    }

    const startDemo = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_PHASE,
                phase: 'in-progress'
            });
        });
    };

    useEffect(() => {
        if (window.OT) {
            window.OT.checkScreenSharingCapability(response => {
                setDeviceSupportsScreenShare(!!response?.supported);
            });
        }
    }, []);

    const toggleTabs = () => {
        dispatch({
            type: presentationActions.SET_SHOW_CHAT,
            response: !showChat
        });
    };

    const displayProjects = false;
    // const displayProjects = !streamNames?.length || streamNames.findIndex(streamName => streamName !== 'screenshare');

    const renderProjects = () => {
        if (!projects?.length) {
            return null;
        }

        if (projects?.length > 1) {
            return (
                <div className="presentation-intro__projects">
                    <p className="presentation-intro__projects-title">Properties</p>
                    <div className="presentation-intro__projects-list">
                        {projects.map(project => {
                            const {
                                iri,
                                name,
                                development_project_logo: developmentProjectLogo,
                                street,
                                city,
                                state,
                                full_address: fullAddress
                            } = {
                                ...project
                            };
                            const { name: cityName } = { ...city };
                            const { name: stateName } = { ...state };

                            let location = fullAddress;
                            if (fullAddress && cityName) {
                                location = (
                                    <>
                                        {street}
                                        <br />
                                        {cityName}
                                        {stateName ? `, ${stateName}` : ''}
                                    </>
                                );
                            }

                            return (
                                <div key={iri} className="presentation-intro__projects-list-item">
                                    <div className="presentation-intro__projects-list-item-logo">
                                        {!!developmentProjectLogo?.iri && (
                                            <Image
                                                iri={developmentProjectLogo?.iri}
                                                version="small"
                                                className="presentation-intro__projects-list-item-logo-image"
                                            />
                                        )}
                                    </div>
                                    <div className="presentation-intro__projects-list-item-data">
                                        {!!name && (
                                            <h3 className="presentation-intro__projects-list-item-data-title">
                                                {name}
                                            </h3>
                                        )}
                                        {!!location && (
                                            <p className="presentation-intro__projects-list-item-data-location">
                                                {location}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        const { main_gallery: mainGallery } = { ...projects?.[0] };

        return (
            <div className="presentation-intro__project">
                <Gallery
                    fullscreen
                    hasThumbs={false}
                    hasHeaderContent={false}
                    hasCaption={false}
                    hasMagnifier={false}
                    hasFullFullscreen={false}
                    name="sessionProjectGallery"
                    imagesIris={getImagesIrisFromObjects(mainGallery)}
                />
            </div>
        );
    };

    const renderProjectSidebar = () => {
        if (!projects?.length) return null;

        const project = projects[0];

        const {
            name,
            development_project_logo: developmentProjectLogo,
            street,
            city,
            state,
            full_address: fullAddress
        } = {
            ...project
        };
        const { name: cityName } = { ...city };
        const { name: stateName } = { ...state };

        let location = fullAddress;
        if (fullAddress && cityName) {
            location = (
                <>
                    {street}
                    <br />
                    {cityName}
                    {stateName ? `, ${stateName}` : ''}
                </>
            );
        }

        return (
            <div className="presentation-sidebar__info-row presentation-sidebar__info-row--border">
                <span className="presentation-intro__sidebar-data-item-title">Property</span>
                {!!name && <p className="presentation-sidebar__info-label-large">{name}</p>}
                {!!location && <p className="presentation-sidebar__info-label">{location}</p>}
                {!!developmentProjectLogo?.iri && (
                    <Image
                        iri={developmentProjectLogo?.iri}
                        version="small"
                        className="presentation-sidebar__info-img"
                    />
                )}
            </div>
        );
    };

    // const showCameraStreams = (type === 'client' && !displayProjects) || (type === 'admin' && connectionType === 'one-to-one' && !!streamNames?.length);

    // const showCameraPublisher = type === 'admin' || (type === 'client' && connectionType === 'one-to-one');

    return (
        <>
            <div className="presentation-intro">
                <div className="presentation-intro__sidebar">
                    {chatEnabled && (
                        <div className="presentation-intro__sidebar-tabs">
                            <button
                                type="button"
                                className={`presentation-intro__sidebar-tabs-tab ${!showChat &&
                                    'presentation-intro__sidebar-tabs-tab--active'}`}
                                onClick={() => (!showChat ? false : toggleTabs())}
                            >
                                SESSION DETAILS
                            </button>
                            <button
                                type="button"
                                className={`presentation-intro__sidebar-tabs-tab ${showChat &&
                                    'presentation-intro__sidebar-tabs-tab--active'}`}
                                onClick={() => (showChat ? false : toggleTabs())}
                            >
                                CHAT{' '}
                                {countUnreadedChatMessages > 0 ? (
                                    <span className="presentation-intro__sidebar-tabs-tab-unread-messages">
                                        {countUnreadedChatMessages}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </button>
                        </div>
                    )}
                    <div
                        className={`presentation-intro__sidebar-data ${
                            showChat && chatEnabled
                                ? 'presentation-intro__sidebar-data--full-height'
                                : ''
                        }`}
                    >
                        {(!showChat || !chatEnabled) && (
                            <>
                                {!!prospectName && (
                                    <p className="presentation-intro__sidebar-data-item">
                                        <span className="presentation-intro__sidebar-data-item-title">
                                            Presentation For
                                        </span>
                                        <span className="presentation-intro__sidebar-data-item-value">
                                            {prospectName}
                                        </span>
                                    </p>
                                )}
                                {!!date && (
                                    <p className="presentation-intro__sidebar-data-item">
                                        <span className="presentation-intro__sidebar-data-item-title">
                                            Scheduled for
                                        </span>
                                        <span className="presentation-intro__sidebar-data-item-value">
                                            {moment(date).format('MMM Do, YYYY h:mma')}
                                        </span>
                                    </p>
                                )}
                                {type === 'client' && !!salesAgent && (
                                    <div className="presentation-intro__sidebar-data-item">
                                        <span className="presentation-intro__sidebar-data-item-title">
                                            Sales agent
                                        </span>
                                        <span className="presentation-intro__sidebar-data-item-user">
                                            {!!salesAgent?.image && (
                                                <div className="presentation-intro__sidebar-data-item-user-logo">
                                                    <Image
                                                        iri={salesAgent?.image}
                                                        version="small"
                                                        className="presentation-intro__sidebar-data-item-user-logo-image"
                                                    />
                                                </div>
                                            )}
                                            <div className="presentation-intro__sidebar-data-item-user-data">
                                                <p className="presentation-intro__sidebar-data-item-user-data-title">
                                                    {salesAgent.first_name || ''}{' '}
                                                    {salesAgent.last_name || ''}
                                                </p>
                                                {!!salesAgent.email && (
                                                    <p className="presentation-intro__sidebar-data-item-user-data-email">
                                                        <a
                                                            href={`mailto:${salesAgent.email}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {salesAgent.email}
                                                        </a>
                                                    </p>
                                                )}
                                                {!!salesAgent.phone_number && (
                                                    <p className="presentation-intro__sidebar-data-item-user-data-telephone">
                                                        {salesAgent.phone_number}
                                                    </p>
                                                )}
                                            </div>
                                        </span>
                                    </div>
                                )}
                                {type === 'client' &&
                                    projects?.length === 1 &&
                                    renderProjectSidebar()}
                                {type === 'client' && (
                                    <ProspectCounter
                                        type={type}
                                        getMyConnection={getMyConnection}
                                    />
                                )}
                            </>
                        )}

                        {showChat && chatEnabled && (
                            <PresentationChat
                                boundToContainer
                                sessionHelper={sessionHelper}
                                sessionData={sessionData}
                                getUserName={getUserName}
                            />
                        )}
                    </div>

                    {type === 'admin' && (
                        <div className="presentation-intro__sidebar-call">
                            <h4 className="presentation-intro__sidebar-call-title">INSTRUCTIONS</h4>
                            <ol className="presentation-intro__sidebar-call-list">
                                <li>
                                    <Button
                                        external
                                        variation="link"
                                        label="Click here"
                                        href={`/session/presentation/${getIdFromIri(
                                            sessionData?.iri
                                        )}`}
                                    />{' '}
                                    to open Presentation View Window
                                </li>
                                {!!deviceSupportsScreenShare && (
                                    <li data-completed={isScreenshareActive ? '1' : '0'}>
                                        <Button
                                            variation="link"
                                            label="Click here"
                                            onClick={() => publishScreenshare()}
                                        />{' '}
                                        to share Presentation View Window
                                    </li>
                                )}
                                {!deviceSupportsScreenShare && (
                                    <li data-completed={isScreenshareActive ? '1' : '0'}>
                                        <span
                                            style={{
                                                textDecoration: 'line-through',
                                                pointerEvents: 'none'
                                            }}
                                        >
                                            <Button
                                                variation="link"
                                                label="Click here"
                                                onClick={() => publishScreenshare()}
                                            />{' '}
                                            to share Presentation View Window
                                        </span>
                                    </li>
                                )}
                                <li>
                                    Make sure you adjust the window width to match the prospect’s
                                    screen dimensions as close as possible.
                                </li>
                            </ol>
                            <portals.OutPortal node={portalScreenPublisher} />
                            <Button
                                onClick={startDemo}
                                // disabled={!isScreenshareActive}
                                dummyDisabled={!isScreenshareActive}
                                label="START DEMO"
                            />
                            <ProspectCounter type={type} getMyConnection={getMyConnection} />
                        </div>
                    )}
                </div>
                {!!renderCameras && (
                    <div className="presentation-intro__call">
                        <div className="presentation-intro__call-cameras">
                            {type === 'client' && !!displayProjects && renderProjects()}
                            <portals.OutPortal
                                node={portalCameras}
                                // subscriberClassName="presentation-intro__call-cameras-subscriber"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="presentation-intro__footer-logo">
                <Logo />
            </div>
        </>
    );
};

PresentationIntro.defaultProps = {
    connectionType: null,
    type: 'client',
    sessionData: null,
    sessionHelper: null,
    streamNames: null,
    getMyConnection: () => {},
    publishScreenshare: () => {},
    portalCameras: null,
    portalScreenPublisher: null,
    chatEnabled: false,
    getUserName: () => {},
    renderCameras: false
};

PresentationIntro.propTypes = {
    connectionType: PropTypes.string,
    type: PropTypes.oneOf(['client', 'admin']),
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    streamNames: PropTypes.oneOfType([PropTypes.array]),
    getMyConnection: PropTypes.func,
    publishScreenshare: PropTypes.oneOfType([PropTypes.func]),
    portalCameras: PropTypes.oneOfType([PropTypes.object]),
    portalScreenPublisher: PropTypes.oneOfType([PropTypes.object]),
    chatEnabled: PropTypes.bool,
    getUserName: PropTypes.func,
    renderCameras: PropTypes.bool
};

export default PresentationIntro;
