import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { updateSyncErrors } from 'redux-form';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import './TextEditor.scss';

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            initialized: false
        };

        this.getEditorState = this.getEditorState.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { initialized } = state;
        if (!initialized && props.meta && props.meta.initial) {
            const blocksFromHtml = htmlToDraft(props.meta.initial || '');
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            return {
                editorState: EditorState.createWithContent(contentState),
                initialized: true
            };
        }
        return {};
    }

    getSnapshotBeforeUpdate(prevProps) {
        const { input } = this.props;
        const { input: prevInput } = prevProps;

        if (input.value !== prevInput.value) {
            if (!input.value) {
                return this.setState({
                    editorState: EditorState.createEmpty()
                });
            }
        }

        return null;
    }

    getEditorState() {
        const { editorState } = this.state;
        return editorState;
    }

    onEditorStateChange = editorState => {
        const { input, meta } = this.props;
        const { form, dispatch } = meta;

        this.setState({
            editorState
        });

        let reduxFormValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        reduxFormValue = String(reduxFormValue).trim();

        if (form && dispatch && reduxFormValue === '<p></p>') {
            dispatch(
                updateSyncErrors(form, {
                    content: 'is required'
                })
            );
        }
        input.onChange(reduxFormValue);
    };

    render() {
        const { meta, input, fieldAttr, fieldProps, editorProps } = this.props;
        const { label } = { ...fieldProps };

        return (
            <div
                className={`text-editor${meta.touched && meta.error ? ` text-editor--error` : ''}${
                    fieldAttr.required === true ? ' text-editor--required' : ''
                }${label ? ' text-editor--with-label' : ''}`}
            >
                {label && <span className="text-editor__label">{label}</span>}
                <Editor
                    editorState={this.getEditorState()}
                    toolbarClassName="text-editor__toolbar"
                    wrapperClassName="text-editor__wrapper"
                    editorClassName="text-editor__editor"
                    onEditorStateChange={this.onEditorStateChange}
                    onFocus={() => input.onFocus()}
                    onBlur={() => input.onBlur()}
                    {...editorProps}
                />
                {meta.touched && meta.error && (
                    <span className="text-editor__error">
                        {meta.error.message ? meta.error.message : meta.error}
                    </span>
                )}
            </div>
        );
    }
}
TextEditor.defaultProps = {
    input: {},
    meta: {},
    fieldAttr: {},
    fieldProps: {},
    editorProps: {}
};
TextEditor.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    editorProps: PropTypes.oneOfType([PropTypes.object])
};
export default TextEditor;
