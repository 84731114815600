import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import Svg from 'erpcore/components/Svg';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectFetching, getProjectData } from 'erpcore/screens/Projects/Projects.selectors';

import './ProjectPurchaseCalculator.scss';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';

export const isAvailable = projectData => {
    const { purchase_calculators: purchaseCalculators } = { ...projectData };

    return !!purchaseCalculators && !!purchaseCalculators?.length;
};

const ProjectPurchaseCalculator = () => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);
    const { purchase_calculators: purchaseCalculators } = { ...projectData };

    const pageTitle = 'Purchase Calculator';

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="purchase-calculator" />
            <MobileQuickNav section="purchase-calculator" />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <div className="purchase-calculator">
                    <div className="purchase-calculator__item-list">
                        {purchaseCalculators?.map(calculator => {
                            return (
                                <a
                                    href={calculator.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="purchase-calculator__item"
                                >
                                    <div className="purchase-calculator__item-content">
                                        <Svg
                                            icon="excel"
                                            className="purchase-calculator__item-icon"
                                        />
                                        <span className="purchase-calculator__item-name">
                                            {calculator.title}
                                        </span>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProjectPurchaseCalculator.defaultProps = {};

ProjectPurchaseCalculator.propTypes = {};

export default ProjectPurchaseCalculator;
