import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getPresentationMyScreenShareActiveStatus } from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import Button from 'erpcore/components/Button';
import { getIdFromIri } from 'erpcore/utils/dto';
import * as portals from 'react-reverse-portal';
import './PresentationStart.scss';

const PresentationStart = ({
    // eslint-disable-next-line no-unused-vars
    sessionHelper,
    sessionData,
    publishScreenshare,
    portalScreenPublisher,
    showScreenPublisher
}) => {
    const dispatch = useDispatch();
    const [deviceSupportsScreenShare, setDeviceSupportsScreenShare] = useState(true);
    const isScreenshareActive = useSelector(getPresentationMyScreenShareActiveStatus);

    useEffect(() => {
        if (window.OT) {
            window.OT.checkScreenSharingCapability(response => {
                setDeviceSupportsScreenShare(!!response?.supported);
            });
        }
    }, []);

    const startDemo = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_PHASE,
                phase: 'in-progress'
            });
        });
    };

    return (
        <div className="presentation-start">
            <h4 className="presentation-start__title">INSTRUCTIONS</h4>
            <ol className="presentation-start__list">
                <li>
                    <Button
                        external
                        variation="link"
                        label="Click here"
                        href={`/session/presentation/${getIdFromIri(sessionData?.iri)}`}
                    />{' '}
                    to open Presentation View Window
                </li>
                {!!deviceSupportsScreenShare && (
                    <li data-completed={isScreenshareActive ? '1' : '0'}>
                        <Button
                            variation="link"
                            label="Click here"
                            onClick={() => publishScreenshare()}
                        />{' '}
                        to share Presentation View Window
                    </li>
                )}
                {!deviceSupportsScreenShare && (
                    <li data-completed={isScreenshareActive ? '1' : '0'}>
                        <span
                            style={{
                                textDecoration: 'line-through',
                                pointerEvents: 'none'
                            }}
                        >
                            <Button
                                variation="link"
                                label="Click here"
                                onClick={() => publishScreenshare()}
                            />{' '}
                            to share Presentation View Window
                        </span>
                    </li>
                )}
                <li>
                    Make sure you adjust the window width to match the prospect’s screen dimensions
                    as close as possible.
                </li>
            </ol>
            {!!showScreenPublisher && <portals.OutPortal node={portalScreenPublisher} />}
            <Button
                className="presentation-start__button"
                onClick={startDemo}
                // disabled={!isScreenshareActive}
                dummyDisabled={!isScreenshareActive}
                label="START DEMO"
            />
        </div>
    );
};

PresentationStart.defaultProps = {
    sessionHelper: null,
    sessionData: null,
    publishScreenshare: () => {},
    portalScreenPublisher: null,
    showScreenPublisher: false
};

PresentationStart.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    publishScreenshare: PropTypes.oneOfType([PropTypes.func]),
    portalScreenPublisher: PropTypes.oneOfType([PropTypes.object]),
    showScreenPublisher: PropTypes.bool
};

export default PresentationStart;
