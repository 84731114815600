import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { OTPublisher } from 'opentok-react';
import './PresentationCameraPublisher.scss';
import Button from 'erpcore/components/Button';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import {
    getDefaultCameraState,
    getDefaultMicrophoneState,
    getIsOtherAdminSharingCamera
} from 'erpcore/screens/Presentation/Presentation.selectors';

const PresentationCameraPublisher = ({ type, sessionHelper, userName }) => {
    const dispatch = useDispatch();
    const otPublisher = useRef();
    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);
    const defaultCameraState = useSelector(getDefaultCameraState);
    const defaultMicrophoneState = useSelector(getDefaultMicrophoneState);
    // const [isPublisherCameraActive, setIsPublisherCameraActive] = useState(defaultCameraState);
    // const [isPublisherMicActive, setIsPublisherMicActive] = useState(defaultMicrophoneState);
    const [otherAdminCameraStreams, setOtherAdminCameraStreams] = useState(['initial']);
    const isOtherAdminSharingCameraStore = useSelector(getIsOtherAdminSharingCamera);

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    const getOtherAdminCameraStreams = () => {
        const streams = sessionHelper?.streams;
        if (streams?.length) {
            const otherStreams = streams.filter(stream => {
                const streamConnectionCustomData = getConnectionCustomData(stream?.connection);
                return (
                    stream?.videoType !== 'screen' &&
                    stream?.name !== 'screenshare' &&
                    streamConnectionCustomData?.connectionUserType === 'admin'
                );
            });
            return otherStreams;
        }
        return null;
    };

    const runSetOtherAdminCameraStreams = () => {
        setOtherAdminCameraStreams(getOtherAdminCameraStreams());
    };

    const takeOverCamera = () => {
        const allStreams = getOtherAdminCameraStreams();
        if (allStreams?.length) {
            allStreams.forEach(item => {
                item.destroy();
            });
        }
    };

    const getPublisher = () => {
        return otPublisher?.current?.getPublisher();
    };

    /*
    const publishCamera = () => {
        if (getPublisher()) {
            getPublisher().publishVideo(!getPublisher()?.stream?.hasVideo);
        }
    };

    const publishAudio = () => {
        if (getPublisher()) {
            getPublisher().publishAudio(!getPublisher()?.stream?.hasAudio);
        }
    };
    */

    const forceMute = () => {
        if (getPublisher()) {
            getPublisher().publishAudio(false);
        }
    };

    const setCameraStreamCreated = (nextState = true) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_CAMERA_STREAM_CREATED,
                data: nextState
            });
        });
    };

    const setIsOtherAdminSharingCamera = data => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_IS_OTHER_ADMIN_SHARING_CAMERA,
                data
            });
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            runSetOtherAdminCameraStreams();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const isOtherAdminSharingCamera = type === 'admin' && !!otherAdminCameraStreams?.length;
    const isOtherAdminSharingCameraInitialized = otherAdminCameraStreams?.[0] !== 'initial';

    if (isOtherAdminSharingCameraStore !== isOtherAdminSharingCamera) {
        setIsOtherAdminSharingCamera(isOtherAdminSharingCamera);
    }

    useEffect(() => {
        if (sessionHelper?.session && !sessionEventsCreated) {
            sessionHelper.session.on('signal:muteSelf', () => {
                forceMute();
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'presentation.muteSelf' }
                });
            });

            setSessionEventsCreated(true);
        }
    }, [sessionHelper]);

    if (sessionHelper) {
        if (!isOtherAdminSharingCamera) {
            return (
                <>
                    <OTPublisher
                        session={sessionHelper.session}
                        className="presentation-camera-publisher"
                        ref={otPublisher}
                        eventHandlers={{
                            streamCreated: () => {
                                setCameraStreamCreated(true);
                            }
                        }}
                        properties={{
                            width: '100%',
                            height: '325px',
                            facingMode: 'user',
                            fitMode: 'contain',
                            mirror: true,
                            name: userName,
                            // resolution: '640x480',
                            resolution: '320x240',
                            publishVideo: defaultCameraState,
                            publishAudio: defaultMicrophoneState,
                            disableAudioProcessing: false,
                            showControls: true,
                            style: {
                                buttonDisplayMode: 'off',
                                nameDisplayMode: 'auto'
                            }
                        }}
                    />
                    {/*
                    <div className="presentation-camera-publisher__controls">
                        <Tooltip
                            direction="up"
                            content={
                                isPublisherMicActive ? 'Turn off microphone' : 'Turn on microphone'
                            }
                        >
                            <button
                                data-active={isPublisherMicActive ? '1' : '0'}
                                className="presentation-camera-publisher__controls-button"
                                type="button"
                                onClick={publishAudio}
                                aria-label={
                                    isPublisherMicActive
                                        ? 'Turn off microphone'
                                        : 'Turn on microphone'
                                }
                            >
                                <Svg
                                    className="presentation-camera-publisher__controls-button-icon"
                                    icon="callMicrophone"
                                />
                            </button>
                        </Tooltip>
                        <Tooltip
                            direction="up"
                            content={isPublisherCameraActive ? 'Turn off camera' : 'Turn on camera'}
                        >
                            <button
                                data-active={isPublisherCameraActive ? '1' : '0'}
                                className="presentation-camera-publisher__controls-button"
                                type="button"
                                onClick={publishCamera}
                                aria-label={
                                    isPublisherCameraActive ? 'Turn off camera' : 'Turn on camera'
                                }
                            >
                                <Svg
                                    className="presentation-camera-publisher__controls-button-icon"
                                    icon="callCamera"
                                />
                            </button>
                        </Tooltip>
                    </div>
                    */}
                </>
            );
        }

        if (isOtherAdminSharingCameraInitialized) {
            return (
                <div className="presentation-camera-publisher-takeover">
                    <p className="presentation-camera-publisher-takeover__text">
                        Your camera and microphone are currently controlled from another window.
                    </p>
                    <Button
                        size="small"
                        className="presentation-camera-publisher-takeover__button"
                        onClick={() => takeOverCamera()}
                        label="Take over"
                    />
                </div>
            );
        }
    }

    return null;
};

PresentationCameraPublisher.defaultProps = {
    type: null,
    sessionHelper: null,
    userName: 'Anonymous'
};

PresentationCameraPublisher.propTypes = {
    type: PropTypes.oneOf(['client', 'admin']),
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    userName: PropTypes.string
};

export default PresentationCameraPublisher;
