import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';
import { OTSubscriber } from 'opentok-react';
import './PresentationScreenShareSubscriber.scss';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

const PresentationScreenShareSubscriber = ({ sessionHelper }) => {
    const dispatch = useDispatch();
    const [adminScreenStream, setAdminScreenStream] = useState(null);
    const [adminScreenStreamCreationTime, setAdminScreenStreamCreationTime] = useState(null); // triggers rerender

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    const setMyScreenSize = data => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_MY_SCREEN_SIZE,
                myScreenSize: data
            });
        }).catch(error => ({ error }));
    };

    const getAdminScreenStream = () => {
        const streams = sessionHelper?.streams;
        if (streams?.length) {
            const targetStream = streams.find(stream => {
                const streamConnectionCustomData = getConnectionCustomData(stream?.connection);
                return (
                    stream?.videoType !== 'camera' &&
                    stream?.name === 'screenshare' &&
                    streamConnectionCustomData?.connectionUserType === 'admin'
                );
            });
            return targetStream;
        }
        return null;
    };

    const runSetAdminScreenStream = () => {
        const targetStreamScreen = getAdminScreenStream();
        setAdminScreenStreamCreationTime(targetStreamScreen?.id);
        setAdminScreenStream({
            [targetStreamScreen?.id]: targetStreamScreen
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            runSetAdminScreenStream();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="presentation-screenshare">
            <div className="presentation-screenshare__content">
                <ResizeObserver
                    onResize={rect => {
                        setMyScreenSize({
                            videoSpaceWidth: Math.round(rect.width),
                            videoSpaceHeight: Math.round(rect.height)
                        });
                    }}
                />
                {!!adminScreenStream?.[adminScreenStreamCreationTime] && (
                    <OTSubscriber
                        className="presentation-screenshare__video"
                        session={sessionHelper.session}
                        stream={adminScreenStream?.[adminScreenStreamCreationTime]}
                        properties={{
                            name: 'screenshare-view',
                            width: '100%',
                            height: '100%',
                            fitMode: 'contain',
                            audioVolume: 100,
                            // insertDefaultUI: false,
                            showControls: false
                        }}
                    />
                )}
            </div>
        </div>
    );
};

PresentationScreenShareSubscriber.defaultProps = {
    sessionHelper: null
};

PresentationScreenShareSubscriber.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default PresentationScreenShareSubscriber;
