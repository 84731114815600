import Main from 'erpcore/components/Layout/Main';

const presentationAdminLayout = {
    data: [
        {
            slotName: 'main',
            component: Main,
            position: 0
        }
    ]
};

export default presentationAdminLayout;
