import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'erpcore/components/Layout/Logo';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import './PresentationEnded.scss';
import PropTypes from 'prop-types';
import Image from 'erpcore/components/Image';
import moment from 'moment-timezone';

const PresentationEnded = ({ sessionData, showSessionInfo, title, children }) => {
    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;

    const renderSessionData = () => {
        if (!sessionData?.iri) return null;

        const { date, sales_agent: salesAgent } = {
            ...sessionData
        };

        return (
            <div className="projects__session">
                {!!date && (
                    <p className="projects__session-item">
                        <span className="projects__session-item-title">Demo Session</span>
                        <span className="projects__session-item-value">
                            {moment(date).format('MMM Do, YYYY h:mma')}
                        </span>
                    </p>
                )}
                {!!salesAgent && (
                    <div className="projects__session-item">
                        <span className="projects__session-item-title">Sales agent</span>
                        <span className="projects__session-item-user">
                            {!!salesAgent?.image && (
                                <div className="projects__session-item-user-logo">
                                    <Image
                                        iri={salesAgent?.image}
                                        version="small"
                                        className="projects__session-item-user-logo-image"
                                    />
                                </div>
                            )}
                            <div className="projects__session-item-user-data">
                                <p className="projects__session-item-user-data-title">
                                    {salesAgent.first_name || ''} {salesAgent.last_name || ''}
                                </p>
                                {!!salesAgent.email && (
                                    <p className="projects__session-item-user-data-email">
                                        <a
                                            href={`mailto:${salesAgent.email}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {salesAgent.email}
                                        </a>
                                    </p>
                                )}
                                {!!salesAgent.phone_number && (
                                    <p className="projects__session-item-user-data-telephone">
                                        {salesAgent.phone_number}
                                    </p>
                                )}
                            </div>
                        </span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="presentation-ended">
            <div className="presentation-ended__inner">
                <Link to={`${routePrefix}/`} className="presentation-ended__logo">
                    <Logo />
                </Link>
                {!!title && <h3 className="presentation-ended__title">{title}</h3>}
                <div className="presentation-ended__content">{children}</div>
                {!!showSessionInfo && renderSessionData()}
            </div>
        </div>
    );
};

PresentationEnded.defaultProps = {
    sessionData: null,
    showSessionInfo: false,
    title: null,
    children: null
};

PresentationEnded.propTypes = {
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    showSessionInfo: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default PresentationEnded;
