import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import './PresentationChat.scss';
import { reduxForm, Field, reset } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import ElementLoader from 'erpcore/components/ElementLoader';
import {
    getChatData,
    getShowChat,
    getChatFetching
    //   getPresentationRtcData
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

const PresentationChat = ({
    submitting,
    handleSubmit,
    invalid,
    pristine,
    sessionHelper,
    getUserName,
    boundToContainer,
    sessionData
}) => {
    const dispatch = useDispatch();
    const chatData = useSelector(getChatData) || [];
    const showChat = useSelector(getShowChat);
    const chatFetching = useSelector(getChatFetching);
    const refMessagesContainer = useRef(null);
    const userData = JSON.parse(sessionHelper?.session?.connection?.data);

    let type = userData?.connectionUserType;
    let hash = null;
    if (type === 'guest' || type === 'spectator' || type === 'prospect') {
        hash = userData?.user;
        // TODO: cuz
        if (type === 'prospect') {
            type = 'guest';
            hash = sessionData.guest_hash;
        }
    }

    const fetchChatMessages = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                type: presentationActions.START_FETCHING_CHAT_MESSAGES,
                promise: { resolve, reject },
                sessionIri: sessionData?.iri,
                userType: type,
                hash
            })
        );
    };

    useEffect(() => {
        fetchChatMessages();
    }, []);

    useEffect(() => {
        if (refMessagesContainer?.current) {
            refMessagesContainer.current.scrollTop = refMessagesContainer?.current?.scrollHeight;
        }
    }, [showChat]);

    useEffect(() => {
        if (refMessagesContainer?.current) {
            refMessagesContainer.current.scrollTop = refMessagesContainer?.current?.scrollHeight;
        }
    }, [chatData]);

    const onSubmit = formData => {
        const message = {
            author: getUserName(),
            created_at: moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            content: formData.message
        };
        sessionHelper.session.signal({
            data: JSON.stringify(message),
            type: `chatSubmitted`
        });
        dispatch(reset('PresentationChatForm'));
        return new Promise((resolve, reject) =>
            dispatch({
                type: presentationActions.START_CREATE_CHAT_MESSAGE,
                promise: { resolve, reject },
                sessionIri: sessionData?.iri,
                message,
                userType: type,
                hash
            })
        );
    };

    return (
        <>
            {showChat ? (
                <div
                    className={`presentation-chat${
                        boundToContainer ? ' presentation-chat--bound-to-container' : ''
                    }`}
                >
                    {chatFetching && <ElementLoader overlay />}
                    {chatData.length > 0 && (
                        <div className="presentation-chat__messages" ref={refMessagesContainer}>
                            {/* orderBy(chatData, ['created_at'], ['asc']).map(message => ( */}
                            {orderBy(
                                chatData,
                                [
                                    item => {
                                        // eslint-disable-next-line camelcase
                                        return +moment(item?.created_at);
                                    }
                                ],
                                ['asc']
                            ).map(message => (
                                <div
                                    // eslint-disable-next-line camelcase
                                    key={`${message?.author}-${+moment(message?.created_at)}`}
                                    className={`presentation-chat__message${
                                        message.owner ? ' presentation-chat__message--own' : ''
                                    }`}
                                >
                                    <div className="presentation-chat__message-author">
                                        <span> {message.owner ? 'You' : message.author} </span>
                                        {/* eslint-disable-next-line camelcase */}
                                        <span>{moment(message?.created_at).format('h:mm A')}</span>
                                    </div>
                                    <div className="presentation-chat__message-content">
                                        {message.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="presentation-chat__form">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Row>
                                {submitting && <ElementLoader overlay />}
                                <Field
                                    name="message"
                                    fieldAttr={{
                                        id: 'message',
                                        placeholder: 'Send message to everyone',
                                        autoComplete: 'off'
                                    }}
                                    component={Text}
                                />
                                <Button
                                    loading={submitting}
                                    size="small"
                                    submit
                                    disabled={pristine || invalid}
                                    iconName="chatArrow"
                                    labelOnlyAria
                                />
                            </Form.Row>
                        </Form>
                    </div>
                </div>
            ) : null}
        </>
    );
};

PresentationChat.defaultProps = {
    sessionHelper: {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitting: false,
    getUserName: () => {},
    boundToContainer: false,
    sessionData: {}
};

PresentationChat.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    getUserName: PropTypes.func,
    boundToContainer: PropTypes.bool,
    sessionData: PropTypes.oneOfType([PropTypes.object])
};

export default reduxForm({
    form: 'PresentationChatForm'
})(PresentationChat);
