import { createSelector } from 'reselect';
import { getImageSrc } from 'erpcore/utils/utils';

export const getImage = (state, iri) => state?.imageManager?.collection?.[iri];

export const getImageUrl = (state, iri, version = null) => {
    const stateMediaObject = state?.imageManager?.collection?.[iri];

    if (stateMediaObject) {
        return getImageSrc(stateMediaObject, version);
    }

    return null;
};

export const getImageCollection = state => state?.imageManager?.collection;

export const getImageFetching = state => state?.imageManager?.fetching;
export const getImageFetchingData = state => state?.imageManager?.fetchingData;
export const getImageFetchingDataItem = (state, iri) => state?.imageManager?.fetchingData?.[iri];
export const getImageCreating = state => state?.imageManager?.creating;
export const getImageDeleting = state => state?.imageManager?.deleting;
export const getImageUpdating = state => state?.imageManager?.updating;

export default createSelector([
    getImage,
    getImageUrl,
    getImageCollection,
    getImageFetching,
    getImageFetchingData,
    getImageFetchingDataItem,
    getImageCreating,
    getImageDeleting,
    getImageUpdating
]);
