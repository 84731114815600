import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import './PresentationCameraTest.scss';
import ElementLoader from 'erpcore/components/ElementLoader';
import { createSession, OTPublisher } from 'opentok-react';
import {
    getDefaultCameraState,
    getDefaultMicrophoneState,
    getPresentationRtcDataTest
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

const PresentationCameraTest = ({ sessionTestConnection }) => {
    // eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
    const otPublisher = useRef();
    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);
    const [sessionHelperTest, setSessionHelperTest] = useState(null);
    const rtcDataTest = useSelector(getPresentationRtcDataTest);
    const defaultCameraState = useSelector(getDefaultCameraState);
    const defaultMicrophoneState = useSelector(getDefaultMicrophoneState);
    const [isPublisherCameraActive, setIsPublisherCameraActive] = useState(defaultCameraState);
    const [isPublisherMicActive, setIsPublisherMicActive] = useState(defaultMicrophoneState);
    const [isPublishingCameraInProgress, setIsPublishingCameraInProgress] = useState(false);
    const [canDisplayControls, setCanDisplayControls] = useState(false);

    const setDefaultCameraState = state => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_DEFAULT_CAMERA_STATE,
                data: state
            });
        });
    };

    const setDefaultMicrophoneState = state => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_DEFAULT_MICROPHONE_STATE,
                data: state
            });
        });
    };

    const setCameraAccessDenied = state => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_CAMERA_ACCESS_DENIED,
                data: state
            });
        });
    };

    const setCameraDialogOpened = state => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_CAMERA_DIALOG_OPENED,
                data: state
            });
        });
    };

    const getMyConnection = () => {
        if (sessionHelperTest?.session) {
            return sessionHelperTest?.session?.connection;
        }
        return null;
    };

    const getPublisher = () => {
        return otPublisher?.current?.getPublisher();
    };

    const handleIsPublishingCameraInProgress = nextState => {
        setIsPublishingCameraInProgress(nextState);
        if (nextState === true) {
            setTimeout(() => {
                setIsPublishingCameraInProgress(false);
            }, 2000);
        }
    };

    const publishCamera = () => {
        if (getPublisher()) {
            handleIsPublishingCameraInProgress(true);
            const nextState = !getPublisher()?.stream?.hasVideo;
            getPublisher().publishVideo(nextState);
        }
    };

    const publishAudio = () => {
        if (getPublisher()) {
            const nextState = !getPublisher()?.stream?.hasAudio;
            getPublisher().publishAudio(nextState);
        }
    };

    useEffect(() => {
        if (sessionTestConnection) {
            setSessionHelperTest(
                createSession({
                    ...rtcDataTest
                })
            );
        }
    }, [sessionTestConnection]);

    useEffect(() => {
        if (sessionHelperTest?.session && !sessionEventsCreated) {
            sessionHelperTest.session.on('streamPropertyChanged ', data => {
                const { changedProperty, newValue, stream } = { ...data };
                const { connection, videoType, name } = { ...stream };

                if (
                    changedProperty === 'hasVideo' &&
                    videoType !== 'screen' &&
                    name !== 'screenshare' &&
                    getMyConnection()?.connectionId === connection.id
                ) {
                    setIsPublisherCameraActive(newValue);
                    setDefaultCameraState(newValue);
                    handleIsPublishingCameraInProgress(false);
                }

                if (
                    changedProperty === 'hasAudio' &&
                    videoType !== 'screen' &&
                    name !== 'screenshare' &&
                    getMyConnection()?.connectionId === connection.id
                ) {
                    setIsPublisherMicActive(newValue);
                    setDefaultMicrophoneState(newValue);
                }
            });

            setSessionEventsCreated(true);
        }
    }, [sessionHelperTest]);

    useEffect(() => {
        return () => {
            if (sessionHelperTest) {
                sessionHelperTest.disconnect();
            }
        };
    }, [sessionHelperTest]);

    if (sessionHelperTest) {
        return (
            <div className="presentation-camera-test">
                <OTPublisher
                    session={sessionHelperTest.session}
                    className="presentation-camera-test__publisher"
                    ref={otPublisher}
                    eventHandlers={{
                        streamCreated: () => {
                            setCanDisplayControls(true);
                        },
                        accessDenied: () => {
                            setCameraAccessDenied(true);
                        },
                        accessAllowed: () => {
                            setCameraAccessDenied(false);
                        },
                        accessDialogOpened: () => {
                            setCameraDialogOpened(true);
                        },
                        accessDialogClosed: () => {
                            setCameraDialogOpened(false);
                        }
                    }}
                    properties={{
                        width: '100%',
                        height: '325px',
                        facingMode: 'user',
                        fitMode: 'contain',
                        mirror: true,
                        name: 'Camera Setup',
                        resolution: '320x240',
                        publishVideo: true,
                        publishAudio: true,
                        showControls: true,
                        style: {
                            buttonDisplayMode: 'off',
                            nameDisplayMode: 'off'
                        }
                    }}
                />
                {!!canDisplayControls && (
                    <div className="presentation-camera-test__controls">
                        <Tooltip
                            direction="up"
                            content={isPublisherCameraActive ? 'Turn off camera' : 'Turn on camera'}
                        >
                            <button
                                onClick={() => publishCamera()}
                                type="button"
                                className="presentation-camera-test__controls-button"
                                aria-label={
                                    isPublisherCameraActive ? 'Turn off camera' : 'Turn on camera'
                                }
                            >
                                {!isPublishingCameraInProgress && (
                                    <Svg
                                        className="presentation-camera-test__controls-button-icon"
                                        icon={isPublisherCameraActive ? 'camera' : 'cameraOff'}
                                    />
                                )}
                                {!!isPublishingCameraInProgress && <ElementLoader />}
                            </button>
                        </Tooltip>
                        <Tooltip
                            direction="up"
                            content={
                                isPublisherMicActive ? 'Turn off microphone' : 'Turn on microphone'
                            }
                        >
                            <button
                                onClick={() => publishAudio()}
                                type="button"
                                className="presentation-camera-test__controls-button"
                                aria-label={
                                    isPublisherMicActive
                                        ? 'Turn off microphone'
                                        : 'Turn on microphone'
                                }
                                // style={{ margin: '0 -2px' }}
                            >
                                <Svg
                                    className="presentation-camera-test__controls-button-icon"
                                    icon={isPublisherMicActive ? 'mic' : 'micOff'}
                                />
                            </button>
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }

    return <ElementLoader />;
};

PresentationCameraTest.defaultProps = {
    sessionTestConnection: null
};

PresentationCameraTest.propTypes = {
    sessionTestConnection: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default PresentationCameraTest;
