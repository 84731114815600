import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './PdfViewer.scss';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { getGalleryData, getGalleryFetching } from 'erpcore/components/Gallery/Gallery.selectors';
import { actions as galleryActions } from 'erpcore/components/Gallery/Gallery.reducer';
import { getImageSrc } from 'erpcore/utils/utils';
import ElementLoader from 'erpcore/components/ElementLoader';

const PdfViewer = ({ fileIri, name }) => {
    const dispatch = useDispatch();
    const galleryData = useSelector(state => getGalleryData(state, name)) || {};
    const fileFetching = useSelector(state => getGalleryFetching(state, name));
    const { data: files } = { ...galleryData };
    const previousFileIri = useRef(null);

    const fetchFile = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: galleryActions.START_FETCHING_IMAGES,
                params: { 'filters[id][equals]': fileIri },
                name
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (!isEqual(fileIri, previousFileIri.current)) {
            previousFileIri.current = fileIri;
            if (fileIri?.length > 0) {
                fetchFile();
            }
        }
    }, [fileIri]);

    let pdfUrl = null;

    if (files?.[0]) {
        pdfUrl = getImageSrc(files?.[0]);
    }

    return (
        <div className="pdf-viewer">
            {!!fileFetching && <ElementLoader overlay />}
            {!!pdfUrl && (
                <iframe
                    title="pdf-viewer"
                    width="600"
                    height="600"
                    src={pdfUrl}
                    frameBorder="0"
                    allowFullScreen
                />
            )}
        </div>
    );
};

PdfViewer.defaultProps = {
    name: null,
    fileIri: null
};

PdfViewer.propTypes = {
    name: PropTypes.string,
    fileIri: PropTypes.string
};

export default PdfViewer;
