import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as organizationActions } from './OrganizationSettings.reducer';

export function* fetchOrganizationSettings({ promise }) {
    try {
        const fetchOrganizationSettingsAPI = yield restClient.get('/api/organization-settings', {
            params: { 'filters[name][equals]': enviromentVariables.REACT_APP_SLUG }
        });
        yield put({
            type: organizationActions.FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS,
            response: dto(fetchOrganizationSettingsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCHING_FAILED_ORGANIZATION_SETTINGS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(organizationActions.START_FETCHING_ORGANIZATION_SETTINGS, fetchOrganizationSettings)
];
