import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Pannellum } from 'pannellum-react';

import ElementLoader from 'erpcore/components/ElementLoader';

import { actions as galleryActions } from 'erpcore/components/Gallery/Gallery.reducer';
import { getGalleryData, getGalleryFetching } from 'erpcore/components/Gallery/Gallery.selectors';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PanoramaViewer.scss';

const PanoramaViewer = ({ name, imageIri, className }) => {
    const dispatch = useDispatch();
    const imageData = useSelector(state => getGalleryData(state, name)) || {};
    const imageFetching = useSelector(state => getGalleryFetching(state, name));
    const { data } = { ...imageData };

    const fetchImage = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: galleryActions.START_FETCHING_IMAGES,
                params: { 'filters[id][in]': [imageIri] },
                name
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (imageIri) {
            fetchImage();
        }
    }, [imageIri]);

    const getImageURL = () => {
        return data
            ?.map(image => {
                const imageURL = image.content_url_modified;

                return imageURL;
            })
            .toString();
    };

    if ((!data || data.length < 1) && !imageFetching) {
        return null;
    }

    return (
        <div className={`panorama-viewer${className ? ` ${className}` : ''}`}>
            {imageFetching ? (
                <ElementLoader overlay />
            ) : (
                <Pannellum
                    width="100%"
                    height="500px"
                    image={getImageURL()}
                    haov={360}
                    vaov={90}
                    vOffset={1}
                    pitch={0}
                    yaw={0}
                    hfov={90}
                    maxHfov={170}
                    minHfov={30}
                    autoLoad
                />
            )}
        </div>
    );
};

PanoramaViewer.defaultProps = {
    name: null,
    imageIri: null,
    className: null
};

PanoramaViewer.propTypes = {
    name: PropTypes.string,
    imageIri: PropTypes.string,
    className: PropTypes.string
};

export default PanoramaViewer;
