import React from 'react';
import PageLoader from 'erpcore/components/PageLoader';
import './PresentationLoading.scss';

const PresentationLoading = () => {
    return (
        <div className="presentation-loading">
            <PageLoader />
        </div>
    );
};

PresentationLoading.defaultProps = {};

PresentationLoading.propTypes = {};

export default PresentationLoading;
