import React from 'react';
import PropTypes from 'prop-types';
import Embed from 'erpcore/components/Embed';
import './YoutubeVideo.scss';

const YoutubeVideo = ({ url, fullscreen }) => {
    if (!url) return null;

    const getYoutubeId = () => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[7].length === 11 ? match[7] : false;
    };

    const iframe = (
        <iframe
            title={url}
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${getYoutubeId(url)}?rel=0`}
            frameBorder="0"
            allow="accelerometer; encrypted-media;"
            allowFullScreen
        />
    );

    const style = {};
    if (!fullscreen) {
        style.paddingBottom = '56.25%';
    }

    return (
        <div
            className={`youtube-video-block ${fullscreen ? 'youtube-video-block--fullscreen' : ''}`}
        >
            <Embed code={iframe} renderType="component" fullscreen={fullscreen} {...style} />
        </div>
    );
};

YoutubeVideo.defaultProps = {
    url: null,
    fullscreen: false
};

YoutubeVideo.propTypes = {
    url: PropTypes.string,
    fullscreen: PropTypes.bool
};

export default YoutubeVideo;
