import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, getFormInitialValues, getFormSubmitErrors, change } from 'redux-form';
import { diff } from 'deep-object-diff';

import LocationManualToggle from 'erpcore/components/Form/components/Location/components/LocationManualToggle';
import FullAddress from 'erpcore/components/Form/components/Location/components/FullAddress';
import LocationDetails from 'erpcore/components/Form/components/Location/components/LocationDetails';
import LocationMap from 'erpcore/components/Form/components/Location/components/LocationMap';

import { getIncludedDataByIri } from 'erpcore/utils/dto';
import './Location.scss';

/*
    const location = {
        full_address: '',
        street: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        latitude: '',
        longitude: ''
    };
*/

// eslint-disable-next-line no-unused-vars
const Location = ({ input, meta, fieldProps, fieldAttr, field }) => {
    const dispatch = useDispatch();
    const { name: fieldName } = { ...input };
    const { showLocationDetails, locationManualTogglelabel, fullAddresslabel } = { ...fieldProps };
    const { disabled } = { ...fieldAttr };
    const { form: formName } = { ...meta };
    const formValues = useSelector(state => getFormValues(formName)(state));
    const initialFormValues = useSelector(state => getFormInitialValues(formName)(state));
    const formErrors = useSelector(state => getFormSubmitErrors(formName)(state));
    const { [fieldName]: fieldValues } = { ...formValues };
    const { [fieldName]: initialFieldValues } = { ...initialFormValues };
    const {
        locationManualToggle,
        fullAddressSelected,
        full_address: fullAddress,
        street,
        country,
        state,
        city,
        zip,
        latitude,
        longitude
    } = {
        ...fieldValues
    };

    /**
     *
     * @param components
     * @param type
     * @param version {string}
     */
    const getLocationDataFromGeocodeData = (components, type, version = 'long_name') => {
        const address = components.find(component => {
            return component?.types?.indexOf(type) !== -1;
        });

        if (address) {
            return address[version];
        }

        return '';
    };

    /**
     * Dispatch values to the Redux Form
     */
    const dispatchLocation = results => {
        if (!results || !results[0]) {
            return null;
        }

        const {
            address_components: addressComponents,
            formatted_address: formattedAddress,
            geometry
        } = {
            ...results[0]
        };
        const { location: coordinates } = { ...geometry };
        const { lat, lng } = { ...coordinates };

        // Getting the values from the Geocode results

        //  merging street line to ex 'Ul. Rudolfa Fizira 21' route and street number to one line
        const locationStreetRoute = getLocationDataFromGeocodeData(addressComponents, 'route');
        const locationStreetNumber = getLocationDataFromGeocodeData(
            addressComponents,
            'street_number'
        );
        let locationStreet = '';
        if (locationStreetRoute && !locationStreetNumber) {
            locationStreet = locationStreetRoute;
        }
        if (!locationStreetRoute && locationStreetNumber) {
            locationStreet = locationStreetNumber;
        }
        if (locationStreetRoute && locationStreetNumber) {
            locationStreet = `${locationStreetRoute} ${locationStreetNumber}`;
        }
        //  Country
        const locationCountry = getLocationDataFromGeocodeData(addressComponents, 'country') || '';
        //  State
        const locationState =
            getLocationDataFromGeocodeData(addressComponents, 'administrative_area_level_1') || '';
        //  City
        const locationCity =
            getLocationDataFromGeocodeData(addressComponents, 'locality') ||
            getLocationDataFromGeocodeData(addressComponents, 'neighborhood') ||
            '';
        // ZIP
        const locationZip = getLocationDataFromGeocodeData(addressComponents, 'postal_code') || '';
        // Latitude
        const locationLatitude = lat ? lat() : '';
        // Longitude
        const locationLongitude = lng ? lng() : '';

        // Dispatch values to Redux Form
        dispatch(change(formName, `${fieldName}.full_address`, formattedAddress || ''));
        dispatch(change(formName, `${fieldName}.street`, locationStreet || ''));
        dispatch(change(formName, `${fieldName}.country`, locationCountry || ''));
        dispatch(change(formName, `${fieldName}.state`, locationState || ''));
        dispatch(change(formName, `${fieldName}.city`, locationCity || ''));
        dispatch(change(formName, `${fieldName}.zip`, locationZip || ''));
        dispatch(change(formName, `${fieldName}.latitude`, locationLatitude || ''));
        dispatch(change(formName, `${fieldName}.longitude`, locationLongitude || ''));

        return null;
    };

    /**
     * Dispatch values to the Redux Form
     */
    const dispatchLatLng = results => {
        if (!results || !results[0]) {
            dispatch(change(formName, `${fieldName}.latitude`, ''));
            dispatch(change(formName, `${fieldName}.longitude`, ''));
        }

        const { geometry } = {
            ...results[0]
        };
        const { location: coordinates } = { ...geometry };
        const { lat, lng } = { ...coordinates };

        // Getting the values from the Geocode results
        // Latitude
        const locationLatitude = lat ? lat() : '';
        // Longitude
        const locationLongitude = lng ? lng() : '';

        // Dispatch values to Redux Form
        dispatch(change(formName, `${fieldName}.latitude`, locationLatitude || ''));
        dispatch(change(formName, `${fieldName}.longitude`, locationLongitude || ''));
    };

    /**
     * On FullAddress selected returns placeId
     * Use Geocode for the detailed data (results)
     * Dispatch values to the Redux Form
     * @param {string} placeId
     */
    const onFullAddressSelected = placeId => {
        const { google } = window;
        const geocoder = new google.maps.Geocoder();

        // Flag to show/hide Location Details Form
        dispatch(change(formName, `${fieldName}.fullAddressSelected`, true));

        return geocoder.geocode({ placeId }, results => dispatchLocation(results));
    };

    /**
     * On FullAddress clear
     * Dispatch empty values to the Redux Form
     *
     */
    const onFullAddressClear = () => {
        // Dispatch values to Redux Form
        dispatch(change(formName, `${fieldName}.full_address`, ''));
        dispatch(change(formName, `${fieldName}.street`, ''));
        dispatch(change(formName, `${fieldName}.country`, ''));
        dispatch(change(formName, `${fieldName}.state`, ''));
        dispatch(change(formName, `${fieldName}.city`, ''));
        dispatch(change(formName, `${fieldName}.zip`, ''));
        dispatch(change(formName, `${fieldName}.latitude`, ''));
        dispatch(change(formName, `${fieldName}.longitude`, ''));
        // dispatch(change(formName, `${fieldName}.fullAddressSelected`, false));
    };

    /**
     * On manual field change create full_address value
     * Dispatch value to the Redux Form
     */
    const onManualFieldChange = () => {
        const { google } = window;
        const geocoder = new google.maps.Geocoder();

        const countryValue = getIncludedDataByIri(country, formValues) || '';
        const cityValue = getIncludedDataByIri(city, formValues) || '';
        const stateValue = getIncludedDataByIri(state, formValues) || '';
        const streetValue = street || '';

        let fullAddressValue = [
            streetValue || '',
            zip || '',
            cityValue?.name || cityValue?.label || '',
            stateValue?.name || stateValue?.label || '',
            countryValue?.name || countryValue?.label || ''
        ];

        fullAddressValue = fullAddressValue.filter(el => {
            return el !== '';
        });

        const fullAddressString = fullAddressValue.join(', ');

        // Geocode Address and Dispatch lat and lng
        geocoder.geocode({ address: fullAddressString }, results => dispatchLatLng(results));

        // Dispatch values to Redux Form
        dispatch(change(formName, `${fieldName}.full_address`, fullAddressString));
    };

    /**
     * On manual map onClick or Marker drag
     * Use Geocode for the detailed data (results)
     * Dispatch values to the Redux Form
     * @param {object} event
     */
    const onChangeLocationMap = event => {
        const { google } = window;
        const geocoder = new google.maps.Geocoder();

        return geocoder.geocode(
            { location: { lat: event?.latLng?.lat(), lng: event?.latLng?.lng() } },
            results => dispatchLocation(results)
        );
    };

    /**
     * Render Form errors
     */
    const renderLocationErrors = () => {
        const allowedErrors = ['address', 'country', 'city', 'zip'];

        const filteredErrors = Object.keys(formErrors)
            .filter(key => allowedErrors.includes(key))
            .reduce((obj, key) => {
                obj[key] = formErrors[key];
                return obj;
            }, {});

        return Object.keys(filteredErrors).map(errorKey => (
            <span className="location__helper-text location__helper-text--error">
                {filteredErrors[errorKey].message}
            </span>
        ));
    };

    /**
     * Apply effect on Mount
     */
    useEffect(() => {
        if (fullAddress && fullAddress !== '') {
            dispatch(change(formName, `${fieldName}.fullAddressSelected`, true));
        }
    }, [fullAddress, fullAddressSelected]);

    /**
     * Apply effect on LocationDetails Fields change
     */
    useEffect(() => {
        // Compare initialValues with updated values
        const fieldValuesDiff = diff(
            {
                street: initialFieldValues?.street,
                country: initialFieldValues?.country,
                state: initialFieldValues?.state,
                city: initialFieldValues?.city,
                zip: initialFieldValues?.zip
            },
            { street, country, state, city, zip }
        );
        // if different - update field values
        // if not - set full_address to initialValue
        if (Object.keys(fieldValuesDiff).length !== 0 && fieldValuesDiff.constructor === Object) {
            onManualFieldChange();
        } else if (initialFieldValues) {
            dispatch(
                change(formName, `${fieldName}.full_address`, initialFieldValues.full_address || '')
            );
            dispatch(change(formName, `${fieldName}.latitude`, initialFieldValues.latitude || ''));
            dispatch(
                change(formName, `${fieldName}.longitude`, initialFieldValues.longitude || '')
            );
        }
    }, [street, country, state, city, zip]);

    /**
     *
     * @returns {*}
     */
    return (
        <div className="location">
            {!disabled ? (
                <LocationManualToggle
                    fieldName={fieldName}
                    label={locationManualTogglelabel || null}
                    disabled={disabled}
                />
            ) : (
                ''
            )}
            <FullAddress
                fieldName={fieldName}
                label={fullAddresslabel}
                disabled={disabled || locationManualToggle}
                onSelect={onFullAddressSelected}
                onFullAddressClear={onFullAddressClear}
            />
            {locationManualToggle || showLocationDetails || fullAddressSelected ? (
                <Fragment>
                    <LocationDetails
                        fieldName={fieldName}
                        disabled={disabled || !locationManualToggle}
                    />
                    <LocationMap
                        disabled={disabled || !locationManualToggle}
                        coordinates={{ lat: latitude, lng: longitude }}
                        onChange={onChangeLocationMap}
                    />
                </Fragment>
            ) : (
                ''
            )}
            {renderLocationErrors()}
            {fieldProps?.helperText && (
                <span className="location__helper-text">{fieldProps.helperText}</span>
            )}
        </div>
    );
};

Location.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

Location.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default Location;
