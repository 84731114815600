export const actions = {
    START_FETCHING_ORGANIZATION_SETTINGS: 'START_FETCHING_ORGANIZATION_SETTINGS',
    FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS: 'FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS',
    FETCHING_FAILED_ORGANIZATION_SETTINGS: 'FETCHING_FAILED_ORGANIZATION_SETTINGS'
};

export const initialState = {
    fetchingOrganizationSettings: false,
    organizationSettings: {}
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: false,
                organizationSettings: response.data[0] || {}
            };
        }
        case actions.FETCHING_FAILED_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: false
            };
        }
        default:
            return state;
    }
};
