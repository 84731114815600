import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { getEntityUrl } from 'erpcore/utils/RouterManager';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import ElementLoader from 'erpcore/components/ElementLoader';
import Image from 'erpcore/components/Image';
import frontLayout from 'erpcore/utils/LayoutManager/frontLayout';

import {
    getPresentationFetchingSessionData,
    getPresentationSessionData,
    getPresentationSessionDataByHash
} from 'erpcore/screens/Presentation/Presentation.selectors';

import 'erpcore/screens/Projects/screens/Projects/Projects.scss';

// import moment from 'moment-timezone';

const SessionProjects = () => {
    const match = useRouteMatch();

    const prospectHash = match?.params?.prospectHash;
    const sessionIri = match?.params?.session ? `/api/sessions/${match?.params?.session}` : null;

    const sessionDatabyHash =
        useSelector(state => getPresentationSessionDataByHash(state, prospectHash)) || {};

    const getDerivedSessionIri = () => {
        if (sessionIri) {
            return sessionIri;
        }

        if (sessionDatabyHash?.iri) {
            return sessionDatabyHash?.iri;
        }

        return null;
    };

    const sessionData =
        useSelector(state => getPresentationSessionData(state, getDerivedSessionIri())) || {};

    const sessionFetching = useSelector(getPresentationFetchingSessionData);

    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const title = 'Session Projects';

    const renderSessionData = () => {
        if (!sessionData?.iri) return null;

        const {
            // date,
            session_prospects: sessionProspects,
            sales_agent: salesAgent
        } = {
            ...sessionData
        };

        const connectionType = sessionProspects?.length === 1 ? 'one-to-one' : 'one-to-many';

        let prospectName = null;
        if (connectionType === 'one-to-one') {
            const { prospect } = { ...sessionProspects?.[0] };
            const { first_name: firstName = '', last_name: lastName = '' } = {
                ...prospect
            };
            prospectName = `${firstName} ${lastName}`;
        }

        return (
            <div className="projects__session">
                {!!prospectName && (
                    <p className="projects__session-item">
                        <span className="projects__session-item-title">Presentation For</span>
                        <span className="projects__session-item-value">{prospectName}</span>
                    </p>
                )}
                {!!salesAgent && (
                    <div className="projects__session-item">
                        <span className="projects__session-item-title">Sales agent</span>
                        <span className="projects__session-item-user">
                            {!!salesAgent?.image && (
                                <div className="projects__session-item-user-logo">
                                    <Image
                                        iri={salesAgent?.image}
                                        version="small"
                                        className="projects__session-item-user-logo-image"
                                    />
                                </div>
                            )}
                            <div className="projects__session-item-user-data">
                                <p className="projects__session-item-user-data-title">
                                    {salesAgent.first_name || ''} {salesAgent.last_name || ''}
                                </p>
                                {!!salesAgent.email && (
                                    <p className="projects__session-item-user-data-email">
                                        <a
                                            href={`mailto:${salesAgent.email}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {salesAgent.email}
                                        </a>
                                    </p>
                                )}
                                {!!salesAgent.phone_number && (
                                    <p className="projects__session-item-user-data-telephone">
                                        {salesAgent.phone_number}
                                    </p>
                                )}
                            </div>
                        </span>
                    </div>
                )}
                {/* !!date && (
                    <p className="projects__session-item">
                        <span className="projects__session-item-title">Scheduled for</span>
                        <span className="projects__session-item-value">
                            {moment(date).format('MMM Do, YYYY h:mma')}
                        </span>
                    </p>
                ) */}
            </div>
        );
    };

    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <div className="projects">
                <Image iri={organizationSettings?.settings?.logo} className="projects__logo" />
                <p className="projects__subtitle projects__subtitle--thin">
                    {organizationSettings?.settings?.introText}
                </p>

                {!!prospectHash && !!sessionData?.iri && renderSessionData()}

                <div className="projects__item-list">
                    {!!sessionFetching && <ElementLoader overlay />}
                    {!sessionFetching &&
                        !!sessionData?.projects?.length &&
                        sessionData.projects.map(project => {
                            const { featured_image: featuredImage = null } = project;
                            return (
                                <NavLink
                                    to={getEntityUrl({
                                        project: project?.iri,
                                        session: match?.params?.session,
                                        prospectHash: match?.params?.prospectHash
                                    })}
                                    className="projects__item"
                                    key={project.id}
                                >
                                    {featuredImage ? (
                                        <Image
                                            isContainer
                                            version="large"
                                            iri={featuredImage}
                                            className="projects__item-image"
                                            fallback={<div className="projects__item-image" />}
                                        />
                                    ) : (
                                        <div className="projects__item-image" />
                                    )}
                                    <div className="projects__item-content">
                                        <span className="projects__item-location">
                                            {project?.city?.name}
                                            {project?.state?.name
                                                ? `/ ${project?.state?.name}`
                                                : ''}
                                        </span>
                                        <span className="projects__item-name">{project.name}</span>
                                        <span className="projects__item-type">
                                            {project?.street}
                                        </span>
                                    </div>
                                    <div className="projects__item-action">View project</div>
                                </NavLink>
                            );
                        })}
                </div>
            </div>
        </LayoutManager>
    );
};

export default SessionProjects;
