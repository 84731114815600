import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createPromise } from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSaga from 'erpcore/sagas';
import combinedReducers from 'erpcore/reducers';

// Sentry error logging
let sentryMiddleware = null;
if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN
    });

    sentryMiddleware = createSentryMiddleware(Sentry);
}

const sagaMiddleware = createSagaMiddleware();

/**
|--------------------------------------------------
| Put reducers to local storage
| Do not store form to local storage
|--------------------------------------------------
*/
const reducer = persistReducer(
    { key: 'state', storage, whitelist: ['authManager', 'layoutManager', 'organizationSettings'] },
    combinedReducers
);

//  Preparing middleware for store
let middleware = null;
if (process.env.REACT_APP_REDUX_DEBUGGER === 'true') {
    if (sentryMiddleware !== null) {
        middleware = composeWithDevTools(
            applyMiddleware(createPromise(), sentryMiddleware, createLogger(), sagaMiddleware)
        );
    } else {
        middleware = composeWithDevTools(
            applyMiddleware(createPromise(), createLogger(), sagaMiddleware)
        );
    }
} else if (sentryMiddleware !== null) {
    middleware = compose(applyMiddleware(createPromise(), sentryMiddleware, sagaMiddleware));
} else {
    middleware = compose(applyMiddleware(createPromise(), sagaMiddleware));
}

//  Prepare store for Provider
const store = createStore(reducer, middleware);

//  Mount sagas
sagaMiddleware.run(rootSaga);

//  Prepare persistor for persistStore
const persistor = persistStore(store);

//  Exporting store and persistor for providers in index
export { store, persistor };
