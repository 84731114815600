import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'erpcore/utils/Hooks';
import PropTypes from 'prop-types';
import * as portals from 'react-reverse-portal';
import { getPresentationSidebarOpened } from 'erpcore/screens/Presentation/Presentation.selectors';
import './PresentationSidebar.scss';
import Image from 'erpcore/components/Image';
import PresentationChat from 'erpcore/components/Presentation/components/PresentationChat';
import PresentationRaisedHands from 'erpcore/components/Presentation/components/PresentationRaisedHands';
import PresentationParticipants from 'erpcore/components/Presentation/components/PresentationParticipants';
import { ifMobile } from 'erpcore/utils/utils';

const PresentationSidebar = ({
    connectionType,
    sessionData,
    sessionHelper,
    // streamNames,
    type,
    portalCameras,
    portalScreenPublisher,
    getUserName,
    getOtherAdminConnections,
    chatEnabled,
    raisingHandsEnabled,
    presentationPhase,
    renderCameras
}) => {
    const sidebarOpened = useSelector(getPresentationSidebarOpened);
    const [windowWidth] = useWindowSize();

    const { session_prospects: sessionProspects, sales_agent: salesAgent, projects = [] } = {
        ...sessionData
    };

    let prospectName = null;
    if (connectionType === 'one-to-one') {
        const { prospect } = { ...sessionProspects?.[0] };
        const { first_name: firstName = '', last_name: lastName = '' } = {
            ...prospect
        };
        prospectName = `${firstName} ${lastName}`;
    }

    const renderProject = project => {
        if (!project) return null;

        const {
            name,
            development_project_logo: developmentProjectLogo,
            street,
            city,
            state,
            full_address: fullAddress
        } = {
            ...project
        };
        const { name: cityName } = { ...city };
        const { name: stateName } = { ...state };

        let location = fullAddress;
        if (fullAddress && cityName) {
            location = (
                <>
                    {street}
                    <br />
                    {cityName}
                    {stateName ? `, ${stateName}` : ''}
                </>
            );
        }

        return (
            <div className="presentation-sidebar__info-row presentation-sidebar__info-row--border">
                <h3 className="presentation-sidebar__info-title">Property</h3>
                {!!name && <p className="presentation-sidebar__info-label-large">{name}</p>}
                {!!location && <p className="presentation-sidebar__info-label">{location}</p>}
                {!!developmentProjectLogo?.iri && (
                    <Image
                        iri={developmentProjectLogo?.iri}
                        version="small"
                        className="presentation-sidebar__info-img"
                    />
                )}
            </div>
        );
    };

    // const showCameraStreams = type === 'client' || (type === 'admin' && connectionType === 'one-to-one' && !!streamNames?.length);

    // const showCameraPublisher = type === 'admin' || (type === 'client' && connectionType === 'one-to-one');

    const renderScreenPublisherPortal = !ifMobile(windowWidth) && presentationPhase !== 'intro';

    return (
        <div
            className={`presentation-sidebar presentation-sidebar--${type}`}
            data-opened={sidebarOpened ? '1' : '0'}
        >
            <div className="presentation-sidebar__call">
                {!!sessionHelper && !!renderCameras && (
                    <portals.OutPortal
                        theme={type === 'admin' ? 'light' : 'dark'}
                        node={portalCameras}
                    />
                )}
            </div>
            <div className="presentation-sidebar__content">
                {!!chatEnabled && (
                    <PresentationChat
                        sessionHelper={sessionHelper}
                        sessionData={sessionData}
                        getUserName={getUserName}
                    />
                )}
                {!!raisingHandsEnabled && (
                    <PresentationRaisedHands
                        sessionHelper={sessionHelper}
                        sessionData={sessionData}
                        getUserName={getUserName}
                        getOtherAdminConnections={getOtherAdminConnections}
                    />
                )}
                {type === 'admin' && (
                    <PresentationParticipants
                        sessionProspects={sessionProspects}
                        sessionHelper={sessionHelper}
                    />
                )}
                {type === 'admin' && !!renderScreenPublisherPortal && (
                    <portals.OutPortal node={portalScreenPublisher} />
                )}
                {type === 'client' && (
                    <div className="presentation-sidebar__info">
                        <div className="presentation-sidebar__info-row">
                            {!!prospectName && (
                                <>
                                    <h3 className="presentation-sidebar__info-title">
                                        Presentation for
                                    </h3>
                                    <p className="presentation-sidebar__info-label-large">
                                        {prospectName}
                                    </p>
                                </>
                            )}
                        </div>
                        {!!salesAgent && (
                            <div className="presentation-sidebar__info-row">
                                <h3 className="presentation-sidebar__info-title">Sales Agent</h3>
                                <p className="presentation-sidebar__info-label-large">
                                    {salesAgent.first_name || ''} {salesAgent.last_name || ''}
                                </p>
                                {!!salesAgent.email && (
                                    <p className="presentation-sidebar__info-label">
                                        <a
                                            href={`mailto:${salesAgent.email}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {salesAgent.email}
                                        </a>
                                    </p>
                                )}
                                {!!salesAgent.phone_number && (
                                    <p className="presentation-sidebar__info-label">
                                        {salesAgent.phone_number}
                                    </p>
                                )}
                            </div>
                        )}
                        {projects?.length === 1 && renderProject(projects[0])}
                    </div>
                )}
            </div>
        </div>
    );
};

PresentationSidebar.defaultProps = {
    connectionType: null,
    type: 'client',
    sessionData: null,
    sessionHelper: null,
    // streamNames: null,
    portalCameras: null,
    portalScreenPublisher: null,
    getUserName: () => {},
    getOtherAdminConnections: () => {},
    chatEnabled: false,
    raisingHandsEnabled: false,
    presentationPhase: null,
    renderCameras: false
};

PresentationSidebar.propTypes = {
    connectionType: PropTypes.string,
    type: PropTypes.oneOf(['client', 'admin']),
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    // streamNames: PropTypes.oneOfType([PropTypes.array]),
    portalCameras: PropTypes.oneOfType([PropTypes.object]),
    portalScreenPublisher: PropTypes.oneOfType([PropTypes.object]),
    getUserName: PropTypes.func,
    getOtherAdminConnections: PropTypes.func,
    chatEnabled: PropTypes.bool,
    raisingHandsEnabled: PropTypes.bool,
    presentationPhase: PropTypes.string,
    renderCameras: PropTypes.bool
};

export default PresentationSidebar;
