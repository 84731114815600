import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';

import Button from 'erpcore/components/Button';
import Chip from 'erpcore/components/Chip';
import Collapse from 'erpcore/components/Collapse';
import ElementLoader from 'erpcore/components/ElementLoader';
import { RadioGroup } from 'erpcore/components/Form';

import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

import {
    getPresentationSection,
    getPresentationQuestions,
    getPresentationQuestionsFetching,
    getPresentationProspectAnswers,
    getPresentationProspectAnswersFetching
} from 'erpcore/screens/Presentation/Presentation.selectors';

import './PresentationQuestions.scss';

const projectSectionMap = {
    'project-home': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    'demo-tour-overview': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    neighbourhood: {
        title: 'Neighbourhood',
        erpKey: 'neighbourhood'
    },
    'building-design': {
        title: 'Building design',
        erpKey: 'building-design'
    },
    floorplans: {
        title: 'Floor plans',
        erpKey: 'floor-plans'
    },
    'home-tour': {
        title: 'Home tour',
        erpKey: 'home-tour'
    },
    'features-and-options': {
        title: 'Features & Options',
        erpKey: 'features-and-options'
    },
    developer: {
        title: 'Developer',
        erpKey: 'developer'
    },
    'purchase-calculator': {
        title: 'Purchase Calculator',
        erpKey: 'purchase-calculator'
    },
    documents: {
        title: 'Documents',
        erpKey: 'documents'
    }
};

const PresentationQuestions = ({ sessionHelper, sessionProspect, activeTab }) => {
    const dispatch = useDispatch();
    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);
    const [sectionQuestions, setSectionQuestions] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [nextQuestion, setNextQuestion] = useState(null);
    const [currentSubmittingQuestions, setCurrentSubmittingQuestions] = useState([]);
    const [currentAnswersData, setCurrentAnswersData] = useState(null);
    const presentationSection = useSelector(getPresentationSection);
    const presentationQuestions = useSelector(state => getPresentationQuestions(state));
    const [submittingAnswerInProgress, setSubmittingAnswerInProgress] = useState(false);
    const presentationQuestionsFetching = useSelector(state =>
        getPresentationQuestionsFetching(state)
    );

    const { prospect } = { ...sessionProspect };

    const prospectAnswers =
        useSelector(state => getPresentationProspectAnswers(state, prospect?.iri)) || [];
    const prospectAnswersFetching = useSelector(state =>
        getPresentationProspectAnswersFetching(state)
    );

    const presentationSectionRef = useRef(null);
    const presentationQuestionsRef = useRef(null);
    const prospectAnswersRef = useRef(null);

    const getTotalQuestionsAnswered = (questions = [], answers = []) => {
        const answerIris = answers.map(item => item?.question?.iri);

        const questionsLeftToAnswer = questions.filter(item => answerIris.includes(item?.iri));

        return questionsLeftToAnswer?.length;
    };

    const setQuestionsMeta = data => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_QUESTIONS_META,
                data
            });
        }).catch(error => {
            return error;
        });
    };

    const setAnswers = (question, answer, type = 'multiple') => {
        const answersData = {
            ...currentAnswersData,
            question
        };
        if (!answersData?.answers?.length || currentAnswersData.question !== question) {
            answersData.answers = [];
        }
        if (type === 'single') {
            if (!answersData?.answers.includes(answer)) {
                answersData.answers = [answer];
            } else {
                answersData.answers = [];
            }
        } else if (type === 'multiple') {
            if (!answersData?.answers.includes(answer)) {
                answersData.answers.push(answer);
            } else {
                answersData.answers = answersData.answers.filter(item => item !== answer);
            }
        }
        setCurrentAnswersData(answersData);
    };

    const fetchProspectAnswers = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_PROSPECT_ANSWERS,
                iri: prospect?.iri
            });
        }).catch(error => {
            return error;
        });
    };

    const submitProspectAnswers = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_SUBMIT_PROSPECT_ANSWERS,
                formData: {
                    sessionProspect: sessionProspect?.iri,
                    question: currentAnswersData?.question,
                    answers: currentAnswersData?.answers
                }
            });
        }).catch(error => {
            return error;
        });
    };

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    const getOtherAdminConnections = () => {
        const output = [];

        if (sessionHelper?.session) {
            const myConnection = sessionHelper?.session?.connection;

            sessionHelper.session.connections.forEach(connection => {
                const connectionCustomData = getConnectionCustomData(connection);
                if (
                    connectionCustomData?.connectionUserType === 'admin' &&
                    connection.connectionId !== myConnection.connectionId
                ) {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const emitAnswerSubmitted = () => {
        if (sessionHelper?.session) {
            const targetConnections = getOtherAdminConnections();
            if (targetConnections?.length) {
                targetConnections.forEach(connection => {
                    const data = {
                        formData: {
                            sessionProspect: sessionProspect?.iri,
                            question: currentAnswersData?.question,
                            answers: currentAnswersData?.answers
                        }
                    };
                    sessionHelper.session.signal({
                        to: connection,
                        data: JSON.stringify(data),
                        type: `answerSubmitted`
                    });
                });
            }
        }
    };

    const fetchProfileQuestions = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_QUESTIONS
            });
        }).catch(error => {
            return error;
        });
    };

    const moveToNextQuestion = () => {
        if (nextQuestion) {
            setCurrentQuestion(nextQuestion);
        }
    };

    const getNextQuestion = () => {
        if (currentQuestion && sectionQuestions?.length) {
            const currentQuestionIndex = sectionQuestions.findIndex(
                item => currentQuestion === item.iri
            );
            if (currentQuestionIndex > -1) {
                const findNextQuestion = sectionQuestions.find((item, i) => {
                    return i > currentQuestionIndex && item?.isAnswered === false;
                });
                if (findNextQuestion) {
                    return findNextQuestion?.iri;
                }
                const findPrevQuestion = sectionQuestions.find((item, i) => {
                    return i < currentQuestionIndex && item?.isAnswered === false;
                });
                if (findPrevQuestion) {
                    return findPrevQuestion?.iri;
                }
            }
        }

        return null;
    };

    const findCurrentQuestion = questions => {
        if (questions?.length) {
            const targetQuestion = questions.find(item => {
                return item?.isAnswered === false;
            });
            if (targetQuestion) {
                return targetQuestion?.iri;
            }
        }

        return null;
    };

    const getSectionQuestionsData = (section = undefined, questions = [], answers = []) => {
        let output = [];

        if (questions?.length) {
            output = questions.reduce((accumulator, currentValue) => {
                if (currentValue.section === projectSectionMap?.[section]?.erpKey) {
                    const isAnswered = answers.find(
                        prospectAnswer => prospectAnswer?.question?.iri === currentValue?.iri
                    );
                    accumulator.push({
                        ...currentValue,
                        isAnswered: !!isAnswered,
                        answered: isAnswered?.answers
                    });
                }
                return accumulator;
            }, []);
        }

        return output;
    };

    const getSectionAllQuestionsData = (section = undefined, questions = [], answers = []) => {
        const output = [];
        if (questions?.length) {
            questions.map(question => {
                if (question.section === projectSectionMap?.[section]?.erpKey) {
                    const isAnswered = answers.find(
                        prospectAnswer => prospectAnswer?.question?.iri === question?.iri
                    );
                    output.push({
                        ...question,
                        isAnswered: !!isAnswered,
                        answered: isAnswered?.answers
                    });
                }
                return false;
            });
        }
        return output;
    };

    const renderCurrentQuestion = () => {
        if (currentQuestion) {
            const questionItem = sectionQuestions.find(item => item?.iri === currentQuestion);
            if (questionItem) {
                const { answer_type: answerType } = questionItem;
                return (
                    <>
                        <h4 className="presentation-administration__section-content-title">
                            {!!questionItem?.isAnswered && (
                                <span style={{ color: 'green' }}>DONE</span>
                            )}{' '}
                            {questionItem?.question}
                        </h4>
                        {answerType === 'multiple' && (
                            <Chip.Wrapper>
                                {uniqBy([...questionItem.answers], 'id').map((answer, i) => {
                                    return (
                                        <Chip
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`${i}-${answer?.name}`}
                                            isActive={
                                                currentAnswersData?.question ===
                                                    questionItem?.iri &&
                                                (currentAnswersData?.answers || []).includes(
                                                    answer?.name
                                                )
                                            }
                                            onClick={() => {
                                                setAnswers(
                                                    questionItem?.iri,
                                                    answer?.name,
                                                    answerType
                                                );
                                            }}
                                        >
                                            {answer.name}
                                        </Chip>
                                    );
                                })}
                            </Chip.Wrapper>
                        )}
                        {answerType === 'single' && (
                            <RadioGroup
                                name={`single-question-${questionItem?.iri}`}
                                input={{
                                    value:
                                        currentAnswersData?.question === questionItem?.iri
                                            ? currentAnswersData?.answers?.[0]
                                            : null,
                                    onBlur: () => {},
                                    onChange: event => {
                                        if (event?.target?.value) {
                                            setAnswers(
                                                questionItem?.iri,
                                                event?.target?.value,
                                                answerType
                                            );
                                        }
                                    }
                                }}
                                meta={{}}
                                fieldAttr={{
                                    id: `single-question-${questionItem?.iri}`
                                }}
                                fieldProps={{
                                    inlineLabel: false,
                                    label: null,
                                    options: uniqBy([...questionItem.answers], 'id').map(answer => {
                                        return {
                                            id: answer?.name,
                                            label: answer?.name,
                                            value: answer?.name
                                        };
                                    })
                                }}
                            />
                        )}
                        <Button
                            loading={!!prospectAnswersFetching}
                            disabled={
                                !currentAnswersData ||
                                currentAnswersData?.question !== questionItem?.iri ||
                                !currentAnswersData?.answers?.length
                            }
                            size="small"
                            label="SUBMIT"
                            onClick={() => {
                                setSubmittingAnswerInProgress(true);
                                submitProspectAnswers().finally(() => {
                                    // because backend
                                    setTimeout(() => {
                                        try {
                                            emitAnswerSubmitted();
                                            fetchProspectAnswers().then(() => {
                                                setSubmittingAnswerInProgress(false);
                                                moveToNextQuestion();
                                            });
                                        } catch (e) {
                                            setSubmittingAnswerInProgress(false);
                                        }
                                    }, 800);
                                });
                            }}
                        />
                        {!!nextQuestion && (
                            <Button
                                size="small"
                                variation="secondary"
                                label="NEXT QUESTIONS"
                                onClick={() => moveToNextQuestion()}
                            />
                        )}
                    </>
                );
            }
        }

        if (!currentQuestion && sectionQuestions?.length) {
            return (
                <p>
                    All questions for {projectSectionMap?.[presentationSection]?.title} have been
                    answered!
                </p>
            );
        }

        return null;
    };

    const renderAllQuestion = () => {
        if (presentationQuestions?.length > 0) {
            const sortedQuestions = Object.keys(projectSectionMap)
                ?.slice(1)
                ?.map(section =>
                    getSectionAllQuestionsData(section, presentationQuestions, prospectAnswers)
                );
            return sortedQuestions.map((sortedQuestion, index) => {
                const renderSectionQuestions = sortedQuestion?.map(questionItem => {
                    const { answer_type: answerType, isAnswered, answered } = questionItem;
                    const renderQuestion = (
                        <div
                            className="presentation-questions__question"
                            key={`all-questions-${questionItem?.iri}`}
                        >
                            <h4 className="presentation-administration__section-content-title">
                                {questionItem?.question}
                            </h4>
                            {isAnswered ? (
                                answered?.map(answer => (
                                    <div
                                        className="presentation-questions__answered-chip"
                                        key={`all-questions-answer-${answer}`}
                                    >
                                        {answer}
                                    </div>
                                ))
                            ) : (
                                <>
                                    {answerType === 'multiple' && (
                                        <Chip.Wrapper>
                                            {uniqBy([...questionItem.answers], 'id').map(
                                                (answer, i) => {
                                                    return (
                                                        <Chip
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`${i}-${answer?.name}`}
                                                            isActive={
                                                                currentAnswersData?.question ===
                                                                    questionItem?.iri &&
                                                                (
                                                                    currentAnswersData?.answers ||
                                                                    []
                                                                ).includes(answer?.name)
                                                            }
                                                            onClick={() => {
                                                                setAnswers(
                                                                    questionItem?.iri,
                                                                    answer?.name,
                                                                    answerType
                                                                );
                                                            }}
                                                        >
                                                            {answer.name}
                                                        </Chip>
                                                    );
                                                }
                                            )}
                                        </Chip.Wrapper>
                                    )}
                                    {answerType === 'single' && (
                                        <RadioGroup
                                            name={`single-question-${questionItem?.iri}`}
                                            input={{
                                                value:
                                                    currentAnswersData?.question ===
                                                    questionItem?.iri
                                                        ? currentAnswersData?.answers?.[0]
                                                        : null,
                                                onBlur: () => {},
                                                onChange: event => {
                                                    if (event?.target?.value) {
                                                        setAnswers(
                                                            questionItem?.iri,
                                                            event?.target?.value,
                                                            answerType
                                                        );
                                                    }
                                                }
                                            }}
                                            meta={{}}
                                            fieldAttr={{
                                                id: `single-question-${questionItem?.iri}`
                                            }}
                                            fieldProps={{
                                                inlineLabel: false,
                                                label: null,
                                                options: uniqBy(
                                                    [...questionItem.answers],
                                                    'id'
                                                ).map(answer => {
                                                    return {
                                                        id: answer?.name,
                                                        label: answer?.name,
                                                        value: answer?.name
                                                    };
                                                })
                                            }}
                                        />
                                    )}
                                    <Button
                                        loading={currentSubmittingQuestions.includes(
                                            questionItem?.iri
                                        )}
                                        disabled={
                                            !currentAnswersData ||
                                            currentAnswersData?.question !== questionItem?.iri ||
                                            !currentAnswersData?.answers?.length ||
                                            prospectAnswersFetching
                                        }
                                        size="small"
                                        label="SUBMIT"
                                        onClick={() => {
                                            setCurrentSubmittingQuestions([
                                                ...currentSubmittingQuestions,
                                                questionItem?.iri
                                            ]);
                                            submitProspectAnswers().then(() => {
                                                emitAnswerSubmitted();
                                                fetchProspectAnswers();
                                            });
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    );
                    return renderQuestion;
                });
                if (renderSectionQuestions.length > 0) {
                    const section =
                        projectSectionMap[Object.keys(projectSectionMap)[index + 1]]?.title;
                    return (
                        <Collapse initiallyExpanded key={`all-questions-section-${section}`}>
                            <Collapse.Visible>
                                <h4 className="presentation-questions__question-section-title">
                                    {section}
                                </h4>
                            </Collapse.Visible>
                            <Collapse.Expandable>{renderSectionQuestions}</Collapse.Expandable>
                        </Collapse>
                    );
                }
                return null;
            });
        }
        return null;
    };

    const renderAllAnsweredQuestion = () => {
        if (presentationQuestions?.length > 0) {
            const sortedQuestions = Object.keys(projectSectionMap)
                ?.slice(1)
                ?.map(section =>
                    getSectionQuestionsData(section, presentationQuestions, prospectAnswers)
                );
            const onlyAnsweredSectionQuestions = sortedQuestions.map(questions =>
                questions.filter(question => question.isAnswered)
            );

            return onlyAnsweredSectionQuestions.map((sortedQuestion, index) => {
                const renderSectionQuestions = sortedQuestion?.map(questionItem => {
                    const { isAnswered, answered } = questionItem;
                    const renderQuestion = (
                        <div
                            className="presentation-questions__question"
                            key={`questions-answered${questionItem?.iri}`}
                        >
                            <h4 className="presentation-administration__section-content-title">
                                {questionItem?.question}
                            </h4>
                            {isAnswered === true &&
                                answered?.map(answer => (
                                    <div
                                        className="presentation-questions__answered-chip"
                                        key={`questions-answered-answer-${answer}`}
                                    >
                                        {answer}
                                    </div>
                                ))}
                        </div>
                    );
                    return renderQuestion;
                });
                if (renderSectionQuestions.length > 0) {
                    const section =
                        projectSectionMap[Object.keys(projectSectionMap)[index + 1]]?.title;
                    return (
                        <Collapse initiallyExpanded key={`questions-answered-section-${section}`}>
                            <Collapse.Visible>
                                <h4 className="presentation-questions__question-section-title">
                                    {section}
                                </h4>
                            </Collapse.Visible>
                            <Collapse.Expandable>{renderSectionQuestions}</Collapse.Expandable>
                        </Collapse>
                    );
                }
                return null;
            });
        }
        return null;
    };

    useEffect(() => {
        setCurrentAnswersData(null);
        setCurrentQuestion(null);
    }, [presentationSection]);

    useEffect(() => {
        if (
            presentationSection !== presentationSectionRef.current ||
            !isEqual(presentationQuestions, presentationQuestionsRef.current) ||
            !isEqual(prospectAnswers, prospectAnswersRef.current)
        ) {
            presentationSectionRef.current = presentationSection;
            presentationQuestionsRef.current = presentationQuestions;
            prospectAnswersRef.current = prospectAnswers;

            const newSectionQuestions = getSectionQuestionsData(
                presentationSection,
                presentationQuestions,
                prospectAnswers
            );

            setSectionQuestions(newSectionQuestions);

            setQuestionsMeta({
                total: presentationQuestions?.length || 0,
                completed: getTotalQuestionsAnswered(presentationQuestions, prospectAnswers)
            });

            if (
                !currentQuestion ||
                newSectionQuestions.find(
                    item => item?.iri === currentQuestion && item?.isAnswered
                ) ||
                (sectionQuestions?.length &&
                    sectionQuestions.find(item => item?.iri !== currentQuestion))
            ) {
                setCurrentQuestion(findCurrentQuestion(newSectionQuestions));
            }
        }
    }, [presentationSection, presentationQuestions, prospectAnswers]);

    useEffect(() => {
        if (prospect?.iri) {
            fetchProspectAnswers().finally(() => {
                fetchProfileQuestions();
            });
        }
    }, [prospect]);

    const handleSettingNextQuestion = () => {
        if (!currentQuestion) setNextQuestion(null);
        if (currentQuestion) setNextQuestion(getNextQuestion());
    };

    useEffect(() => {
        // handleSettingNextQuestion();
    }, [currentQuestion]);

    useEffect(() => {
        handleSettingNextQuestion();
    }, [currentQuestion, sectionQuestions]);

    useEffect(() => {
        if (sessionHelper?.session && !sessionEventsCreated) {
            sessionHelper.session.on('signal:answerSubmitted', () => {
                fetchProspectAnswers();
            });
            setSessionEventsCreated(true);
        }
    }, [sessionHelper]);

    if (!prospect?.iri) {
        return null;
    }

    return (
        <div className="presentation-questions">
            {!!(
                !!presentationQuestionsFetching ||
                !!prospectAnswersFetching ||
                !!submittingAnswerInProgress
            ) && <ElementLoader overlay />}
            {activeTab === 'current-section' && renderCurrentQuestion()}
            {activeTab === 'all-questions' && renderAllQuestion()}
            {activeTab === 'answered-questions' && renderAllAnsweredQuestion()}
        </div>
    );
};

PresentationQuestions.defaultProps = {
    sessionHelper: null,
    sessionProspect: null,
    activeTab: 'current-section'
};

PresentationQuestions.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object]),
    sessionProspect: PropTypes.oneOfType([PropTypes.object]),
    activeTab: PropTypes.string
};

export default PresentationQuestions;
