import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import Image from 'erpcore/components/Image';

import { getEntityUrl } from 'erpcore/utils/RouterManager';

// import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
// import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
const MainNavigation = () => {
    const match = useRouteMatch();
    // const menuId = 'main-navigation';

    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};

    // const projectIri = `/api/projects/${match?.params?.project}`;
    // const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    // const { purchase_calculators: purchaseCalculators, documents } = { ...projectData };

    /*
    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };
     */

    /**
     * Effect used only on component mount
     *
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach(activeItem => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);
     */

    return (
        <>
            <Link
                to={getEntityUrl({
                    session: match?.params?.session,
                    prospectHash: match?.params?.prospectHash
                })}
                className="main-navigation__logo"
            >
                <Image iri={organizationSettings?.settings?.logo} />
            </Link>
            {/*
            <Menu id={menuId}>
                <Menu.Item exact to={projectUrl()} label="Home" />
                <Menu.Item to={projectUrl('/neighbourhood')} label="Neighbourhood" />
                <Menu.Item to={projectUrl('/building-design')} label="Building Design" />
                <Menu.Item to={projectUrl('/floorplans')} label="Floor Plans" />
                <Menu.Item to={projectUrl('/home-tour')} label="Home Tour" />
                <Menu.Item to={projectUrl('/features-and-options')} label="Features & Options" />
                <Menu.Item to={projectUrl('/developer')} label="Developer" />
                {!!purchaseCalculators?.length && (
                    <Menu.Item
                        to={projectUrl('/purchase-calculator')}
                        label="Purchase Calculator"
                    />
                )}
                {(!!documents?.length || !!sessionProspectSpecificDocuments?.length) && (
                    <Menu.Item to={projectUrl('/documents')} label="Documents" />
                )}
                <div className="main-navigation__menu-item--bottom">
                    <Menu.Item
                        exact
                        to={getEntityUrl({
                            session: match?.params?.session,
                            prospectHash: match?.params?.prospectHash
                        })}
                        label="Back To Projects"
                    />
                    <Menu.Item
                        exact
                        to={getEntityUrl({
                            route: '/sign-out'
                        })}
                        label="Sign Out"
                    />
                </div>
            </Menu>
            */}
        </>
    );
};

export default MainNavigation;
