import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Matterport from 'erpcore/components/Matterport';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import Button from 'erpcore/components/Button';
import Gallery from 'erpcore/components/Gallery';
import YoutubeVideo from 'erpcore/components/YoutubeVideo';

import { getProjectData, getProjectIriFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';
import { getEntityUrl } from 'erpcore/utils/RouterManager';
import projectHomeLayout from 'erpcore/utils/LayoutManager/projectHomeLayout';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import './ProjectHome.scss';
import DownloadPDF from 'erpcore/components/DownloadPDF';

const ProjectHome = () => {
    const match = useRouteMatch();
    const [projectDataFetched, setProjectDataFetched] = useState(false);
    const [infoOpened, setInfoOpened] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(state => getProjectIriFetching(state, projectIri));
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};

    const {
        main_gallery: mainGallery,
        matterport_url: matterportUrl,
        intro_video_url: introVideoUrl,
        development_project_logo: developmentProjectLogo
    } = { ...projectData };

    const { content_url: contentUrl, content_url_modified: contentUrlModified } = {
        ...developmentProjectLogo
    };

    if (!activeTab && projectDataFetched) {
        let activateInitialTab = 'none';
        if (mainGallery?.length) {
            activateInitialTab = 'gallery';
        } else if (introVideoUrl) {
            activateInitialTab = 'video';
        } else if (matterportUrl) {
            activateInitialTab = '360';
        }
        setActiveTab(activateInitialTab);
    }

    const isTabActive = (tabKey = null) => {
        return tabKey === activeTab;
    };

    useEffect(() => {
        if (!projectDataFetched && !fetching && !isEmpty(projectData)) {
            setProjectDataFetched(true);
        }
    }, [fetching, projectData]);

    const pageTitle = 'Project Home';
    const { disclosureForm = [] } = {
        ...organizationSettings.settings
    };

    const renderProjectInfoBoxContent = () => {
        return (
            <>
                <div className="project-home__content">
                    <span className="project-home__name">{projectData.name}</span>
                    {projectData.description && (
                        <span
                            className="project-home__description"
                            dangerouslySetInnerHTML={{
                                __html: projectData.description.replace(/(?:\r\n|\r|\n)/g, '<br>')
                            }}
                        />
                    )}
                    {projectData.development_project_logo && (
                        <figure className="project-home__development-project-logo">
                            <img src={contentUrlModified || contentUrl} alt="" />
                        </figure>
                    )}
                    <div className="project-home__action">
                        <PageContent.Row>
                            <Button
                                size="large"
                                href={getEntityUrl({
                                    project: match?.params?.project,
                                    session: match?.params?.session,
                                    prospectHash: match?.params?.prospectHash,
                                    route: '/demo-tour-overview'
                                })}
                                label="Launch Demo"
                            />
                        </PageContent.Row>
                        {disclosureForm.map(item => (
                            <PageContent.Row>
                                {item.link ? (
                                    <Button
                                        size="large"
                                        variation="link"
                                        external
                                        href={item.link}
                                        label={item.title}
                                    />
                                ) : (
                                    <DownloadPDF iri={item.file} title={item.title} />
                                )}
                            </PageContent.Row>
                        ))}
                    </div>
                </div>
                <div className="project-home__attributes">
                    {!!projectData.homes && (
                        <>
                            <span className="project-home__attribute-label">Homes</span>
                            <span className="project-home__attribute-value">
                                {projectData.homes}
                            </span>
                        </>
                    )}
                    {!!projectData.bedrooms && (
                        <>
                            <span className="project-home__attribute-label">Bedrooms</span>
                            <span className="project-home__attribute-value">
                                {projectData.bedrooms}
                            </span>
                        </>
                    )}
                    {!!projectData.number_of_homes && (
                        <>
                            <span className="project-home__attribute-label">NUMBER OF HOMES</span>
                            <span className="project-home__attribute-value">
                                {projectData.number_of_homes}
                            </span>
                        </>
                    )}
                    {!!projectData?.client?.name && (
                        <>
                            <span className="project-home__attribute-label">Developer</span>
                            <span className="project-home__attribute-value">
                                {projectData?.client?.name}
                            </span>
                        </>
                    )}
                    {!!projectData.status && (
                        <>
                            <span className="project-home__attribute-label">Status</span>
                            <span className="project-home__attribute-value">
                                {projectData.status}
                            </span>
                        </>
                    )}
                    {!!projectData.website && (
                        <>
                            <span className="project-home__attribute-label">Website</span>
                            <span className="project-home__attribute-value">
                                {projectData.website}
                            </span>
                        </>
                    )}
                </div>
            </>
        );
    };

    return (
        <LayoutManager
            className="main--project-home"
            slot="main"
            layoutType="override"
            layout={projectHomeLayout}
        >
            <HeadMeta title={pageTitle} />
            {fetching === true && <PageLoader />}
            <PageContent className="project-home">
                {/* all tabs are rendered into DOM, better tab changing experience */}
                <div className="project-home__fullscreen">
                    {!!matterportUrl && (
                        <div
                            className="project-home__fullscreen-item"
                            data-active={isTabActive('360') ? '1' : '0'}
                        >
                            <Matterport fullscreen url={matterportUrl} />
                        </div>
                    )}
                    {!!introVideoUrl && (
                        <div
                            className="project-home__fullscreen-item"
                            data-active={isTabActive('video') ? '1' : '0'}
                        >
                            <YoutubeVideo fullscreen url={introVideoUrl} />
                        </div>
                    )}
                    {!!mainGallery?.length && (
                        <div
                            className="project-home__fullscreen-item"
                            data-active={isTabActive('gallery') ? '1' : '0'}
                        >
                            <Gallery
                                fullscreen
                                hasThumbs={false}
                                hasHeaderContent={false}
                                hasCaption={false}
                                hasMagnifier={false}
                                hasFullFullscreen={false}
                                name="mainGallery"
                                imagesIris={getImagesIrisFromObjects(mainGallery)}
                            />
                        </div>
                    )}
                </div>
                <div className="project-home__menu">
                    <div
                        className="project-home__menu-item project-home__menu-item--wide"
                        data-info-opened={infoOpened ? '1' : '0'}
                    >
                        <Button
                            className="project-home__menu-item-button"
                            onClick={() => setInfoOpened(!infoOpened)}
                            label={infoOpened ? 'CLOSE' : 'OPEN PROJECT INFO'}
                            iconName={infoOpened ? 'minus' : 'plus'}
                            size="large"
                            variation="secondary"
                        />
                        <div className="project-home__info-box">
                            {renderProjectInfoBoxContent()}
                        </div>
                    </div>
                    {!!mainGallery?.length && (
                        <div
                            className="project-home__menu-item"
                            data-active={isTabActive('gallery') ? '1' : '0'}
                        >
                            <Button
                                className="project-home__menu-item-button"
                                onClick={() => setActiveTab('gallery')}
                                label="Gallery"
                                iconName="gallery"
                                labelOnlyAria
                                size="large"
                                variation="secondary"
                            />
                        </div>
                    )}
                    {!!matterportUrl && (
                        <div
                            className="project-home__menu-item"
                            data-active={isTabActive('360') ? '1' : '0'}
                        >
                            <Button
                                className="project-home__menu-item-button"
                                onClick={() => setActiveTab('360')}
                                label="360 view"
                                iconName="view360"
                                labelOnlyAria
                                size="large"
                                variation="secondary"
                            />
                        </div>
                    )}
                    {!!introVideoUrl && (
                        <div
                            className="project-home__menu-item"
                            data-active={isTabActive('video') ? '1' : '0'}
                        >
                            <Button
                                className="project-home__menu-item-button"
                                onClick={() => setActiveTab('video')}
                                label="Video"
                                iconName="video"
                                labelOnlyAria
                                size="large"
                                variation="secondary"
                            />
                        </div>
                    )}
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProjectHome.defaultProps = {};

ProjectHome.propTypes = {};

export default ProjectHome;
