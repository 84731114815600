import React from 'react';
import PropTypes from 'prop-types';
import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    ButtonNext,
    ButtonBack
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './FeaturesListSlider.scss';

const FeaturesListSlider = ({ featuresList }) => {
    return (
        <div className="features-list-slider">
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={200}
                totalSlides={featuresList?.length}
                visibleSlides={3}
                step={3}
                dragStep={3}
                isIntrinsicHeight
                dragEnabled={false}
            >
                {featuresList?.length > 3 && (
                    <div className="features-list-slider__controls">
                        <ButtonBack />
                        <DotGroup />
                        <ButtonNext />
                    </div>
                )}
                <Slider>
                    {featuresList?.map((item, index) => (
                        <Slide
                            index={index}
                            className="features-list-slider__slide"
                            innerClassName="features-list-slider__slide-inner"
                            classNameVisible="features-list-slider__slide--visible"
                        >
                            <h3 className="features-list-slider__slide-title">{item.title}</h3>
                            <div
                                className="features-list-slider__slide-content"
                                dangerouslySetInnerHTML={{
                                    __html: item?.description
                                }}
                            />
                        </Slide>
                    ))}
                </Slider>
            </CarouselProvider>
        </div>
    );
};

FeaturesListSlider.defaultProps = {
    featuresList: null
};

FeaturesListSlider.propTypes = {
    featuresList: PropTypes.oneOfType([PropTypes.array])
};

export default FeaturesListSlider;
