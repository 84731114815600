import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const PresentationJoinForm = ({
    handleSubmit,
    submitting,
    invalid,
    onSubmit,
    disableFormFields,
    requireFormFields
}) => {
    const emailRequireValidate = requireFormFields
        ? valueValidation([{ validator: 'email' }, { validator: 'required' }])
        : undefined;
    const requireValidate = requireFormFields
        ? valueValidation([{ validator: 'required' }])
        : undefined;
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="first_name"
                    id="first_name"
                    fieldProps={{
                        label: 'First name',
                        clearable: true
                    }}
                    fieldAttr={{ disabled: disableFormFields }}
                    validate={requireValidate}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="last_name"
                    id="last_name"
                    fieldProps={{
                        label: 'Last name',
                        clearable: true
                    }}
                    fieldAttr={{ disabled: disableFormFields }}
                    validate={requireValidate}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="email"
                    id="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    fieldAttr={{ disabled: disableFormFields }}
                    validate={emailRequireValidate}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    className="button--full"
                    submit
                    disabled={invalid}
                    loading={submitting}
                    label="Join Session"
                />
            </Form.Row>
        </Form>
    );
};

PresentationJoinForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    invalid: false,
    disableFormFields: true,
    requireFormFields: false
};

PresentationJoinForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    disableFormFields: PropTypes.bool,
    requireFormFields: PropTypes.bool
};

export default reduxForm({
    form: 'PresentationJoinForm',
    enableReinitialize: true
})(PresentationJoinForm);
