import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import NetworkTest from 'opentok-network-test-js';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Button from 'erpcore/components/Button';
import Presentation from 'erpcore/components/Presentation';

import presentationAdminLayout from 'erpcore/utils/LayoutManager/presentationAdminLayout';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import {
    getPresentationRtcData,
    getPresentationFetchingRtcData,
    getPresentationRtcError,
    getPresentationSessionData,
    getPresentationSessionDataByHash,
    getPresentationFetchingSessionData,
    getPresentationJoinedState,
    getPresentationRtcDataTest
} from 'erpcore/screens/Presentation/Presentation.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import PresentationLoading from 'erpcore/components/Presentation/components/PresentationLoading';
import PresentationForbidden from 'erpcore/components/Presentation/components/PresentationForbidden';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import PresentationEnded from 'erpcore/components/Presentation/components/PresentationEnded';
import PresentationJoin from 'erpcore/components/Presentation/components/PresentationJoin';
import { preloadScript } from 'opentok-react';
import { getIdFromIri } from 'erpcore/utils/dto';

const PresentationClient = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const prospectHash = match?.params?.prospectHash;
    const guestHash = match?.params?.guestHash;
    const spectatorHash = match?.params?.spectatorHash;
    const [sessionTestConnection, setSessionTestConnection] = useState(null);
    const rtcData = useSelector(getPresentationRtcData);
    const rtcDataTest = useSelector(getPresentationRtcDataTest);
    const fetchingRtcData = useSelector(getPresentationFetchingRtcData);
    const rtcError = useSelector(getPresentationRtcError);
    const [sessionIri, setSessionIri] = useState(null);
    const sessionDatabyHash =
        useSelector(state =>
            getPresentationSessionDataByHash(state, prospectHash || guestHash || spectatorHash)
        ) || {};
    const sessionData = useSelector(state => getPresentationSessionData(state, sessionIri)) || {};
    const sessionFetching = useSelector(getPresentationFetchingSessionData);
    const meData = useSelector(getMeData);
    const [hasAccess, setHasAccess] = useState(undefined);
    const [sessionGeneralError, setSessionGeneralError] = useState(undefined);
    const [sessionExists, setSessionExists] = useState(undefined);
    const [sessionExpired, setSessionExpired] = useState(undefined);
    const hasJoined = useSelector(getPresentationJoinedState);
    const hasJoinedRef = useRef(false);

    const joinFormValues = useSelector(state => getFormValues('PresentationJoinForm')(state)) || {};

    // const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    // const fetching = useSelector(getProjectFetching);

    // const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    // const projectName = match?.params?.project;

    // eslint-disable-next-line no-nested-ternary
    let subType = 'spectator';
    if (prospectHash) {
        subType = 'prospect';
    } else if (guestHash) {
        subType = 'guest';
    }

    const pageTitle = 'Prospect Presentation';

    const signIn = token => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_IN_WITH_TOKEN,
                token
            })
        );
    };

    const fetchSessionData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                iri
            });
        }).catch(error => ({ error }));
    };

    const copySessionDataFromHash = (iri, hash) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.COPY_SINGLE_SESSION_DATA_FROM_HASH,
                iri,
                hash
            });
        }).catch(error => ({ error }));
    };

    // eslint-disable-next-line no-unused-vars
    const fetchSessionDataByHash = hash => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                hash
            });
        });
    };

    const fetchRtcData = (iri, isTest = false) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_PRESENTATION_RTC_DATA,
                sessionId: getIdFromIri(iri),
                isTest,
                openTokRole: subType === 'spectator' ? 'subscriber' : 'publisher',
                connectionData: {
                    connectionUserType: subType,
                    userName: `${joinFormValues.first_name} ${joinFormValues.last_name}`,
                    email: joinFormValues.email,
                    user: prospectHash || guestHash || spectatorHash
                }
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (sessionData?.iri && !hasJoined) {
            dispatch({
                type: presentationActions.SET_PRESENTATION_ROOM_IDENTIFIER,
                roomIdentifier: sessionData?.iri
            });
            if (isEmpty(rtcDataTest)) {
                fetchRtcData(sessionData?.iri, true);
            }
        } else if (sessionData?.iri && !!hasJoined) {
            dispatch({
                type: presentationActions.SET_PRESENTATION_ROOM_IDENTIFIER,
                roomIdentifier: sessionData?.iri
            });
            if (isEmpty(rtcData)) {
                fetchRtcData(sessionData?.iri);
            }
        }
    }, [sessionData, hasJoined]);

    useEffect(() => {
        if (sessionDatabyHash?.iri) {
            if (!meData?.iri && prospectHash && sessionDatabyHash?.token) {
                signIn(sessionDatabyHash?.token)
                    .then(() => {
                        setHasAccess(true);
                        setSessionIri(sessionDatabyHash?.iri);
                        fetchSessionData(sessionDatabyHash?.iri);
                    })
                    .catch(() => {
                        setHasAccess(false);
                    });
            } else if (!meData?.iri && (guestHash || spectatorHash)) {
                setSessionIri(sessionDatabyHash?.iri);
                copySessionDataFromHash(sessionDatabyHash?.iri, guestHash || spectatorHash);
                setHasAccess(true);
            } else {
                setSessionIri(sessionDatabyHash?.iri);
                fetchSessionData(sessionDatabyHash?.iri);
                setHasAccess(true);
            }
        }
    }, [sessionDatabyHash]);

    useEffect(() => {
        if (hasJoined && !hasJoinedRef.current && sessionTestConnection) {
            sessionTestConnection.stop();
        }
        if (hasJoined !== hasJoinedRef.current) {
            hasJoinedRef.current = hasJoined;
        }
    }, [hasJoined]);

    useEffect(() => {
        if (!isEmpty(rtcDataTest) && !sessionTestConnection) {
            setSessionTestConnection(
                new NetworkTest(window.OT, {
                    ...rtcDataTest
                })
            );
        }
    }, [rtcDataTest]);

    const handleSessionErrorState = error => {
        const { code } = { ...error };
        if (code === 'session.notFoundByHash') {
            setHasAccess(true);
            setSessionExists(false);
        } else if (code === 'session.recapExpired') {
            setHasAccess(true);
            setSessionExpired(true);
        } else {
            setSessionGeneralError(true);
        }
    };

    useEffect(() => {
        if ((prospectHash || guestHash || spectatorHash) && !sessionData?.iri) {
            fetchSessionDataByHash(prospectHash || guestHash || spectatorHash)
                .then(() => {
                    setSessionExists(true);
                    setSessionExpired(false);
                })
                .catch(error => handleSessionErrorState(error));
        }
    }, [prospectHash, guestHash, spectatorHash]);

    if (!fetchingRtcData && !sessionFetching && rtcError === true) {
        return (
            <PresentationForbidden title="WebRTC server error!">
                <Button
                    label="Retry"
                    onClick={() => {
                        fetchRtcData(sessionData?.iri, true);
                        fetchRtcData(sessionData?.iri);
                    }}
                />
            </PresentationForbidden>
        );
    }

    if (sessionExists === false) {
        return <PresentationForbidden title="This session is not available!" />;
    }

    if (sessionExpired === true) {
        return (
            <PresentationEnded sessionData={sessionData} title="This session has expired">
                <p>
                    Unfortunately, the session you are trying to reach has ended.
                    <br />
                    Please contact your Sales Agent for any further assistance and information about
                    the projects.
                </p>
            </PresentationEnded>
        );
    }

    if (sessionGeneralError === true) {
        return <PresentationForbidden title="An unknown error occurred!" />;
    }

    if (hasAccess === undefined) {
        return <PresentationLoading />;
    }

    if (hasAccess === false) {
        return (
            <PresentationForbidden message="You don't have permission to participate in this session!" />
        );
    }

    if (hasAccess === true && !hasJoined) {
        return (
            <PresentationJoin
                showCameraPublisher={subType !== 'spectator'}
                sessionTestConnection={sessionTestConnection}
                subType={subType}
                title={
                    subType === 'spectator'
                        ? 'Welcome to your Home Tour'
                        : 'Welcome to your Interactive Home Tour'
                }
                sessionData={sessionData}
            >
                {subType === 'prospect' && (
                    <>
                        <p>
                            During this experience, you will have the ability to interact with our
                            Sales Team and ask questions throughout the tour, so please ensure you
                            have enabled your microphone and camera to allow for the best possible
                            experience.
                        </p>
                        <p>We will see you shortly!</p>
                    </>
                )}

                {subType === 'guest' && (
                    <>
                        <p>
                            Prior to joining, kindly provide your full name and email address below.
                            During this experience, you will have the ability to interact with our
                            Sales Team and ask questions throughout the tour, so please ensure you
                            have enabled your microphone and camera to allow for the best possible
                            experience.
                        </p>
                        <p>We will see you shortly!</p>
                    </>
                )}
                {subType === 'spectator' && (
                    <>
                        <p>
                            Prior to joining, kindly provide your full name and email address below.
                            With this experience, we will be conducting a presentation style tour
                            where your microphone and camera will be disabled. Our Sales Team will
                            personally be following up with you in the coming days to ensure any
                            questions you may have are answered, walk you through any items that you
                            wish to spend more time on, or book an interactive home tour.
                        </p>
                        <p>We look forward to presenting our community to you!</p>
                    </>
                )}
            </PresentationJoin>
        );
    }

    return (
        <LayoutManager
            className="main--presentation main--presentation-client"
            slot="main"
            layoutType="override"
            forwardProps={{ displayFooter: false }}
            layout={presentationAdminLayout}
        >
            <HeadMeta title={pageTitle} />
            {sessionExists === true && sessionExpired === false && hasAccess === true && (
                <>
                    <Presentation rtcData={rtcData} type="client" sessionData={sessionData} />
                    {!!fetchingRtcData && <PageLoader />}
                </>
            )}
        </LayoutManager>
    );
};

PresentationClient.defaultProps = {};

PresentationClient.propTypes = {};

export default preloadScript(PresentationClient);
