import React from 'react';
import PropTypes from 'prop-types';
import Image from 'erpcore/components/Image';
import { useSelector } from 'react-redux';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import './Logo.scss';

const Logo = ({ className }) => {
    const organizationSettings = useSelector(getOrganizationSettings);
    return <Image className={`${className || ''}`} iri={organizationSettings?.settings?.logo} />;
};

Logo.defaultProps = {
    className: ''
};

Logo.propTypes = {
    className: PropTypes.string
};

export default Logo;
