import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from 'erpcore/components/Tooltip';
import Button from 'erpcore/components/Button';
import has from 'lodash/has';
import './PresentationParticipants.scss';
import {
    getPresentationConnectionUsers,
    getShowParticipants
} from 'erpcore/screens/Presentation/Presentation.selectors';

const PresentationParticipants = ({ sessionHelper, sessionProspects }) => {
    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);
    const connectedUsers = useSelector(getPresentationConnectionUsers);
    const showParticipants = useSelector(getShowParticipants);
    const [micData, setMicData] = useState({});

    const muteUser = user => {
        sessionHelper.session.signal({
            to: sessionHelper.session.connections.get(user.connectionId),
            data: '',
            type: `muteSelf`
        });
    };

    const kickUser = user => {
        sessionHelper.session.signal({
            to: sessionHelper.session.connections.get(user.connectionId),
            data: '',
            type: `kickUser`
        });
    };

    const prospects = connectedUsers.filter(user => user.connectionUserType === 'prospect');
    const guests = connectedUsers.filter(user => user.connectionUserType === 'guest');
    const audience = connectedUsers.filter(user => user.connectionUserType === 'spectator');

    const nonAttandeeProspects = sessionProspects.filter(
        sessionProspect => prospects.filter(user => user.user === sessionProspect.hash).length === 0
    );

    const checkIfHasAudio = (streamId, fallbackBoolean = false) => {
        if (has(micData, streamId)) {
            return micData[streamId];
        }
        return fallbackBoolean;
    };

    useEffect(() => {
        if (sessionHelper?.session && !sessionEventsCreated) {
            sessionHelper.session.on('streamPropertyChanged ', data => {
                const { changedProperty, newValue, stream } = { ...data };
                const { videoType, name } = { ...stream };

                if (
                    changedProperty === 'hasAudio' &&
                    videoType !== 'screen' &&
                    name !== 'screenshare'
                ) {
                    setMicData({ ...micData, [stream?.id]: newValue });
                }
            });

            setSessionEventsCreated(true);
        }
    }, [sessionHelper]);

    return (
        <div>
            {showParticipants && (
                <div className="presentation-participants">
                    <span className="presentation-participants__title">Prospects</span>
                    {prospects.map(user => (
                        <div
                            key={user.connectionId}
                            className="presentation-participants__participant"
                        >
                            <div className="presentation-participants__participant-action">
                                {sessionHelper.streams.filter(
                                    stream =>
                                        stream.connection.id === user.connectionId &&
                                        checkIfHasAudio(stream?.id, stream?.hasAudio)
                                ).length > 0 ? (
                                    <Tooltip direction="up" content="Mute">
                                        <Button
                                            onClick={() => muteUser(user)}
                                            labelOnlyAria
                                            variation="tertiary"
                                            size="small"
                                            iconName="mic"
                                        />
                                    </Tooltip>
                                ) : (
                                    <Button
                                        onClick={() => muteUser(user)}
                                        labelOnlyAria
                                        variation="tertiary"
                                        size="small"
                                        disabled
                                        iconName="micOff"
                                    />
                                )}
                                <Tooltip direction="up" content="Kick">
                                    <Button
                                        onClick={() => kickUser(user)}
                                        labelOnlyAria
                                        variation="tertiary"
                                        size="small"
                                        iconName="deniedRound"
                                    />
                                </Tooltip>
                            </div>
                            <div className="presentation-participants__participant-author">
                                {user.userName}
                            </div>
                        </div>
                    ))}
                    {nonAttandeeProspects.map(user => {
                        const { prospect = {} } = { ...user };
                        const { first_name: firstName = '', last_name: lastName = '' } = {
                            ...prospect
                        };
                        return (
                            <div key={user.hash} className="presentation-participants__participant">
                                <div className="presentation-participants__participant-action"></div>
                                <div className="presentation-participants__participant-author">
                                    {firstName} {lastName}
                                </div>
                            </div>
                        );
                    })}
                    {guests.length > 0 && (
                        <span className="presentation-participants__title">Guests</span>
                    )}
                    {guests.map(user => (
                        <div
                            key={user.connectionId}
                            className="presentation-participants__participant"
                        >
                            <div className="presentation-participants__participant-action">
                                {sessionHelper.streams.filter(
                                    stream =>
                                        stream.connection.id === user.connectionId &&
                                        checkIfHasAudio(stream?.id, stream?.hasAudio)
                                ).length > 0 ? (
                                    <Tooltip direction="up" content="Mute">
                                        <Button
                                            onClick={() => muteUser(user)}
                                            labelOnlyAria
                                            variation="tertiary"
                                            size="small"
                                            iconName="mic"
                                        />
                                    </Tooltip>
                                ) : (
                                    <Button
                                        onClick={() => muteUser(user)}
                                        labelOnlyAria
                                        variation="tertiary"
                                        size="small"
                                        disabled
                                        iconName="micOff"
                                    />
                                )}
                                <Tooltip direction="up" content="Kick">
                                    <Button
                                        onClick={() => kickUser(user)}
                                        labelOnlyAria
                                        variation="tertiary"
                                        size="small"
                                        iconName="deniedRound"
                                    />
                                </Tooltip>
                            </div>
                            <div className="presentation-participants__participant-author">
                                {user.userName}
                            </div>
                        </div>
                    ))}
                    {audience.length > 0 && (
                        <span className="presentation-participants__title">Audience</span>
                    )}
                    {audience.map(user => (
                        <div
                            key={user.connectionId}
                            className="presentation-participants__participant"
                        >
                            <div className="presentation-participants__participant-action">
                                <Tooltip direction="up" content="Kick">
                                    <Button
                                        onClick={() => kickUser(user)}
                                        labelOnlyAria
                                        variation="tertiary"
                                        size="small"
                                        iconName="deniedRound"
                                    />
                                </Tooltip>
                            </div>
                            <div className="presentation-participants__participant-author">
                                {user.userName}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

PresentationParticipants.defaultProps = {
    sessionHelper: {},
    sessionProspects: []
};

PresentationParticipants.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object]),
    sessionProspects: PropTypes.oneOfType([PropTypes.array])
};

export default PresentationParticipants;
