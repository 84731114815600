import React from 'react';
import PropTypes from 'prop-types';
import './Footer.scss';

const Footer = ({ className, children }) => {
    return <footer className={`footer ${className || ''}`}>{children}</footer>;
};

Footer.defaultProps = {
    className: null,
    children: null
};

Footer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default Footer;
