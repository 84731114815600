import React from 'react';
import PageContent from 'erpcore/components/Layout/PageContent';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import slugify from 'react-slugify';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { getEntityUrl, getProjectRoutePaths } from 'erpcore/utils/RouterManager';
import Gallery from 'erpcore/components/Gallery';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';
import PropTypes from 'prop-types';

const ProjectFloorPlansAssets = ({ projectData }) => {
    const match = useRouteMatch();
    const { floorplans, site_plan_images: sitePlanImages } = { ...projectData };
    const routes = [];

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    const renderRoute = route => {
        const exists = routes.filter(existedRoute => existedRoute.indexOf(route) > -1);

        if (!exists.length) {
            routes.push(route);
            return projectUrl(route);
        }

        routes.push(route.concat(`-${exists.length + 1}`));
        return projectUrl(route.concat(`-${exists.length + 1}`));
    };

    return (
        <PageContent.Columns>
            <PageContent.SideMenu>
                <SubNavigation>
                    {!!sitePlanImages && (
                        <Menu.Submenu
                            label="Site plan"
                            className="main-navigation__menu-item--open"
                        >
                            {sitePlanImages?.map((item, index) => (
                                <Menu.SubmenuItem
                                    to={projectUrl(
                                        `/floorplans${
                                            index > 0 ? `/site-plan/${slugify(item.title)}` : ''
                                        }`
                                    )}
                                    label={item.title}
                                    key={slugify(item.title)}
                                    exact
                                />
                            ))}
                        </Menu.Submenu>
                    )}
                    {floorplans?.map(item => (
                        <Menu.Item
                            exact
                            to={renderRoute(`/floorplans/${slugify(item.unit_type)}`)}
                            label={item.unit_type}
                            key={slugify(item.unit_type)}
                        />
                    ))}
                </SubNavigation>
            </PageContent.SideMenu>
            <PageContent.Column>
                <Switch>
                    {sitePlanImages?.map((item, index) => (
                        <Route
                            exact
                            path={getProjectRoutePaths(
                                `/floorplans${index > 0 ? `/site-plan/${slugify(item.title)}` : ''}`
                            )}
                            key={slugify(item.title)}
                        >
                            <Gallery
                                name={`sitePlanGallery-${index}`}
                                imagesIris={getImagesIrisFromObjects(item.gallery)}
                            />
                        </Route>
                    ))}
                    {floorplans?.map((item, index) => (
                        <Route
                            exact
                            path={getProjectRoutePaths(routes[index])}
                            key={slugify(item.unit_type)}
                        >
                            <Gallery
                                name={`florplans-${index}`}
                                imagesIris={getImagesIrisFromObjects(item.images)}
                            />
                        </Route>
                    ))}
                </Switch>
            </PageContent.Column>
        </PageContent.Columns>
    );
};

ProjectFloorPlansAssets.defaultProps = {
    projectData: {}
};

ProjectFloorPlansAssets.propTypes = {
    projectData: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectFloorPlansAssets;
