import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getPresentationProspectSessions } from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import moment from 'moment-timezone';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import './PresentationProspectSessions.scss';

const PresentationProspectSessions = ({ sessionData, prospect }) => {
    const dispatch = useDispatch();
    const presentationProspectSessions = useSelector(state =>
        getPresentationProspectSessions(state, prospect?.iri)
    );
    const prospectRef = useRef(null);

    const fetchProspectSessions = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_PROSPECT_SESSIONS,
                iri
            });
        }).catch(error => {
            return error;
        });
    };

    useEffect(() => {
        if (prospect?.iri && prospect?.iri !== prospectRef?.current?.iri) {
            prospectRef.current = prospect;
            fetchProspectSessions(prospect?.iri);
        }
    }, [prospect]);

    if (!presentationProspectSessions?.length) {
        return null;
    }

    return (
        <div className="presentation-prospect-sessions">
            <h4 className="presentation-administration__section-content-title presentation-administration__section-content-title--uppercase">
                Demo meetings
            </h4>
            {presentationProspectSessions.map(item => {
                const { session, has_attended: hasAttended } = { ...item };
                const { date, sales_agent: salesAgent } = { ...session };
                const { first_name: agentFirstName, last_name: agentLastName } = {
                    ...salesAgent
                };
                if (date) {
                    const currentDate = moment();
                    const momentDate = moment(date);

                    const renderAttendanceInfo = () => {
                        if (sessionData?.iri === session?.iri) {
                            return (
                                <span className="presentation-prospect-sessions__item-info">
                                    (current){' '}
                                </span>
                            );
                        }
                        if (hasAttended) {
                            return (
                                <div
                                    className="presentation-prospect-sessions__item-icon"
                                    data-attended="1"
                                >
                                    <Tooltip content="Attended">
                                        <Svg icon="checkmarkRound" />
                                    </Tooltip>
                                </div>
                            );
                        }
                        if (!hasAttended && momentDate.isBefore(currentDate)) {
                            return (
                                <div
                                    className="presentation-prospect-sessions__item-icon"
                                    data-attended="0"
                                >
                                    <Tooltip content="Has Not Attended">
                                        <Svg icon="deniedRound" />
                                    </Tooltip>
                                </div>
                            );
                        }
                        if (!hasAttended && momentDate.isAfter(currentDate)) {
                            return (
                                <span className="presentation-prospect-sessions__item-info">
                                    (upcoming){' '}
                                </span>
                            );
                        }
                        return null;
                    };

                    return (
                        <div className="presentation-prospect-sessions__item">
                            {renderAttendanceInfo()}
                            <p className="presentation-prospect-sessions__item-title">
                                {!!date && momentDate.format('MMM Do, YYYY h:mma')} with{' '}
                                {agentFirstName} {agentLastName}
                            </p>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
};

PresentationProspectSessions.defaultProps = {
    sessionData: null,
    prospect: null
};

PresentationProspectSessions.propTypes = {
    sessionData: PropTypes.oneOfType([PropTypes.object]),
    prospect: PropTypes.oneOfType([PropTypes.object])
};

export default PresentationProspectSessions;
