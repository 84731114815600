import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import './Switch.scss';

class Switch extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            initialized: false
        };
    }

    static getDerivedStateFromProps(nextProps, context) {
        const { initialized } = context;
        if (!initialized) {
            const { fieldProps, input } = nextProps;
            if (
                input.value &&
                fieldProps.on &&
                fieldProps.on.value &&
                input.value === fieldProps.on.value
            ) {
                return {
                    active: true,
                    initialized: true
                };
            }
            return null;
        }
        return null;
    }

    /**
     *
     * @param ev
     * @param state
     */
    toggleSwitch(ev, state = null) {
        ev.preventDefault();
        const { active } = this.state;
        const { input, fieldProps } = this.props;

        if (state !== null) {
            if (state === 'on') {
                this.setState({ active: true });
                if (Object.keys(input).length) input.onChange(fieldProps.on.value);
            } else if (state === 'off') {
                this.setState({ active: false });
                if (Object.keys(input).length) input.onChange(fieldProps.off.value);
            }
        } else {
            this.setState({ active: !active });
            if (Object.keys(input).length)
                input.onChange(active ? fieldProps.off.value : fieldProps.on.value);
        }
    }

    /**
     *
     * @returns {*}
     */
    renderSingle() {
        const { active } = this.state;
        const { fieldProps, fieldAttr, input } = this.props;
        return (
            <div className="input-switch">
                <div className="input-switch__col">
                    <span
                        role="button"
                        aria-label={`${input.name || ''} switch`}
                        tabIndex={0}
                        onClick={ev => this.toggleSwitch(ev)}
                        onKeyPress={ev => this.toggleSwitch(ev)}
                        className={`input-switch__toggle ${
                            active ? `input-switch__toggle--active input-switch__toggle--green` : ''
                        }`}
                    />
                    <input
                        type="checkbox"
                        className="input-switch__checkbox"
                        aria-label={`${input.name} field`}
                        defaultChecked={active}
                        id={fieldAttr.id}
                        name={fieldAttr.name}
                    />
                </div>
                {fieldProps.label && (
                    <div className="input-switch__col">
                        <label
                            role="button" // eslint-disable-line
                            aria-label={fieldProps.label}
                            tabIndex={0}
                            onClick={ev => this.toggleSwitch(ev)}
                            onKeyPress={ev => this.toggleSwitch(ev)}
                            htmlFor={fieldAttr.id}
                            className="input-switch__option input-switch__option--clickable input-switch__option--active"
                        >
                            {fieldProps.label}
                        </label>
                    </div>
                )}
            </div>
        );
    }

    /**
     *
     * @returns {*}
     */
    renderDual() {
        const { active } = this.state;
        const { fieldProps, fieldAttr, input } = this.props;
        return (
            <div className="input-switch">
                {fieldProps.off.label && (
                    <div className="input-switch__col">
                        <span
                            role="button"
                            tabIndex={0}
                            aria-label={`${fieldProps.off.label} switch`}
                            id={fieldProps.off.id ? fieldProps.off.id : fieldProps.off.label}
                            onClick={ev => this.toggleSwitch(ev, 'off')}
                            onKeyPress={ev => this.toggleSwitch(ev, 'off')}
                            className={`input-switch__option input-switch__option--clickable ${
                                active ? '' : 'input-switch__option--active'
                            }`}
                        >
                            {fieldProps.off.label}
                        </span>
                    </div>
                )}
                <div className="input-switch__col">
                    <span
                        role="button"
                        tabIndex={0}
                        aria-label={`${input.name}`}
                        id={fieldAttr.id}
                        name={fieldAttr.name}
                        onClick={ev => this.toggleSwitch(ev)}
                        onKeyPress={ev => this.toggleSwitch(ev)}
                        className={`input-switch__toggle ${
                            active ? `input-switch__toggle--active` : ''
                        }`}
                    />
                    <input
                        type="checkbox"
                        className="input-switch__checkbox"
                        defaultChecked={active}
                        aria-label={`${input.name} field`}
                    />
                </div>
                {fieldProps.on.label && (
                    <div className="input-switch__col">
                        <span
                            role="button"
                            tabIndex={0}
                            aria-label={`${fieldProps.on.label} switch`}
                            id={fieldProps.on.id ? fieldProps.on.id : fieldProps.off.label}
                            onClick={ev => this.toggleSwitch(ev, 'on')}
                            onKeyPress={ev => this.toggleSwitch(ev, 'on')}
                            className={`input-switch__option input-switch__option--clickable
                             ${active ? 'input-switch__option--active' : ''}`}
                        >
                            {fieldProps.on.label}
                        </span>
                    </div>
                )}
            </div>
        );
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { fieldProps, meta } = this.props;
        return (
            <Fragment>
                {fieldProps.type === 'dual' ? this.renderDual() : this.renderSingle()}
                {meta.touched && meta.error && (
                    <span className="input-switch__error">
                        {meta.error.message ? meta.error.message : meta.error}
                    </span>
                )}
            </Fragment>
        );
    }
}

Switch.defaultProps = {
    input: {},
    meta: {},
    fieldProps: {
        on: { label: 'On', value: true, id: 'switch-on-true' },
        off: { label: 'Off', value: false, id: 'switch-off-false' },
        type: 'single'
    },
    fieldAttr: {}
};
Switch.propTypes = {
    fieldProps: PropTypes.shape({
        on: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
        }),
        off: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
        }),
        type: PropTypes.oneOf(['single', 'dual']),
        label: PropTypes.string
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default Switch;
