import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UIFx from 'uifx';
import { useRouteMatch } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import handRaiseMp3 from 'erpcore/assets/sounds/handRaise.mp3';
import {
    getIsOtherAdminSharingCamera,
    getShowChat,
    getCountUnreadedChatMessages,
    getShowRaisedHands,
    getCountRaisedHands,
    getShowParticipants,
    getPresentationConnectionUsers,
    getDefaultCameraState,
    getDefaultMicrophoneState,
    getCameraStreamCreated
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import ElementLoader from 'erpcore/components/ElementLoader';
import './PresentationControls.scss';

const PresentationControls = ({
    sessionHelper,
    theme,
    type,
    getMyConnection,
    userName,
    chatEnabled,
    raisingHandsEnabled,
    getAdminConnections
}) => {
    const match = useRouteMatch();
    const spectatorHash = match?.params?.spectatorHash;
    const dispatch = useDispatch();

    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);

    const showChat = useSelector(getShowChat);
    const countUnreadedChatMessages = useSelector(getCountUnreadedChatMessages);
    const [chatPop, setChatPop] = useState(false);

    const countRaisedHands = useSelector(getCountRaisedHands);
    const showRaiseHands = useSelector(getShowRaisedHands);
    const [wavingHandPop, setWavingHandPop] = useState(false);
    const handRaiseSound = new UIFx(handRaiseMp3);

    const showParticipants = useSelector(getShowParticipants);
    const connectedUsers = useSelector(getPresentationConnectionUsers);

    // video
    const [isPublishingCameraInProgress, setIsPublishingCameraInProgress] = useState(false);
    const defaultCameraState = useSelector(getDefaultCameraState);
    const defaultMicrophoneState = useSelector(getDefaultMicrophoneState);
    const [isPublisherCameraActive, setIsPublisherCameraActive] = useState(defaultCameraState);
    const [isPublisherMicActive, setIsPublisherMicActive] = useState(defaultMicrophoneState);
    const cameraStreamCreated = useSelector(getCameraStreamCreated);
    const isOtherAdminSharingCamera = useSelector(getIsOtherAdminSharingCamera);

    const dispatchChatOpened = newShowChat => {
        dispatch({
            type: presentationActions.SET_SHOW_CHAT,
            response: newShowChat
        });
    };

    const dispatchRaisedHandsOpened = newShowRaisedHands => {
        dispatch({
            type: presentationActions.SET_SHOW_RAISED_HANDS,
            response: newShowRaisedHands
        });
    };

    const dispatchParticipantsOpened = newShowParticipants => {
        dispatch({
            type: presentationActions.SET_SHOW_PARTICIPANTS,
            response: newShowParticipants
        });
    };

    const getMyCameraStreamPublisher = () => {
        if (sessionHelper?.session?.streams?.length) {
            const myCameraStream = sessionHelper.session.streams.find(item => {
                return (
                    item.connection.id === getMyConnection()?.id &&
                    item.videoType !== 'screen' &&
                    item.name !== 'screenshare'
                );
            });
            if (myCameraStream) {
                return myCameraStream?.publisher;
            }
        }

        return null;
    };

    const handleIsPublishingCameraInProgress = nextState => {
        setIsPublishingCameraInProgress(nextState);
        if (nextState === true) {
            setTimeout(() => {
                setIsPublishingCameraInProgress(false);
            }, 2000);
        }
    };

    const publishCamera = () => {
        const myCameraStreamPublisher = getMyCameraStreamPublisher();
        if (myCameraStreamPublisher) {
            handleIsPublishingCameraInProgress(true);
            const nextState = !myCameraStreamPublisher?.stream?.hasVideo;
            myCameraStreamPublisher.publishVideo(nextState);
        }
    };

    const publishAudio = () => {
        const myCameraStreamPublisher = getMyCameraStreamPublisher();
        if (myCameraStreamPublisher) {
            const nextState = !myCameraStreamPublisher?.stream?.hasAudio;
            myCameraStreamPublisher.publishAudio(nextState);
        }
    };

    const handleShowChat = () => {
        dispatchRaisedHandsOpened(false);
        dispatchParticipantsOpened(false);
        dispatchChatOpened(!showChat);
    };

    const handleShowRaiseHand = () => {
        dispatchChatOpened(false);
        dispatchParticipantsOpened(false);
        dispatchRaisedHandsOpened(!showRaiseHands);
    };

    const handleRaiseHand = () => {
        const targetConnections = getAdminConnections();
        if (targetConnections?.length) {
            targetConnections.forEach(connection => {
                sessionHelper.session.signal({
                    to: connection,
                    data: JSON.stringify({
                        author: userName,
                        date: new Date()
                    }),
                    type: `raiseHandSubmitted`
                });
            });
        }
        handRaiseSound.play(0.5);
        setTimeout(() => {
            setWavingHandPop(false);
        }, 2000);
    };

    const handleShowParticipants = () => {
        dispatchChatOpened(false);
        dispatchRaisedHandsOpened(false);
        dispatchParticipantsOpened(!showParticipants);
    };

    useEffect(() => {
        if (countUnreadedChatMessages > 0) {
            setChatPop(true);
            setTimeout(() => {
                setChatPop(false);
            }, 2000);
        }
    }, [countUnreadedChatMessages]);

    useEffect(() => {
        if (countRaisedHands > 0) {
            setWavingHandPop(true);
            setTimeout(() => {
                setWavingHandPop(false);
            }, 2000);
        }
    }, [countRaisedHands]);

    useEffect(() => {
        if (sessionHelper?.session && !sessionEventsCreated) {
            sessionHelper.session.on('streamPropertyChanged ', data => {
                const { changedProperty, newValue, stream } = { ...data };
                const { connection, videoType, name } = { ...stream };

                if (
                    changedProperty === 'hasVideo' &&
                    videoType !== 'screen' &&
                    name !== 'screenshare' &&
                    getMyConnection()?.connectionId === connection.id
                ) {
                    setIsPublisherCameraActive(newValue);
                    handleIsPublishingCameraInProgress(false);
                }

                if (
                    changedProperty === 'hasAudio' &&
                    videoType !== 'screen' &&
                    name !== 'screenshare' &&
                    getMyConnection()?.connectionId === connection.id
                ) {
                    setIsPublisherMicActive(newValue);
                }
            });

            setSessionEventsCreated(true);
        }
    }, [sessionHelper]);

    return (
        <div className="presentation-controls" data-theme={theme}>
            <div className="presentation-controls__left">
                {!!sessionHelper &&
                    !!cameraStreamCreated &&
                    !spectatorHash &&
                    !isOtherAdminSharingCamera && (
                        <>
                            <Tooltip
                                direction="up"
                                content={
                                    isPublisherCameraActive ? 'Turn off camera' : 'Turn on camera'
                                }
                            >
                                <button
                                    onClick={() => publishCamera()}
                                    type="button"
                                    className="presentation-controls__button"
                                    aria-label={
                                        isPublisherCameraActive
                                            ? 'Turn off camera'
                                            : 'Turn on camera'
                                    }
                                >
                                    {!isPublishingCameraInProgress && (
                                        <Svg
                                            className="presentation-controls__button-icon"
                                            icon={isPublisherCameraActive ? 'camera' : 'cameraOff'}
                                        />
                                    )}
                                    {!!isPublishingCameraInProgress && <ElementLoader />}
                                </button>
                            </Tooltip>
                            <Tooltip
                                direction="up"
                                content={
                                    isPublisherMicActive
                                        ? 'Turn off microphone'
                                        : 'Turn on microphone'
                                }
                            >
                                <button
                                    onClick={() => publishAudio()}
                                    type="button"
                                    className="presentation-controls__button"
                                    aria-label={
                                        isPublisherMicActive
                                            ? 'Turn off microphone'
                                            : 'Turn on microphone'
                                    }
                                    // style={{ margin: '0 -2px' }}
                                >
                                    <Svg
                                        className="presentation-controls__button-icon"
                                        icon={isPublisherMicActive ? 'mic' : 'micOff'}
                                    />
                                </button>
                            </Tooltip>
                        </>
                    )}
                {!!sessionHelper &&
                    !spectatorHash &&
                    !isOtherAdminSharingCamera &&
                    !!(chatEnabled || raisingHandsEnabled) && (
                        <span className="presentation-controls__divider" />
                    )}
                {!!sessionHelper && chatEnabled && (
                    <Tooltip direction="up" content={showChat ? 'Hide chat' : 'Show chat'}>
                        <button
                            onClick={() => handleShowChat()}
                            type="button"
                            className={`presentation-controls__button${chatPop ? ' pop' : ''}`}
                            aria-label={showChat ? 'Hide chat' : 'Show chat'}
                        >
                            <Svg className="presentation-controls__button-icon" icon="callChat" />
                            {countUnreadedChatMessages > 0 && (
                                <span
                                    style={{ marginLeft: '2px' }}
                                    className="presentation-controls__button-annotation presentation-controls__button-annotation--chat"
                                >
                                    {countUnreadedChatMessages}
                                </span>
                            )}
                        </button>
                    </Tooltip>
                )}
                {!!sessionHelper &&
                    raisingHandsEnabled &&
                    !spectatorHash &&
                    (type === 'admin' ? (
                        <Tooltip
                            content={showRaiseHands ? 'Hide Raise Hands' : 'Show Raised Hands'}
                        >
                            <button
                                onClick={() => handleShowRaiseHand()}
                                type="button"
                                className={`presentation-controls__button${
                                    wavingHandPop ? ' pop' : ''
                                }`}
                                aria-label={
                                    showRaiseHands ? 'Hide Raise Hands' : 'Show Raised Hands'
                                }
                            >
                                <Svg className="presentation-controls__button-icon" icon="hand" />
                                {countRaisedHands > 0 && (
                                    <span className="presentation-controls__button-annotation presentation-controls__button-annotation--raise-hand">
                                        {countRaisedHands}
                                    </span>
                                )}
                            </button>
                        </Tooltip>
                    ) : (
                        <Tooltip content="Raise Hand">
                            <button
                                onClick={() => {
                                    setWavingHandPop(true);
                                    handleRaiseHand();
                                }}
                                type="button"
                                className={`presentation-controls__button${
                                    wavingHandPop ? ' pop' : ''
                                }`}
                                disabled={wavingHandPop}
                                aria-label="Raise Hand"
                            >
                                <Svg className="presentation-controls__button-icon" icon="hand" />
                            </button>
                        </Tooltip>
                    ))}
            </div>
            <div className="presentation-controls__right">
                {!!sessionHelper && type === 'admin' && (
                    <Tooltip
                        direction="up"
                        content={
                            showParticipants ? 'Hide participant list' : 'Show participant list'
                        }
                    >
                        <button
                            onClick={() => handleShowParticipants()}
                            type="button"
                            className="presentation-controls__button"
                            aria-label={
                                showParticipants ? 'Hide participant list' : 'Show participant list'
                            }
                        >
                            <Svg className="presentation-controls__button-icon" icon="users" />
                            {connectedUsers.length > 1 && (
                                <span className="presentation-controls__button-annotation presentation-controls__button-annotation--participants">
                                    {connectedUsers.length - 1}
                                </span>
                            )}
                        </button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

PresentationControls.defaultProps = {
    theme: 'light',
    type: null,
    sessionHelper: null,
    getMyConnection: () => {},
    getAdminConnections: () => {},
    chatEnabled: false,
    raisingHandsEnabled: false,
    userName: ''
};

PresentationControls.propTypes = {
    theme: PropTypes.oneOf(['light', 'dark']),
    type: PropTypes.oneOf(['client', 'admin']),
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    getMyConnection: PropTypes.oneOfType([PropTypes.func]),
    getAdminConnections: PropTypes.oneOfType([PropTypes.func]),
    chatEnabled: PropTypes.bool,
    raisingHandsEnabled: PropTypes.bool,
    userName: PropTypes.string
};

export default PresentationControls;
