import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';

/**
 *
 * All project screens are wrapped inside this Component. It is done within RouterManager.
 */
function ProjectWrapper({ children }) {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const projectIri = match?.params?.project ? `/api/projects/${match.params.project}` : null;
    const prevProjectIri = useRef();

    const fetchProjectData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: iri || projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (projectIri && projectIri !== prevProjectIri.current) {
            prevProjectIri.current = projectIri;
            fetchProjectData(projectIri);
        }
    }, [projectIri]);

    return <>{children}</>;
}
ProjectWrapper.defaultProps = {
    children: null
};
ProjectWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node])
};

export default ProjectWrapper;
