import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';
import PageLoader from 'erpcore/components/PageLoader';
import Svg from 'erpcore/components/Svg';
import { getPresentationSessionDataByHash } from 'erpcore/screens/Presentation/Presentation.selectors';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';

export const getSessionProspectSpecificDocuments = (
    projectData,
    prospectHash = null,
    sessionDatabyHash = null
) => {
    let sessionProspectSpecificDocuments = [];
    if (prospectHash && sessionDatabyHash?.sessionProspects?.length) {
        sessionProspectSpecificDocuments = sessionDatabyHash.sessionProspects
            .find(item => item.hash === prospectHash)
            ?.documents?.reduce((accumulator, currentValue) => {
                if (currentValue?.project === projectData?.iri) {
                    accumulator.push(currentValue);
                }
                return accumulator;
            }, []);
    }
    return sessionProspectSpecificDocuments;
};

export const isAvailable = (projectData, sessionProspectSpecificDocuments) => {
    const { documents } = {
        ...projectData
    };

    return documents?.length || !!sessionProspectSpecificDocuments?.length;
};

const ProjectDocuments = () => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);
    const dispatch = useDispatch();
    const sessionDatabyHash =
        useSelector(state =>
            getPresentationSessionDataByHash(state, match?.params?.prospectHash)
        ) || {};
    const { documents } = { ...projectData };
    const { sessionProspects } = { ...sessionDatabyHash };

    const pageTitle = 'Documents';

    const documentIris = projectData?.documents?.map(document => document.documentIri) || [];

    const sessionProspectSpecificDocuments = getSessionProspectSpecificDocuments(
        projectData,
        match?.params?.prospectHash,
        sessionDatabyHash
    );

    const fetchDocuments = () => {
        if (sessionProspectSpecificDocuments?.length) {
            sessionProspectSpecificDocuments.map(document => {
                return documentIris.push(document?.document);
            });
        }

        if (documentIris?.length > 0) {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: projectActions.START_FETCHING_DOCUMENTS,
                    params: { 'filters[id][in]': documentIris },
                    promise: { resolve, reject },
                    iri: projectIri
                });
            });
        }

        return null;
    };

    useEffect(() => {
        fetchDocuments();
    }, [documents, sessionProspects]);

    const getFileExtension = (filename = '') => {
        if (!filename) return '';
        return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2); // eslint-disable-line no-bitwise
    };

    const renderProjectDocuments = () => {
        return projectData?.documents?.map(document => {
            const matchedDocumentData = projectData?.documentsData?.find(
                documentsData => documentsData.iri === document.documentIri
            );

            const { title } = { ...document };
            const { content_url: url } = { ...matchedDocumentData };
            const extension = getFileExtension(url);

            if (matchedDocumentData) {
                return (
                    <div key={matchedDocumentData.iri} className="projects__document">
                        <div className="projects__document-title">
                            <div className="projects__document-icon">
                                <Svg icon="document" />
                                <span className="projects__document-extension">
                                    <strong>{extension}</strong>
                                </span>
                            </div>
                            <p className="projects__document-title">{title}</p>
                        </div>
                        <div className="projects__document-actions">
                            <a href={url} rel="noopener noreferrer" target="_blank" download>
                                {extension.toLowerCase() !== 'pdf' ? 'DOWNLOAD' : 'OPEN LINK'}
                            </a>
                        </div>
                    </div>
                );
            }

            return null;
        });
    };

    const renderSessionProspectDocuments = () => {
        return sessionProspectSpecificDocuments.map(document => {
            const matchedDocumentData = projectData?.documentsData?.find(
                documentsData => documentsData?.iri === document?.document
            );

            const { name } = { ...document };
            const { content_url: url } = { ...matchedDocumentData };
            const extension = getFileExtension(url);

            if (matchedDocumentData) {
                return (
                    <div key={matchedDocumentData.iri} className="projects__document">
                        <div className="projects__document-title">
                            <div className="projects__document-icon">
                                <Svg icon="document" />
                                <span className="projects__document-extension">
                                    <strong>{extension}</strong>
                                </span>
                            </div>
                            <p className="projects__document-title">{name}</p>
                        </div>
                        <div className="projects__document-actions">
                            <a href={url} rel="noopener noreferrer" target="_blank" download>
                                {extension.toLowerCase() !== 'pdf' ? 'DOWNLOAD' : 'OPEN LINK'}
                            </a>
                        </div>
                    </div>
                );
            }

            return null;
        });
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="documents" />
            <MobileQuickNav section="documents" />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <div className="projects__documents">
                    {!!sessionProspectSpecificDocuments?.length && (
                        <>
                            <h3 className="project__documents-title">My Documents</h3>
                            {renderSessionProspectDocuments()}
                            <br />
                        </>
                    )}
                    {!!projectData?.documents?.length && (
                        <>
                            {!!match?.params?.prospectHash && (
                                <h3 className="project__documents-title">Project Documents</h3>
                            )}
                            {renderProjectDocuments()}
                        </>
                    )}
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProjectDocuments.defaultProps = {};

ProjectDocuments.propTypes = {};

export default ProjectDocuments;
