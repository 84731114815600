import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
const SubNavigation = ({ children }) => {
    const menuId = 'sub-navigation';

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach(activeItem => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);

    return (
        <Menu className="main-navigation--sub-navigation" id={menuId}>
            {children}
        </Menu>
    );
};

SubNavigation.defaultProps = {
    children: null
};

SubNavigation.propTypes = {
    children: PropTypes.node
};

export default SubNavigation;
