import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';
import { getProjectSectionsData, getNextSection } from 'erpcore/utils/navigationManager';
import './MobileQuickNav.scss';
import { getEntityUrl } from 'erpcore/utils/RouterManager';
import Svg from 'erpcore/components/Svg';

const MobileQuickNav = ({ section }) => {
    const match = useRouteMatch();
    // const location = useLocation();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    // const fetching = useSelector(getProjectFetching);

    // const section = getSectionFromPath(location?.pathname) || 'demo-tour-overview';

    const projectSectionedData = getProjectSectionsData(projectData);

    const title = projectSectionedData?.[section]?.title;

    if (title) {
        const nextSection = getNextSection(projectData, section);

        return (
            <div className="mobile-quick-nav">
                <h2 className="mobile-quick-nav__title">{title}</h2>
                {!!nextSection?.title && (
                    <NavLink
                        to={getEntityUrl({
                            project: match?.params?.project,
                            session: match?.params?.session,
                            prospectHash: match?.params?.prospectHash,
                            route: nextSection?.route
                        })}
                        className="mobile-quick-nav__next"
                    >
                        <span className="mobile-quick-nav__next-label">{nextSection.title}</span>
                        <Svg className="mobile-quick-nav__next-icon" icon="arrow" />
                    </NavLink>
                )}
            </div>
        );
    }

    return null;
};

MobileQuickNav.defaultProps = {
    section: null
};

MobileQuickNav.propTypes = {
    section: PropTypes.string
};

export default MobileQuickNav;
