import React from 'react';
import PropTypes from 'prop-types';
import './Chip.scss';

const Chip = ({ children, isActive, emphasise, onClick }) => {
    if (onClick) {
        return (
            <button
                type="button"
                onClick={onClick}
                className="chip chip--button"
                data-active={isActive ? '1' : '0'}
                data-emphasise={emphasise ? '1' : '0'}
            >
                {children}
            </button>
        );
    }

    return (
        <div
            className="chip"
            data-active={isActive ? '1' : '0'}
            data-emphasise={emphasise ? '1' : '0'}
        >
            {children}
        </div>
    );
};

Chip.Wrapper = function Wrapper({ children }) {
    return <div className="chip-wrapper">{children}</div>;
};
Chip.Wrapper.defaultProps = {
    children: null
};
Chip.Wrapper.propTypes = {
    children: PropTypes.node
};

Chip.defaultProps = {
    children: null,
    isActive: false,
    emphasise: false,
    onClick: null
};

Chip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isActive: PropTypes.bool,
    emphasise: PropTypes.bool,
    onClick: PropTypes.func
};

export default Chip;
