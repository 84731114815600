import React, { useEffect, useState } from 'react';
import { useRouteMatch, useLocation, useHistory, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import slugify from 'react-slugify';
import isEmpty from 'lodash/isEmpty';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import PanoramaViewer from 'erpcore/components/PanoramaViewer';
import Matterport from 'erpcore/components/Matterport';
import YoutubeVideo from 'erpcore/components/YoutubeVideo';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectData, getProjectIriFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';
import Gallery from 'erpcore/components/Gallery';
import { getProjectRoutePaths, getEntityUrl } from 'erpcore/utils/RouterManager';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';

export const isAvailable = projectData => {
    const {
        home_tour_sales_centre3d_tour: homeTourSalesCentre3dTour = [],
        home_tour_gallery: homeTourGallery = [],
        home_tour_panoramas: homeTourPanoramas = [],
        home_tour_sales_centre_video_tour: homeTourSalesCentreVideoTour = null,
        home_tour_show_home_video_tour: homeTourShowHomeVideoTour = null
    } = {
        ...projectData
    };

    return (
        (!!Array.isArray(homeTourSalesCentre3dTour) && !!homeTourSalesCentre3dTour?.length) ||
        !!homeTourSalesCentreVideoTour ||
        !!homeTourShowHomeVideoTour ||
        (!!homeTourGallery && !!homeTourGallery?.length) ||
        (!!homeTourPanoramas && !!homeTourPanoramas?.length)
    );
};

const ProjectHomeTour = () => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const [projectDataFetched, setProjectDataFetched] = useState(false);
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(state => getProjectIriFetching(state, projectIri));
    const location = useLocation();
    const history = useHistory();

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    const pageTitle = 'Home Tour';

    const {
        home_tour_sales_centre3d_tour: homeTourSalesCentre3dTour = [],
        home_tour_gallery: homeTourGallery = [],
        home_tour_panoramas: homeTourPanoramas = []
    } = {
        ...projectData
    };

    const maybeRedirect = () => {
        // check if redirect is needed if on /home-tour page and project data is fetched
        if (location.pathname === projectUrl('/home-tour') && projectDataFetched) {
            let redirectTo = false;

            if (!Array.isArray(homeTourSalesCentre3dTour) || !homeTourSalesCentre3dTour?.length) {
                if (homeTourPanoramas?.length) {
                    redirectTo = projectUrl(
                        `/home-tour/360-view/${slugify(homeTourPanoramas[0].title)}`
                    );
                } else if (projectData.home_tour_show_home_video_tour) {
                    redirectTo = projectUrl('/home-tour/home-video-tour');
                } else if (projectData.home_tour_sales_centre_video_tour) {
                    redirectTo = projectUrl('/home-tour/sales-centre-video-tour');
                }
            }

            if (redirectTo) {
                history.push(redirectTo);
            }
        }
    };

    useEffect(() => {
        if (!projectDataFetched && !fetching && !isEmpty(projectData)) {
            setProjectDataFetched(true);
        }
    }, [fetching, projectData]);

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="home-tour" />
            <MobileQuickNav section="home-tour" />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <PageContent.Columns>
                    <PageContent.SideMenu>
                        <SubNavigation>
                            {!!Array.isArray(homeTourSalesCentre3dTour) &&
                                !!homeTourSalesCentre3dTour?.length && (
                                    <Menu.Submenu label="3D Tour">
                                        {homeTourSalesCentre3dTour?.map((item, index) => (
                                            <Menu.SubmenuItem
                                                to={projectUrl(
                                                    `/home-tour${
                                                        index > 0
                                                            ? `/3d-tour/${slugify(
                                                                  item.title || index + 1
                                                              )}`
                                                            : ''
                                                    }`
                                                )}
                                                label={item.title || (index + 1).toString()}
                                                key={slugify(
                                                    item.title ||
                                                        `homeTourSalesCentre3dTour-${index + 1}`
                                                )}
                                                exact
                                            />
                                        ))}
                                    </Menu.Submenu>
                                )}
                            {!!projectData.home_tour_sales_centre_video_tour && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/home-tour/sales-centre-video-tour')}
                                    label="Sales Centre Video Tour"
                                />
                            )}
                            {!!projectData.home_tour_show_home_video_tour && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/home-tour/home-video-tour')}
                                    label="Show Home Video Tour"
                                />
                            )}
                            {homeTourPanoramas?.length > 0 && (
                                <Menu.Submenu label="360 View">
                                    {homeTourPanoramas?.map(item => (
                                        <Menu.SubmenuItem
                                            exact
                                            to={projectUrl(
                                                `/home-tour/360-view/${slugify(item.title)}`
                                            )}
                                            label={item.title}
                                            key={slugify(item.title)}
                                        />
                                    ))}
                                </Menu.Submenu>
                            )}
                            {homeTourGallery?.length > 0 && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/home-tour/gallery')}
                                    label="Home Tour Gallery"
                                />
                            )}
                        </SubNavigation>
                    </PageContent.SideMenu>
                    <Switch>
                        {maybeRedirect()}
                        {!!Array.isArray(homeTourSalesCentre3dTour) &&
                            homeTourSalesCentre3dTour?.map((item, index) => (
                                <Route
                                    exact
                                    path={getProjectRoutePaths(
                                        `/home-tour${
                                            index > 0
                                                ? `/3d-tour/${slugify(item.title || index + 1)}`
                                                : ''
                                        }`
                                    )}
                                    key={item.title || `homeTourSalesCentre3dTour-${index + 1}`}
                                >
                                    <PageContent.Column className="page-content__column--no-padding">
                                        {item?.url && <Matterport url={item.url} />}
                                    </PageContent.Column>
                                </Route>
                            ))}
                        {!!homeTourGallery?.length && (
                            <Route exact path={getProjectRoutePaths('/home-tour/gallery')}>
                                <PageContent.Column>
                                    <Gallery
                                        name="home_tour_gallery"
                                        imagesIris={getImagesIrisFromObjects(homeTourGallery)}
                                    />
                                </PageContent.Column>
                            </Route>
                        )}
                        {homeTourPanoramas?.map(item => (
                            <Route
                                exact
                                path={getProjectRoutePaths(
                                    `/home-tour/360-view/${slugify(item.title)}`
                                )}
                                key={slugify(item.title)}
                            >
                                <PageContent.Column>
                                    <PanoramaViewer
                                        name={slugify(item.title)}
                                        imageIri={item.image}
                                    />
                                </PageContent.Column>
                            </Route>
                        ))}
                        {!!projectData.home_tour_show_home_video_tour && (
                            <Route exact path={getProjectRoutePaths('/home-tour/home-video-tour')}>
                                <PageContent.Column className="page-content__column--no-padding">
                                    <YoutubeVideo
                                        url={projectData.home_tour_show_home_video_tour}
                                    />
                                </PageContent.Column>
                            </Route>
                        )}
                        {!!projectData.home_tour_sales_centre_video_tour && (
                            <Route
                                exact
                                path={getProjectRoutePaths('/home-tour/sales-centre-video-tour')}
                            >
                                <PageContent.Column className="page-content__column--no-padding">
                                    <YoutubeVideo
                                        url={projectData.home_tour_sales_centre_video_tour}
                                    />
                                </PageContent.Column>
                            </Route>
                        )}
                    </Switch>
                </PageContent.Columns>
            </PageContent>
        </LayoutManager>
    );
};

ProjectHomeTour.defaultProps = {};

ProjectHomeTour.propTypes = {};

export default ProjectHomeTour;
