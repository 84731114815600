import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import Button from 'erpcore/components/Button';
import './PageHeader.scss';

const PageHeader = ({ title, subtitle, children, className, backButtonLink, link }) => {
    return (
        <header className={`page-header ${className || ''}`}>
            {backButtonLink && (
                <div className="page-header__back">
                    <Button
                        className="page-header__back-button"
                        label=""
                        iconName="arrowLeft"
                        href={backButtonLink}
                    />
                </div>
            )}

            {(title || subtitle) && (
                <div className="page-header__content">
                    {title && (
                        <h1 className="page-header__title">
                            <span>{title}</span> {!!link && link}
                        </h1>
                    )}
                    {subtitle && <p className="page-header__subtitle">{subtitle}</p>}
                </div>
            )}

            {children && (
                <div className="page-header__actions">
                    <span className="page-header__actions-dots">
                        <Svg icon="action" />
                    </span>
                    <div className="page-header__actions-items">{children}</div>
                </div>
            )}
        </header>
    );
};

PageHeader.defaultProps = {
    title: null,
    subtitle: null,
    children: null,
    className: '',
    backButtonLink: null,
    link: null
};

PageHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    backButtonLink: PropTypes.string,
    link: PropTypes.node
};

export default PageHeader;
