import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as galleryActions } from 'erpcore/components/Gallery/Gallery.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';

const sortResponse = (data, params) => {
    if (params?.['filters[id][in]']?.length && data && Array.isArray(data) && data?.length) {
        const sortedArray = [];
        const responseArray = [...data];
        const inputArray = [...params?.['filters[id][in]']];
        inputArray.forEach(iri => {
            const match = responseArray.find(item => {
                return item.id === iri || item.iri === iri;
            });
            if (match) {
                sortedArray.push({ ...match });
            }
        });
        if (sortedArray.length) {
            return sortedArray;
        }
    }
    return data;
};

/**
 * Fetch Images
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* fetchImages({ promise, params, name }) {
    try {
        const fetchImagesAPI = yield restClient.get(`/api/media-objects?include=versions`, {
            params
        });
        const sortedDataResponse = yield {
            ...fetchImagesAPI?.data,
            data: sortResponse(fetchImagesAPI?.data?.data, params)
        };
        const dtoFetchImagesAPI = yield dto(sortedDataResponse);
        yield put({
            type: galleryActions.FETCHING_IMAGES_SUCCESSFUL,
            name
        });
        yield put({
            type: galleryActions.STORE_IMAGES_DATA,
            response: dto(dtoFetchImagesAPI),
            name
        });
        yield call(promise.resolve, dtoFetchImagesAPI);
    } catch (error) {
        yield put({
            type: galleryActions.FETCHING_IMAGES_FAILED,
            name
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}
/**
 * Register action to watcher
 */
export default [takeLatest(galleryActions.START_FETCHING_IMAGES, fetchImages)];
