import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';
import ProjectFloorPlansAssets from 'erpcore/screens/Projects/components/ProjectFloorplansAssets';
import ProjectFloorPlansFromUnits from 'erpcore/screens/Projects/components/ProjectFloorplansFromUnits';

export const isAvailable = projectData => {
    const {
        floorplans,
        site_plan_images: sitePlanImages,
        use_unit_type_floorplans: useUnitTypeFloorplans
    } = { ...projectData };

    if (!useUnitTypeFloorplans) {
        return (
            (!!floorplans && !!floorplans?.length) || (!!sitePlanImages && !!sitePlanImages?.length)
        );
    }

    let { key_plans: keyPlans = [], unit_types: unitTypes = [] } = { ...projectData };
    keyPlans = keyPlans.filter(keyPlan => !keyPlan.deleted);
    unitTypes = unitTypes.filter(unitType => !unitType.deleted);
    return !!(keyPlans?.length || unitTypes?.length);
};

const ProjectFloorplans = () => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);
    const pageTitle = 'Floor Plans';
    const { use_unit_type_floorplans: useUnitTypeFloorplans = false } = { ...projectData };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="floorplans" />
            <MobileQuickNav section="floorplans" />
            <PageContent>
                {fetching === true && <PageLoader content />}
                {useUnitTypeFloorplans ? (
                    <ProjectFloorPlansFromUnits projectData={projectData} />
                ) : (
                    <ProjectFloorPlansAssets projectData={projectData} />
                )}
            </PageContent>
        </LayoutManager>
    );
};

ProjectFloorplans.defaultProps = {};

ProjectFloorplans.propTypes = {};

export default ProjectFloorplans;
