import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'erpcore/components/Layout/Logo';
import Notification from 'erpcore/components/Notification';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import './PresentationForbidden.scss';
import PropTypes from 'prop-types';

const PresentationForbidden = ({ title, message, children }) => {
    const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;

    return (
        <div className="presentation-forbidden">
            <Link to={`${routePrefix}/`} className="presentation-forbidden__logo">
                <Logo />
            </Link>
            <div className="presentation-forbidden__content">
                <Notification icon type="error" title={title} text={message} />
                {children}
            </div>
        </div>
    );
};

PresentationForbidden.defaultProps = {
    title: null,
    message: null,
    children: null
};

PresentationForbidden.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default PresentationForbidden;
