import React from 'react';
import PropTypes from 'prop-types';
import Embed from 'erpcore/components/Embed';
import './Matterport.scss';

const Matterport = ({ url, fullscreen }) => {
    const iframe = (
        <iframe
            title="Matterport"
            width="600"
            height="600"
            src={url}
            frameBorder="0"
            allowFullScreen
            allow="vr"
        />
    );

    return (
        <div className={`matterport-block ${fullscreen ? 'matterport-block--fullscreen' : ''}`}>
            <Embed code={iframe} renderType="component" fullscreen={fullscreen} />
        </div>
    );
};

Matterport.defaultProps = {
    url: null,
    fullscreen: false
};

Matterport.propTypes = {
    url: PropTypes.string,
    fullscreen: PropTypes.bool
};

export default Matterport;
