export const actions = {
    START_FETCHING_IMAGES: 'START_FETCHING_IMAGES',
    FETCHING_IMAGES_SUCCESSFUL: 'FETCHING_IMAGES_SUCCESSFUL',
    FETCHING_IMAGES_FAILED: 'FETCHING_IMAGES_FAILED',
    STORE_IMAGES_DATA: 'STORE_IMAGES_DATA'
};

export const initialState = {
    fetching: false,
    error: null
};

export default (state = initialState, { type, response, name }) => {
    switch (type) {
        case actions.START_FETCHING_IMAGES: {
            return {
                ...state,
                [name]: {
                    fetching: true
                }
            };
        }
        case actions.FETCHING_IMAGES_SUCCESSFUL: {
            return {
                ...state,
                [name]: {
                    fetching: false
                }
            };
        }
        case actions.FETCHING_IMAGES_FAILED: {
            return {
                ...state,
                [name]: {
                    fetching: false
                },
                error: response
            };
        }
        case actions.STORE_IMAGES_DATA: {
            return {
                ...state,
                [name]: response
            };
        }
        default:
            return state;
    }
};
