import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import Chip from 'erpcore/components/Chip';
import ElementLoader from 'erpcore/components/ElementLoader';

import {
    getPresentationSection,
    getPresentationProspectAnswers,
    getPresentationProspectAnswersFetching
} from 'erpcore/screens/Presentation/Presentation.selectors';

import './PresentationProspectAnswers.scss';

const projectSectionMap = {
    'project-home': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    'demo-tour-overview': {
        title: 'Home Page',
        erpKey: 'home-page'
    },
    neighbourhood: {
        title: 'Neighbourhood',
        erpKey: 'neighbourhood'
    },
    'building-design': {
        title: 'Building design',
        erpKey: 'building-design'
    },
    floorplans: {
        title: 'Floor plans',
        erpKey: 'floor-plans'
    },
    'home-tour': {
        title: 'Home tour',
        erpKey: 'home-tour'
    },
    'features-and-options': {
        title: 'Features & Options',
        erpKey: 'features-and-options'
    },
    developer: {
        title: 'Developer',
        erpKey: 'developer'
    },
    'purchase-calculator': {
        title: 'Purchase Calculator',
        erpKey: 'purchase-calculator'
    },
    documents: {
        title: 'Documents',
        erpKey: 'documents'
    }
};

const PresentationProspectAnswers = ({ sessionProspect }) => {
    const [sectionAnswers, setSectionAnswers] = useState(null);
    const presentationSection = useSelector(getPresentationSection);

    const { prospect } = { ...sessionProspect };

    const prospectAnswers =
        useSelector(state => getPresentationProspectAnswers(state, prospect?.iri)) || [];
    const prospectAnswersFetching = useSelector(state =>
        getPresentationProspectAnswersFetching(state)
    );

    const presentationSectionRef = useRef(null);
    const prospectAnswersRef = useRef(null);

    const getSectionAnswersData = (section = undefined, answers = []) => {
        let output = [];

        if (answers?.length) {
            output = answers.reduce((accumulator, currentValue) => {
                if (currentValue?.question?.section === projectSectionMap?.[section]?.erpKey) {
                    accumulator.push({
                        ...currentValue
                    });
                }
                return accumulator;
            }, []);
        }

        return output;
    };

    const renderSectionAnswers = () => {
        if (sectionAnswers?.length) {
            return sectionAnswers.map(profileAnswer => {
                if (profileAnswer?.question && profileAnswer?.answers?.length) {
                    return (
                        <div
                            className="presentation-prospect-answers__item"
                            key={profileAnswer?.iri}
                            data-id={profileAnswer?.iri}
                        >
                            <h5 className="presentation-administration__section-content-title presentation-administration__section-content-title--small">
                                {profileAnswer?.question?.question}
                            </h5>
                            <Chip.Wrapper>
                                {profileAnswer?.answers.map((item, i) => {
                                    return (
                                        <Chip
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`${i}-${item}`}
                                            isActive
                                        >
                                            {item}
                                        </Chip>
                                    );
                                })}
                            </Chip.Wrapper>
                        </div>
                    );
                }
                return null;
            });
        }

        return null;
    };

    useEffect(() => {
        if (
            presentationSection !== presentationSectionRef.current ||
            !isEqual(prospectAnswers, prospectAnswersRef.current)
        ) {
            presentationSectionRef.current = presentationSection;
            prospectAnswersRef.current = prospectAnswers;
            const newSectionAnswers = getSectionAnswersData(presentationSection, prospectAnswers);
            setSectionAnswers(newSectionAnswers);
        }
    }, [presentationSection, prospectAnswers]);

    if (!prospect?.iri || !sectionAnswers?.length) {
        return null;
    }

    return (
        <div className="presentation-prospect-answers">
            <h4 className="presentation-administration__section-content-title presentation-administration__section-content-title--uppercase">
                Profile questions
            </h4>
            {!!prospectAnswersFetching && <ElementLoader overlay />}
            {renderSectionAnswers()}
        </div>
    );
};

PresentationProspectAnswers.defaultProps = {
    sessionProspect: null
};

PresentationProspectAnswers.propTypes = {
    sessionProspect: PropTypes.oneOfType([PropTypes.object])
};

export default PresentationProspectAnswers;
