import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Tooltip from 'erpcore/components/Tooltip';
import './PresentationRaisedHands.scss';
import Button from 'erpcore/components/Button';
import {
    getRaisedHandsData,
    getShowRaisedHands
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

const PresentationRaisedHands = ({ sessionHelper, getOtherAdminConnections }) => {
    const dispatch = useDispatch();
    const raisedHandsData = useSelector(getRaisedHandsData) || [];
    const showRaisedHands = useSelector(getShowRaisedHands);

    const emitClearRaisedHand = owner => {
        const targetConnections = getOtherAdminConnections();
        if (targetConnections?.length) {
            targetConnections.forEach(connection => {
                sessionHelper.session.signal({
                    to: connection,
                    data: JSON.stringify({
                        owner
                    }),
                    type: `clearRaiseHandSubmitted`
                });
            });
        }
    };

    const handleClearHandRaise = owner => {
        dispatch({
            type: presentationActions.CLEAR_RAISED_HAND,
            response: {
                owner
            }
        });
        emitClearRaisedHand(owner);
    };

    return (
        <div>
            {showRaisedHands ? (
                <div className="presentation-raised-hands">
                    {raisedHandsData.length > 0
                        ? orderBy(raisedHandsData, ['date'], ['desc']).map(hand => (
                              <div key={hand.date} className="presentation-raised-hands__hand">
                                  <div className="presentation-raised-hands__hand-action">
                                      <Tooltip direction="up" content="Lower Hand">
                                          <Button
                                              onClick={() => handleClearHandRaise(hand.owner)}
                                              labelOnlyAria
                                              variation="tertiary"
                                              size="small"
                                              iconName="close"
                                          />
                                      </Tooltip>
                                  </div>
                                  <div className="presentation-raised-hands__hand-author">
                                      {hand.author}
                                      {'  '}
                                      {moment(hand.date).format('h:mm A')}
                                  </div>
                              </div>
                          ))
                        : 'No Raised Hands.'}
                </div>
            ) : null}
        </div>
    );
};

PresentationRaisedHands.defaultProps = {
    sessionHelper: {},
    getOtherAdminConnections: () => {}
};

PresentationRaisedHands.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object]),
    getOtherAdminConnections: PropTypes.oneOfType([PropTypes.func])
};

export default PresentationRaisedHands;
