import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import Gallery from 'erpcore/components/Gallery';
import FeaturesListSlider from 'erpcore/components/FeaturesListSlider';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';
import { getProjectRoutePaths, getEntityUrl } from 'erpcore/utils/RouterManager';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';

export const isAvailable = projectData => {
    const {
        features_gallery: featuresGallery = [],
        home_tour_color_board: homeTourColorBoard = [],
        features_list: featuresList = [],
        options_gallery: optionsGallery = []
    } = {
        ...projectData
    };

    return (
        (!!featuresGallery && !!featuresGallery?.length) ||
        (!!homeTourColorBoard && !!homeTourColorBoard?.length) ||
        (!!featuresList && !!featuresList?.length) ||
        (!!optionsGallery && !!optionsGallery?.length)
    );
};

const ProjectFeatureAndOptions = () => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    const pageTitle = 'Features & Options';

    const { features_list: featuresList } = {
        ...projectData
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="features-and-options" />
            <MobileQuickNav section="features-and-options" />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <PageContent.Columns>
                    <PageContent.SideMenu>
                        <SubNavigation>
                            {!!projectData.features_gallery && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/features-and-options')}
                                    label="Features Gallery"
                                />
                            )}
                            {!!projectData.home_tour_color_board && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/features-and-options/color-board')}
                                    label="Colour Schemes"
                                />
                            )}
                            {!!featuresList && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/features-and-options/features-list')}
                                    label="Features List"
                                />
                            )}
                            {!!projectData.options_gallery && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/features-and-options/options-gallery')}
                                    label="Options Gallery"
                                />
                            )}
                        </SubNavigation>
                    </PageContent.SideMenu>
                    <PageContent.Column>
                        <Switch>
                            {!!projectData.features_gallery && (
                                <Route exact path={getProjectRoutePaths('/features-and-options')}>
                                    <Gallery
                                        name="featuresGallery"
                                        imagesIris={getImagesIrisFromObjects(
                                            projectData.features_gallery
                                        )}
                                    />
                                </Route>
                            )}
                            {!!projectData.home_tour_color_board && (
                                <Route
                                    exact
                                    path={getProjectRoutePaths('/features-and-options/color-board')}
                                >
                                    <Gallery
                                        name="colorBoard"
                                        imagesIris={getImagesIrisFromObjects(
                                            projectData.home_tour_color_board
                                        )}
                                    />
                                </Route>
                            )}
                            {!!featuresList && (
                                <Route
                                    exact
                                    path={getProjectRoutePaths(
                                        '/features-and-options/features-list'
                                    )}
                                >
                                    <FeaturesListSlider featuresList={featuresList} />
                                </Route>
                            )}
                            {!!projectData.options_gallery && (
                                <Route
                                    exact
                                    path={getProjectRoutePaths(
                                        '/features-and-options/options-gallery'
                                    )}
                                >
                                    <Gallery
                                        name="optionsGallery"
                                        imagesIris={getImagesIrisFromObjects(
                                            projectData.options_gallery
                                        )}
                                    />
                                </Route>
                            )}
                        </Switch>
                    </PageContent.Column>
                </PageContent.Columns>
            </PageContent>
        </LayoutManager>
    );
};

ProjectFeatureAndOptions.defaultProps = {};

ProjectFeatureAndOptions.propTypes = {};

export default ProjectFeatureAndOptions;
