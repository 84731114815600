import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import {
    getPresentationPhase,
    getPresentationSessionTerminated,
    getPresentationSessionTerminationInProgress
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';

const PresentationEndModal = ({ confirmAction, cancelAction }) => {
    const dispatch = useDispatch();
    const presentationPhase = useSelector(getPresentationPhase);
    const sessionTerminated = useSelector(getPresentationSessionTerminated);
    const sessionTerminationInProgress = useSelector(getPresentationSessionTerminationInProgress);
    const [isError, setIsError] = useState(false);

    const setSessionTerminationInProgress = status => {
        dispatch({
            type: presentationActions.SET_SESSION_TERMINATION_IN_PROGRESS,
            data: status
        });
    };

    const localConfirmAction = () => {
        setSessionTerminationInProgress(true);
        confirmAction();
    };

    const notifyingErpFailed =
        presentationPhase === 'ended' && !sessionTerminated && !sessionTerminationInProgress;

    if (notifyingErpFailed && !isError) {
        // persist error
        setIsError(true);
    }

    const title = isError ? (
        <span style={{ color: 'red' }}>Error!</span>
    ) : (
        'Are you sure you want to close the demo session?'
    );
    const subtitle = isError ? (
        <>
            All prospects have been disconnected but{' '}
            <strong>failed to mark Session as Completed</strong>.
        </>
    ) : (
        'All prospects will be disconnected and Session will be marked as Completed.'
    );

    return (
        <Modal
            root="body"
            variation="medium"
            opened
            onClose={() => cancelAction()}
            title={title}
            subtitle={subtitle}
        >
            <Button
                loading={sessionTerminationInProgress}
                size="small"
                label={isError ? 'RETRY' : 'YES'}
                onClick={() => localConfirmAction()}
                variation="secondary"
            />
            {!isError && <Button size="small" label="NO" onClick={() => cancelAction()} />}
        </Modal>
    );
};

PresentationEndModal.defaultProps = {
    confirmAction: () => {},
    cancelAction: () => {}
};

PresentationEndModal.propTypes = {
    confirmAction: PropTypes.func,
    cancelAction: PropTypes.func
};

export default PresentationEndModal;
