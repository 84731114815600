import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Offices Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Sessions = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'session.recapExpired':
            return (
                <Notification
                    identifier={identifier}
                    title=""
                    text="This session has expired!"
                    type="error"
                    expire="3500"
                />
            );
        case 'session.notFoundByHash':
            return (
                <Notification
                    identifier={identifier}
                    title=""
                    text="This session is not available!"
                    type="error"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

Sessions.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Sessions.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Sessions;
