import React from 'react';
import PropTypes from 'prop-types';
import './Embed.scss';

const Embed = ({ renderType, code, style, innerStyle, fullscreen }) => {
    return (
        <div className={`embed-block ${fullscreen ? 'embed-block--fullscreen' : ''}`} {...style}>
            {renderType === 'html' ? (
                <div
                    className="embed-block__inner"
                    dangerouslySetInnerHTML={{
                        __html: code
                    }}
                />
            ) : (
                <div className="embed-block__inner" {...innerStyle}>
                    {code}
                </div>
            )}
        </div>
    );
};

Embed.defaultProps = {
    renderType: 'html',
    code: null,
    style: null,
    innerStyle: null,
    fullscreen: false
};

Embed.propTypes = {
    renderType: PropTypes.oneOf(['html', 'component']),
    code: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.oneOfType([PropTypes.object]),
    innerStyle: PropTypes.oneOfType([PropTypes.object]),
    fullscreen: PropTypes.bool
};

export default Embed;
