import React from 'react';
import Button from 'erpcore/components/Button';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getPresentationSidebarOpened } from 'erpcore/screens/Presentation/Presentation.selectors';
import './PresentationSidebarToggle.scss';

const PresentationSidebarToggle = () => {
    const dispatch = useDispatch();
    const sidebarOpened = useSelector(getPresentationSidebarOpened);

    const toggleSidebar = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.SET_PRESENTATION_SIDEBAR_OPENED,
                sidebarOpened: !sidebarOpened
            });
        }).catch(error => ({ error }));
    };

    return (
        <Button
            className={`presentation-sidebar-toggle presentation-sidebar-toggle--${
                sidebarOpened ? 'opened' : 'closed'
            }`}
            onClick={toggleSidebar}
            labelOnlyAria
            label="Toggle sidebar"
            iconName="arrowLeft"
        />
    );
};

PresentationSidebarToggle.defaultProps = {};

PresentationSidebarToggle.propTypes = {};

export default PresentationSidebarToggle;
