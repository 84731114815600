import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './PresentationCameras.scss';
import PresentationCameraPublisher from 'erpcore/components/Presentation/components/PresentationCameraPublisher';
import PresentationCameraSubscriber from 'erpcore/components/Presentation/components/PresentationCameraSubscriber';
import PresentationControls from 'erpcore/components/Presentation/components/PresentationControls';
import Svg from 'erpcore/components/Svg';

const PresentationCameras = ({
    theme,
    sessionHelper,
    type,
    subscriberClassName,
    getMyConnection,
    userName,
    showCameraPublisher,
    chatEnabled,
    raisingHandsEnabled,
    getAdminConnections
}) => {
    // eslint-disable-next-line no-unused-vars
    const [pinnedStream, setPinnedStream] = useState(null);
    const pinnedStreamRef = useRef(null);
    // eslint-disable-next-line no-unused-vars
    const [currentSlide, setCurrentSlide] = useState(1);
    const [otherCameraStreamIds, setOtherCameraStreamIds] = useState([]); // triggers rerender

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    /*
    const getOtherCameraStream = () => {
        const streams = sessionHelper?.streams;
        if (streams?.length) {
            return streams.find(stream => {
                const streamConnectionCustomData = getConnectionCustomData(stream?.connection);
                return (
                    stream?.videoType !== 'screen' &&
                    (type === 'admin' ? ['prospect', 'guest', 'spectator'] : ['admin']).includes(
                        streamConnectionCustomData?.connectionUserType
                    )
                );
            });
        }
        return null;
    };
    */

    const getOtherCameraStreams = () => {
        const streams = sessionHelper?.streams;
        if (streams?.length) {
            return streams
                .filter(stream => {
                    const streamConnectionCustomData = getConnectionCustomData(stream?.connection);
                    return (
                        stream?.videoType !== 'screen' &&
                        stream?.name !== 'screenshare' &&
                        (type === 'admin'
                            ? ['prospect', 'guest']
                            : ['admin', 'prospect', 'guest']
                        ).includes(streamConnectionCustomData?.connectionUserType)
                    );
                })
                .sort((a, b) => {
                    const aType = getConnectionCustomData(a?.connection)?.connectionUserType;
                    const bType = getConnectionCustomData(b?.connection)?.connectionUserType;
                    // a.hasAudio, a.hasVideo
                    if (a?.id === pinnedStreamRef.current) {
                        return -1;
                    }
                    if (b?.id === pinnedStreamRef.current) {
                        return 1;
                    }
                    if (aType === 'admin' && bType === 'admin') {
                        return 0;
                    }
                    if (aType === 'admin') {
                        return -1;
                    }
                    if (aType === 'prospect' && bType === 'prospect') {
                        return 0;
                    }
                    if (aType === 'prospect' && bType === 'guest') {
                        return -1;
                    }
                    if (aType === 'guest' && bType === 'prospect') {
                        return 1;
                    }
                    return 0;
                });
        }
        return [];
    };

    /*
    const runSetOtherCameraStream = () => {
        const targetStreamScreen = getOtherCameraStream();
        setOtherCameraStreamCreationTime(targetStreamScreen?.creationTime);
        setOtherCameraStream({
            [targetStreamScreen?.creationTime]: targetStreamScreen
        });
    };
    */

    const runSetOtherCameraStreams = () => {
        const targetStreams = getOtherCameraStreams();
        setOtherCameraStreamIds(targetStreams.map(item => item.id));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            runSetOtherCameraStreams();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        pinnedStreamRef.current = pinnedStream;
    }, [pinnedStream]);

    const styleShow = {};
    const styleHide = { display: 'none' };

    const sliderCameras = [];

    if (showCameraPublisher) {
        sliderCameras.push({ type: 'publisher', key: 'publisher' });
    }

    if (otherCameraStreamIds?.length) {
        otherCameraStreamIds.forEach((streamKey, i) => {
            const prepareObject = {
                type: 'subscriber',
                key: streamKey
            };
            if (i === 0) {
                prepareObject.firstSubscriber = true;
            }
            sliderCameras.push(prepareObject);
        });
    }

    const hasPinnedStream =
        pinnedStream && !!otherCameraStreamIds.find(item => item === pinnedStream);

    const itemCountPerSlide = 3;
    const totalSlidesCount = Math.ceil(
        (otherCameraStreamIds?.length - 1 + (showCameraPublisher ? 1 : 0)) / itemCountPerSlide
    );
    let lowerBound = (currentSlide - 1) * itemCountPerSlide + 1;
    let upperBound = currentSlide * itemCountPerSlide;
    const visibleStreamIds = [];
    const visibleStreamIdsTest = [];
    if (showCameraPublisher) {
        if (currentSlide === 1) {
            visibleStreamIds.push('publisher');
        } else {
            lowerBound -= 1;
            upperBound -= 1;
        }
    }
    let getNextUntilFulfilled = false;
    otherCameraStreamIds.forEach((streamId, i) => {
        if (visibleStreamIds?.length < itemCountPerSlide) {
            if ((i >= lowerBound && i <= upperBound) || getNextUntilFulfilled) {
                if (streamId !== pinnedStream) {
                    visibleStreamIds.push(streamId);
                    visibleStreamIdsTest.push(i);
                } else {
                    getNextUntilFulfilled = true;
                }
            }
        }
    });

    if (currentSlide > 1 && currentSlide > totalSlidesCount) {
        setCurrentSlide(totalSlidesCount);
    }

    return (
        <div className="presentation-cameras">
            <div className="presentation-cameras__list">
                <div
                    className="presentation-cameras__list-videos"
                    data-has-pinned={hasPinnedStream ? '1' : '0'}
                    data-has-publisher={showCameraPublisher ? '1' : '0'}
                    data-current-slide={currentSlide}
                >
                    {!!sliderCameras?.length &&
                        sliderCameras.map(camera => {
                            if (camera.type === 'publisher') {
                                return (
                                    <div
                                        style={
                                            visibleStreamIds.includes('publisher')
                                                ? styleShow
                                                : styleHide
                                        }
                                        className={`presentation-camera presentation-camera--publisher ${subscriberClassName}`}
                                    >
                                        <PresentationCameraPublisher
                                            key={camera.key}
                                            type={type}
                                            getMyConnection={getMyConnection}
                                            sessionHelper={sessionHelper}
                                            userName={userName}
                                        />
                                    </div>
                                );
                            }
                            if (camera.type === 'subscriber') {
                                const stream = getOtherCameraStreams().find(
                                    item => item.id === camera.key
                                );
                                return (
                                    <PresentationCameraSubscriber
                                        style={
                                            (!hasPinnedStream && camera.firstSubscriber) ||
                                            pinnedStream === stream?.id ||
                                            visibleStreamIds.includes(stream?.id)
                                                ? styleShow
                                                : styleHide
                                        }
                                        type={type}
                                        key={camera.key}
                                        sessionHelper={sessionHelper}
                                        stream={stream}
                                        subscriberClassName={`presentation-camera--subscriber ${
                                            pinnedStream === stream?.id
                                                ? 'presentation-camera--pinned'
                                                : ''
                                        } ${subscriberClassName}`}
                                        isPinned={pinnedStream === stream?.id}
                                        setPinnedStream={() => setPinnedStream(stream?.id)}
                                        unsetPinnedStream={() => setPinnedStream(null)}
                                    />
                                );
                            }
                            return null;
                        })}
                </div>
                <div className="presentation-cameras__list-arrows">
                    {currentSlide > 1 && (
                        <button
                            onClick={() => setCurrentSlide(currentSlide - 1)}
                            type="button"
                            className="presentation-cameras__list-arrows-button presentation-cameras__list-arrows-button-prev"
                        >
                            <Svg icon="arrowLeft" />
                        </button>
                    )}
                    {currentSlide < totalSlidesCount && (
                        <button
                            onClick={() => setCurrentSlide(currentSlide + 1)}
                            type="button"
                            className="presentation-cameras__list-arrows-button presentation-cameras__list-arrows-button-next"
                        >
                            <Svg icon="arrowRight" />
                        </button>
                    )}
                </div>
            </div>
            <div className="presentation-cameras__controls">
                <PresentationControls
                    theme={theme}
                    type={type}
                    sessionHelper={sessionHelper}
                    getMyConnection={getMyConnection}
                    getAdminConnections={getAdminConnections}
                    chatEnabled={chatEnabled}
                    raisingHandsEnabled={raisingHandsEnabled}
                    userName={userName}
                />
            </div>
        </div>
    );
};

PresentationCameras.defaultProps = {
    theme: 'light',
    type: null,
    sessionHelper: null,
    subscriberClassName: null,
    getMyConnection: () => {},
    getAdminConnections: () => {},
    userName: 'Anonymous',
    showCameraPublisher: false,
    chatEnabled: false,
    raisingHandsEnabled: false
};

PresentationCameras.propTypes = {
    theme: PropTypes.oneOf(['light', 'dark']),
    type: PropTypes.oneOf(['client', 'admin']),
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    subscriberClassName: PropTypes.string,
    getMyConnection: PropTypes.oneOfType([PropTypes.func]),
    getAdminConnections: PropTypes.oneOfType([PropTypes.func]),
    userName: PropTypes.string,
    showCameraPublisher: PropTypes.bool,
    chatEnabled: PropTypes.bool,
    raisingHandsEnabled: PropTypes.bool
};

export default PresentationCameras;
