import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import SignInForm from 'erpcore/screens/Projects/components/SignIn/components/SignInForm';

import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const SignIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { state } = { ...location };
    const title = 'Sign In';

    const onSubmitSignInForm = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_IN,
                credentials: formData
            })
        )
            .then(() => {
                // If user was redirected to Sign In screen
                // After successful Sign In, redirect user to intended route
                if (state?.from?.pathname !== '/sign-out') {
                    history.replace(state?.from);
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <>
            <HeadMeta title={title} />
            <h2 className="front-main__title">Sign in</h2>
            <SignInForm onSubmit={onSubmitSignInForm} />
        </>
    );
};

export default SignIn;
