import { createSelector } from 'reselect';

export const getPresentationRtcData = state => state?.presentation?.rtcData;

export const getPresentationRtcDataTest = state => state?.presentation?.rtcDataTest;

export const getPresentationFetchingRtcData = state => state?.presentation?.fetchingRtcData;

export const getPresentationRtcError = state => state?.presentation?.rtcError;

export const getPresentationSidebarOpened = state => state?.presentation?.sidebarOpened;

export const getPresentationProspectSidebarOpened = state =>
    state.presentation?.prospectSidebarOpened;

export const getPresentationOthersScreenSize = state => state?.presentation?.othersScreenSize;

export const getPresentationMyScreenSize = state => state?.presentation?.myScreenSize;

export const getPresentationMyScreenShareActiveStatus = state =>
    state?.presentation?.isMyScreenShareActive;

export const getPresentationRoomName = state => state?.presentation?.roomIdentifier;

export const getPresentationSessionData = (state, iri) => state?.presentation?.sessionData?.[iri];

export const getPresentationSessionDataByHash = (state, hash) =>
    state?.presentation?.sessionData?.[hash];

export const getPresentationFetchingSessionData = state =>
    state.presentation?.singleSessionFetching;

export const getPresentationJoinedState = state => state?.presentation?.hasJoined;

export const getPresentationPhase = state => state?.presentation?.phase;

export const getPresentationSection = state => state?.presentation?.section;

export const getPresentationCurrentProject = state => state?.presentation?.currentProject;

export const getPresentationConnectionUsers = state => state?.presentation?.connectionUsers;

export const getPresentationProspectLoginUpdated = state =>
    state?.presentation?.prospectLoginUpdated;

export const getPresentationSessionTerminated = state => state?.presentation?.sessionTerminated;

export const getPresentationSessionTerminationInProgress = state =>
    state?.presentation?.sessionTerminationInProgress;

export const getPresentationQuestionsMeta = state => state?.presentation?.questionsMeta;

export const getPresentationQuestions = state => state?.presentation?.questions;

export const getPresentationQuestionsFetching = state => state?.presentation?.questionsFetching;

export const getPresentationProspectAnswers = (state, iri) =>
    state?.presentation?.prospectAnswers?.[iri];

export const getPresentationProspectAnswersFetching = state =>
    state?.presentation?.prospectAnswersFetching;

export const getPresentationProspectSessions = (state, iri) =>
    state?.presentation?.prospectSessions?.[iri];

export const getPresentationProspectSessionsFetching = state =>
    state?.presentation?.prospectSessionsFetching;

export const getPresentationError = state => state?.presentation?.error;

export const getGlobalGuidelines = state => state?.presentation?.globalGuidelines;

export const getGlobalGuidelinesFetching = state => state?.presentation?.globalGuidelinesFetching;

export const getChatData = state => state?.presentation?.chat;

export const getCountUnreadedChatMessages = state => state?.presentation?.countUnreadedChatMessages;

export const getChatFetching = state => state?.presentation?.chatFetching;

export const getShowChat = state => state?.presentation?.showChat;

export const getRaisedHandsData = state => state?.presentation?.raisedHands;

export const getShowRaisedHands = state => state?.presentation?.showRaisedHands;

export const getCountRaisedHands = state => state?.presentation?.raisedHands?.length;

export const getIsRaisedHand = (state, owner) =>
    state?.presentation?.raisedHands?.filter(item => item.owner === owner)?.length > 0;

export const getShowParticipants = state => state?.presentation?.showParticipants;

export const getDefaultCameraState = state => state?.presentation?.defaultCameraState;

export const getDefaultMicrophoneState = state => state?.presentation?.defaultMicrophoneState;

export const getCameraAccessDenied = state => state?.presentation?.cameraAccessDenied;

export const getCameraDialogOpened = state => state?.presentation?.cameraDialogOpened;

export const getCameraStreamCreated = state => state?.presentation?.cameraStreamCreated;

export const getIsOtherAdminSharingCamera = state => state?.presentation?.isOtherAdminSharingCamera;

export default createSelector([
    getPresentationRtcData,
    getPresentationRtcDataTest,
    getPresentationFetchingRtcData,
    getPresentationRtcError,
    getPresentationSidebarOpened,
    getPresentationProspectSidebarOpened,
    getPresentationOthersScreenSize,
    getPresentationMyScreenSize,
    getPresentationMyScreenShareActiveStatus,
    getPresentationRoomName,
    getPresentationSessionData,
    getPresentationSessionDataByHash,
    getPresentationFetchingSessionData,
    getPresentationJoinedState,
    getPresentationPhase,
    getPresentationSection,
    getPresentationCurrentProject,
    getPresentationConnectionUsers,
    getPresentationProspectLoginUpdated,
    getPresentationSessionTerminated,
    getPresentationSessionTerminationInProgress,
    getPresentationQuestionsMeta,
    getPresentationQuestions,
    getPresentationQuestionsFetching,
    getPresentationProspectAnswers,
    getPresentationProspectAnswersFetching,
    getPresentationProspectSessions,
    getPresentationProspectSessionsFetching,
    getPresentationError,
    getGlobalGuidelines,
    getGlobalGuidelinesFetching,
    getChatData,
    getCountUnreadedChatMessages,
    getChatFetching,
    getShowChat,
    getRaisedHandsData,
    getCountRaisedHands,
    getShowRaisedHands,
    getIsRaisedHand,
    getShowParticipants,
    getDefaultCameraState,
    getDefaultMicrophoneState,
    getCameraAccessDenied,
    getCameraDialogOpened,
    getCameraStreamCreated,
    getIsOtherAdminSharingCamera
]);
