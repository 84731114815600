import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import difference from 'lodash/difference';
import ProgressBar from 'erpcore/components/ProgressBar';
import {
    getPresentationCurrentProject,
    getPresentationSection
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { getProjectSectionsData } from 'erpcore/utils/navigationManager';

const PresentationCompletion = ({ sessionData }) => {
    const [visitedProjectSections, setVisitedProjectSections] = useState({});
    const presentationSection = useSelector(getPresentationSection);
    const presentationCurrentProject = useSelector(getPresentationCurrentProject);
    const presentationSectionRef = useRef(null);
    const presentationCurrentProjectRef = useRef(null);

    const getAvailableProjectSections = () => {
        const availableProjectSections = {};

        if (!sessionData?.projects?.length) return availableProjectSections;

        sessionData.projects.forEach(project => {
            const { iri } = {
                ...project
            };

            const projectSections = getProjectSectionsData(project);

            availableProjectSections[iri] = [];

            (Object.keys(projectSections) || []).forEach(sectionKey => {
                if (
                    !!projectSections?.[sectionKey]?.isNavItem &&
                    projectSections?.[sectionKey]?.isAvailable
                ) {
                    availableProjectSections[iri].push(sectionKey);
                }
            });

            /*
            availableProjectSections[iri] = [
                'project-home',
                'neighbourhood',
                'building-design',
                'floorplans',
                'home-tour',
                'features-and-options',
                'developer'
            ];

            if (documents?.length) {
                availableProjectSections[iri].push('documents');
            }

            if (purchaseCalculators?.length) {
                availableProjectSections[iri].push('purchase-calculator');
            }
            */
        });

        return availableProjectSections;
    };

    const getCompletionRate = () => {
        const availableProjectSections = getAvailableProjectSections();

        let totalSectionsCount = 0;

        let visitedSectionsCount = 0;

        let notVisitedSections = [];

        (Object.keys(availableProjectSections) || []).forEach(project => {
            if (availableProjectSections[project]?.length) {
                totalSectionsCount += availableProjectSections[project].length;
            }
            notVisitedSections = [
                ...notVisitedSections,
                ...difference(
                    availableProjectSections[project],
                    visitedProjectSections?.[project] || []
                )
            ];
        });

        visitedSectionsCount = totalSectionsCount - notVisitedSections.length;

        return Math.floor((visitedSectionsCount / totalSectionsCount) * 100);
    };

    useEffect(() => {
        if (
            presentationSection !== presentationSectionRef?.current ||
            presentationCurrentProject !== presentationCurrentProjectRef?.current
        ) {
            presentationSectionRef.current = presentationSection;
            presentationCurrentProjectRef.current = presentationCurrentProject;

            if (
                presentationCurrentProject &&
                presentationSection &&
                !visitedProjectSections?.[presentationCurrentProject]?.includes(presentationSection)
            ) {
                const newVisitedProjectSections = {
                    ...visitedProjectSections,
                    [presentationCurrentProject]: [
                        ...(visitedProjectSections?.[presentationCurrentProject] || []),
                        presentationSection
                    ]
                };

                setVisitedProjectSections(newVisitedProjectSections);
            }
        }
    }, [presentationSection, presentationCurrentProject]);

    return (
        <>
            <p className="presentation-header__label">Completion rate of presentation</p>
            <div className="presentation-header__completion">
                <p className="presentation-header__completion-value">{getCompletionRate()}%</p>
                <div className="presentation-header__completion-bar">
                    <ProgressBar percentage={getCompletionRate()} />
                </div>
            </div>
        </>
    );
};

PresentationCompletion.defaultProps = {
    sessionData: null
};

PresentationCompletion.propTypes = {
    sessionData: PropTypes.oneOfType([PropTypes.object])
};

export default PresentationCompletion;
