export const actions = {
    START_FETCHING_PRESENTATION_RTC_DATA: 'START_FETCHING_PRESENTATION_RTC_DATA',
    FETCHING_PRESENTATION_RTC_DATA_SUCCESSFUL: 'FETCHING_PRESENTATION_RTC_DATA_SUCCESSFUL',
    FETCHING_PRESENTATION_RTC_DATA_FAILED: 'FETCHING_PRESENTATION_RTC_DATA_FAILED',

    PRESENTATION_ADD_CONNECTION_USER: 'PRESENTATION_ADD_CONNECTION_USER',
    PRESENTATION_REMOVE_CONNECTION_USER: 'PRESENTATION_REMOVE_CONNECTION_USER',

    START_FETCHING_SINGLE_SESSION: 'START_FETCHING_SINGLE_SESSION',
    FETCH_SINGLE_SESSION_SUCCESSFUL: 'FETCH_SINGLE_SESSION_SUCCESSFUL',
    FETCH_SINGLE_SESSION_FAILED: 'FETCH_SINGLE_SESSION_FAILED',
    STORE_SINGLE_SESSION_DATA: 'STORE_SINGLE_SESSION_DATA',
    COPY_SINGLE_SESSION_DATA_FROM_HASH: 'COPY_SINGLE_SESSION_DATA_FROM_HASH',

    START_UPDATE_SINGLE_SESSION: 'START_UPDATE_SINGLE_SESSION',
    UPDATE_SINGLE_SESSION_SUCCESSFUL: 'UPDATE_SINGLE_SESSION_SUCCESSFUL',
    UPDATE_SINGLE_SESSION_FAILED: 'UPDATE_SINGLE_SESSION_FAILED',

    START_INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT:
        'START_INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT',
    INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT_SUCCESSFUL:
        'INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT_SUCCESSFUL',
    INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT_FAILED:
        'INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT_FAILED',

    START_UPDATE_SESSION_PROSPECT: 'START_UPDATE_SESSION_PROSPECT',
    UPDATE_SESSION_PROSPECT_SUCCESSFUL: 'UPDATE_SESSION_PROSPECT_SUCCESSFUL',
    UPDATE_SESSION_PROSPECT_FAILED: 'UPDATE_SESSION_PROSPECT_FAILED',

    START_FETCHING_PROSPECT_SESSIONS: 'START_FETCHING_PROSPECT_SESSIONS',
    FETCHING_PROSPECT_SESSIONS_SUCCESSFUL: 'FETCHING_PROSPECT_SESSIONS_SUCCESSFUL',
    FETCHING_PROSPECT_SESSIONS_FAILED: 'FETCHING_PROSPECT_SESSIONS_FAILED',

    SET_PROSPECT_LOGIN_UPDATED: 'SET_PROSPECT_LOGIN_UPDATED',

    SET_SESSION_TERMINATED: 'SET_SESSION_TERMINATED',
    SET_SESSION_TERMINATION_IN_PROGRESS: 'SET_SESSION_TERMINATION_IN_PROGRESS',

    START_FETCHING_QUESTIONS: 'START_FETCHING_QUESTIONS',
    FETCH_QUESTIONS_SUCCESSFUL: 'FETCH_QUESTIONS_SUCCESSFUL',
    START_FETCH_QUESTIONS_FAILED: 'START_FETCH_QUESTIONS_FAILED',

    SET_PRESENTATION_QUESTIONS_META: 'SET_PRESENTATION_QUESTIONS_META',

    START_FETCHING_PROSPECT_ANSWERS: 'START_FETCHING_PROSPECT_ANSWERS',
    FETCH_PROSPECT_ANSWERS_SUCCESSFUL: 'FETCH_PROSPECT_ANSWERS_SUCCESSFUL',
    FETCH_PROSPECT_ANSWERS_FAILED: 'FETCH_PROSPECT_ANSWERS_FAILED',

    START_SUBMIT_PROSPECT_ANSWERS: 'START_SUBMIT_PROSPECT_ANSWERS',
    SUBMIT_PROSPECT_ANSWERS_SUCCESSFUL: 'SUBMIT_PROSPECT_ANSWERS_SUCCESSFUL',
    SUBMIT_PROSPECT_ANSWERS_FAILED: 'SUBMIT_PROSPECT_ANSWERS_FAILED',

    SET_PRESENTATION_JOIN_STATE: 'SET_PRESENTATION_JOIN_STATE',
    SET_PRESENTATION_PHASE: 'SET_PRESENTATION_PHASE',
    SET_PRESENTATION_SECTION: 'SET_PRESENTATION_SECTION',
    SET_PRESENTATION_CURRENT_PROJECT: 'SET_PRESENTATION_CURRENT_PROJECT',
    SET_PRESENTATION_ROOM_IDENTIFIER: 'SET_PRESENTATION_ROOM_IDENTIFIER',
    SET_PRESENTATION_RTC_DATA: 'SET_PRESENTATION_RTC_DATA',
    SET_PRESENTATION_RTC_DATA_TEST: 'SET_PRESENTATION_RTC_DATA_TEST',
    EDIT_PRESENTATION_RTC_DATA: 'EDIT_PRESENTATION_RTC_DATA',
    SET_PRESENTATION_SIDEBAR_OPENED: 'SET_PRESENTATION_SIDEBAR_OPENED',
    SET_PRESENTATION_PROSPECT_SIDEBAR_OPENED: 'SET_PRESENTATION_PROSPECT_SIDEBAR_OPENED',
    SET_PRESENTATION_OTHERS_SCREEN_SIZE: 'SET_PRESENTATION_OTHERS_SCREEN_SIZE',
    SET_PRESENTATION_MY_SCREEN_SIZE: 'SET_PRESENTATION_MY_SCREEN_SIZE',
    SET_PRESENTATION_MY_SCREEN_SHARE_ACTIVE: 'SET_PRESENTATION_MY_SCREEN_SHARE_ACTIVE',

    START_FETCHING_GLOBAL_GUIDELINES: 'START_FETCHING_GLOBAL_GUIDELINES',
    FETCHING_GLOBAL_GUIDELINES_SUCCESSFUL: 'FETCHING_GLOBAL_GUIDELINES_SUCCESSFUL',
    FETCHING_GLOBAL_GUIDELINES_FAILED: 'FETCHING_GLOBAL_GUIDELINES_FAILED',

    START_CREATE_PROSPECT_NOTES: 'START_CREATE_PROSPECT_NOTES',
    CREATE_PROSPECT_NOTES_SUCCESSFUL: 'CREATE_PROSPECT_NOTES_SUCCESSFUL',
    CREATE_PROSPECT_NOTES_FAILED: 'CREATE_PROSPECT_NOTES_FAILED',

    START_CREATE_CHAT_MESSAGE: 'START_CREATE_CHAT_MESSAGE',
    CREATE_CHAT_MESSAGE_SUCCESSFUL: 'CREATE_CHAT_MESSAGE_SUCCESSFUL',
    CREATE_CHAT_MESSAGE_FAILED: 'CREATE_CHAT_MESSAGE_FAILED',
    START_FETCHING_CHAT_MESSAGES: 'START_FETCHING_CHAT_MESSAGES',
    FETCHING_CHAT_MESSAGES_SUCCESSFUL: 'FETCHING_CHAT_MESSAGES_SUCCESSFUL',
    FETCHING_CHAT_MESSAGES_FAILED: 'FETCHING_CHAT_MESSAGES_FAILED',
    STORE_CHAT_MESSAGE: 'STORE_CHAT_MESSAGE',
    SET_COUNT_UNREADED_CHAT_MESSAGES: 'SET_COUNT_UNREADED_CHAT_MESSAGES',
    INCREMENT_COUNT_UNREADED_CHAT_MESSAGES: 'INCREMENT_COUNT_UNREADED_CHAT_MESSAGES',
    SET_SHOW_CHAT: 'SET_SHOW_CHAT',

    STORE_RAISED_HAND: 'STORE_RAISED_HAND',
    CLEAR_RAISED_HAND: 'CLEAR_RAISED_HAND',
    SET_SHOW_RAISED_HANDS: 'SET_SHOW_RAISED_HANDS',

    SET_SHOW_PARTICIPANTS: 'SET_SHOW_PARTICIPANTS',
    SET_DEFAULT_CAMERA_STATE: 'SET_DEFAULT_CAMERA_STATE',
    SET_DEFAULT_MICROPHONE_STATE: 'SET_DEFAULT_MICROPHONE_STATE',
    SET_CAMERA_ACCESS_DENIED: 'SET_CAMERA_ACCESS_DENIED',
    SET_CAMERA_DIALOG_OPENED: 'SET_CAMERA_DIALOG_OPENED',
    SET_CAMERA_STREAM_CREATED: 'SET_CAMERA_STREAM_CREATED',

    SET_IS_OTHER_ADMIN_SHARING_CAMERA: 'SET_IS_OTHER_ADMIN_SHARING_CAMERA',

    START_CREATE_PARTICIPANT: 'START_CREATE_PARTICIPANT',
    CREATE_PARTICIPANT_SUCCESSFUL: 'CREATE_PARTICIPANT_SUCCESSFUL',
    CREATE_PARTICIPANT_FAILED: 'CREATE_PARTICIPANT_FAILED'
};

export const initialState = {
    connectionUsers: [],
    hasJoined: false,
    phase: 'intro', // intro/in-progress
    section: null,
    currentProject: null,
    sidebarOpened: true,
    prospectSidebarOpened: null,
    othersScreenSize: null,
    myScreenSize: null,
    isMyScreenShareActive: false,
    roomIdentifier: null,
    rtcData: null,
    rtcDataTest: null,
    fetchingRtcData: false,
    sessionData: null,
    singleSessionFetching: false,
    error: null,
    rtcError: null,
    sessionTerminated: false,
    sessionTerminationInProgress: false,
    prospectLoginUpdated: false,
    questionsMeta: {},
    questions: [],
    questionsFetching: false,
    prospectAnswers: [],
    prospectAnswersFetching: false,
    globalGuidelines: [],
    globalGuidelinesFetching: false,
    prospectSessionsFetching: false,
    prospectSessions: null,
    chat: [],
    showChat: false,
    chatFetching: false,
    countUnreadedChatMessages: 0,
    raisedHands: [],
    showRaisedHands: false,
    showParticipants: false,
    defaultCameraState: true,
    defaultMicrophoneState: true,
    cameraAccessDenied: false,
    cameraDialogOpened: false,
    cameraStreamCreated: false,
    isOtherAdminSharingCamera: false
};

export default (
    state = initialState,
    {
        data,
        type,
        sidebarOpened,
        othersScreenSize,
        myScreenSize,
        isMyScreenShareActive,
        prospectSidebarOpened,
        response,
        phase,
        section,
        currentProject,
        roomIdentifier,
        rtcData,
        iri,
        hash
    }
) => {
    switch (type) {
        case actions.START_FETCHING_PRESENTATION_RTC_DATA: {
            return {
                ...state,
                fetchingRtcData: true
            };
        }
        case actions.FETCHING_PRESENTATION_RTC_DATA_SUCCESSFUL: {
            return {
                ...state,
                fetchingRtcData: false,
                rtcError: null
            };
        }
        case actions.FETCHING_PRESENTATION_RTC_DATA_FAILED: {
            return {
                ...state,
                fetchingRtcData: false,
                rtcError: true
            };
        }
        case actions.START_FETCHING_GLOBAL_GUIDELINES: {
            return {
                ...state,
                globalGuidelinesFetching: true
            };
        }
        case actions.FETCHING_GLOBAL_GUIDELINES_SUCCESSFUL: {
            return {
                ...state,
                globalGuidelinesFetching: false,
                globalGuidelines: response?.data
            };
        }
        case actions.FETCHING_GLOBAL_GUIDELINES_FAILED: {
            return {
                ...state,
                globalGuidelinesFetching: false
            };
        }
        case actions.START_FETCHING_SINGLE_SESSION: {
            return {
                ...state,
                singleSessionFetching: true
            };
        }
        case actions.FETCH_SINGLE_SESSION_SUCCESSFUL: {
            return {
                ...state,
                singleSessionFetching: false
            };
        }
        case actions.FETCH_SINGLE_SESSION_FAILED: {
            return {
                ...state,
                singleSessionFetching: false
            };
        }
        case actions.START_FETCHING_PROSPECT_SESSIONS: {
            return {
                ...state,
                prospectSessionsFetching: true
            };
        }
        case actions.FETCHING_PROSPECT_SESSIONS_SUCCESSFUL: {
            return {
                ...state,
                prospectSessionsFetching: false,
                prospectSessions: {
                    ...state?.prospectSessions,
                    [iri]: response?.data
                }
            };
        }
        case actions.FETCHING_PROSPECT_SESSIONS_FAILED: {
            return {
                ...state,
                prospectSessionsFetching: false
            };
        }
        case actions.START_FETCHING_QUESTIONS: {
            return {
                ...state,
                questionsFetching: true
            };
        }
        case actions.FETCH_QUESTIONS_SUCCESSFUL: {
            return {
                ...state,
                questionsFetching: false,
                questions: response?.data
            };
        }
        case actions.START_FETCH_QUESTIONS_FAILED: {
            return {
                ...state,
                questionsFetching: false
            };
        }
        case actions.SET_PRESENTATION_QUESTIONS_META: {
            return {
                ...state,
                questionsMeta: data
            };
        }
        case actions.START_FETCHING_PROSPECT_ANSWERS: {
            return {
                ...state,
                prospectAnswersFetching: true
            };
        }
        case actions.FETCH_PROSPECT_ANSWERS_SUCCESSFUL: {
            return {
                ...state,
                prospectAnswersFetching: false,
                prospectAnswers: {
                    ...state?.prospectAnswers,
                    [iri]: response?.data
                }
            };
        }
        case actions.FETCH_PROSPECT_ANSWERS_FAILED: {
            return {
                ...state,
                prospectAnswersFetching: false
            };
        }
        case actions.START_SUBMIT_PROSPECT_ANSWERS: {
            return {
                ...state,
                prospectAnswersFetching: true
            };
        }
        case actions.SUBMIT_PROSPECT_ANSWERS_SUCCESSFUL: {
            return {
                ...state,
                prospectAnswersFetching: false
            };
        }
        case actions.SUBMIT_PROSPECT_ANSWERS_FAILED: {
            return {
                ...state,
                prospectAnswersFetching: false
            };
        }
        case actions.SET_PRESENTATION_SIDEBAR_OPENED: {
            return {
                ...state,
                sidebarOpened
            };
        }
        case actions.SET_PRESENTATION_PROSPECT_SIDEBAR_OPENED: {
            return {
                ...state,
                prospectSidebarOpened
            };
        }
        case actions.SET_PRESENTATION_OTHERS_SCREEN_SIZE: {
            return {
                ...state,
                othersScreenSize
            };
        }
        case actions.SET_PRESENTATION_MY_SCREEN_SIZE: {
            return {
                ...state,
                myScreenSize
            };
        }
        case actions.SET_PRESENTATION_MY_SCREEN_SHARE_ACTIVE: {
            return {
                ...state,
                isMyScreenShareActive
            };
        }
        case actions.SET_PRESENTATION_JOIN_STATE: {
            return {
                ...state,
                hasJoined: data
            };
        }
        case actions.SET_PRESENTATION_PHASE: {
            return {
                ...state,
                phase
            };
        }
        case actions.SET_PRESENTATION_SECTION: {
            return {
                ...state,
                section
            };
        }
        case actions.SET_PRESENTATION_CURRENT_PROJECT: {
            return {
                ...state,
                currentProject
            };
        }
        case actions.SET_PRESENTATION_ROOM_IDENTIFIER: {
            return {
                ...state,
                roomIdentifier
            };
        }
        case actions.SET_PRESENTATION_RTC_DATA: {
            return {
                ...state,
                rtcData
            };
        }
        case actions.SET_PRESENTATION_RTC_DATA_TEST: {
            return {
                ...state,
                rtcDataTest: rtcData
            };
        }
        case actions.EDIT_PRESENTATION_RTC_DATA: {
            return {
                ...state,
                rtcData: {
                    ...state.rtcData,
                    ...rtcData
                }
            };
        }
        case actions.SET_PROSPECT_LOGIN_UPDATED: {
            return {
                ...state,
                prospectLoginUpdated: data
            };
        }
        case actions.SET_SESSION_TERMINATED: {
            return {
                ...state,
                sessionTerminated: data
            };
        }
        case actions.SET_SESSION_TERMINATION_IN_PROGRESS: {
            return {
                ...state,
                sessionTerminationInProgress: data
            };
        }
        case actions.PRESENTATION_ADD_CONNECTION_USER: {
            return Object.assign({}, state, {
                connectionUsers: [...state?.connectionUsers, data]
            });
        }
        case actions.PRESENTATION_REMOVE_CONNECTION_USER: {
            return Object.assign({}, state, {
                connectionUsers: [
                    ...(state?.connectionUsers || []).filter(user => {
                        return user.connectionId !== data;
                    })
                ]
            });
        }
        case actions.STORE_SINGLE_SESSION_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                sessionData: {
                    ...state?.sessionData,
                    [iri || hash]: response.data
                }
            });
        }
        case actions.START_FETCHING_CHAT_MESSAGES: {
            return {
                ...state,
                chatFetching: true
            };
        }
        case actions.FETCHING_CHAT_MESSAGES_SUCCESSFUL: {
            const chatHistory = [];
            response.data.forEach(message => {
                const { created_at: createdAt, created_by: createdBy, content } = { ...message };
                chatHistory.push({ author: createdBy, created_at: createdAt, content });
            });
            return {
                ...state,
                chat: chatHistory,
                chatFetching: false
            };
        }
        case actions.STORE_CHAT_MESSAGE: {
            const newChatData = Array.from(state.chat);
            newChatData.push(response);
            return {
                ...state,
                chat: newChatData
            };
        }
        case actions.SET_COUNT_UNREADED_CHAT_MESSAGES: {
            return {
                ...state,
                countUnreadedChatMessages: response
            };
        }
        case actions.INCREMENT_COUNT_UNREADED_CHAT_MESSAGES: {
            const incrementNumber = !state.showChat ? state.countUnreadedChatMessages + 1 : 0;
            return {
                ...state,
                countUnreadedChatMessages: incrementNumber
            };
        }
        case actions.SET_SHOW_CHAT: {
            return {
                ...state,
                showChat: response,
                countUnreadedChatMessages: response ? 0 : state.countUnreadedChatMessages
            };
        }
        case actions.STORE_RAISED_HAND: {
            const newRaisedHandData = Array.from(state.raisedHands);
            if (newRaisedHandData.filter(item => item.owner === response.owner).length) {
                //  newRaisedHandData = newRaisedHandData.filter(item => item.owner !== response.owner);
            } else {
                newRaisedHandData.push(response);
            }
            return {
                ...state,
                raisedHands: newRaisedHandData
            };
        }
        case actions.CLEAR_RAISED_HAND: {
            const newRaisedHandData = Array.from(state.raisedHands);
            const filteredRaisedHands = newRaisedHandData.filter(
                item => item.owner !== response.owner
            );
            return {
                ...state,
                raisedHands: filteredRaisedHands
            };
        }
        case actions.SET_SHOW_RAISED_HANDS: {
            return {
                ...state,
                showRaisedHands: response
            };
        }
        case actions.SET_SHOW_PARTICIPANTS: {
            return {
                ...state,
                showParticipants: response
            };
        }
        case actions.SET_DEFAULT_CAMERA_STATE: {
            return {
                ...state,
                defaultCameraState: data
            };
        }
        case actions.SET_DEFAULT_MICROPHONE_STATE: {
            return {
                ...state,
                defaultMicrophoneState: data
            };
        }
        case actions.SET_CAMERA_ACCESS_DENIED: {
            return {
                ...state,
                cameraAccessDenied: data
            };
        }
        case actions.SET_CAMERA_DIALOG_OPENED: {
            return {
                ...state,
                cameraDialogOpened: data
            };
        }
        case actions.SET_CAMERA_STREAM_CREATED: {
            return {
                ...state,
                cameraStreamCreated: data
            };
        }
        case actions.COPY_SINGLE_SESSION_DATA_FROM_HASH: {
            return Object.assign({}, state, {
                sessionData: {
                    ...state?.sessionData,
                    [iri]: state?.sessionData?.[hash]
                }
            });
        }
        case actions.SET_IS_OTHER_ADMIN_SHARING_CAMERA: {
            return {
                ...state,
                isOtherAdminSharingCamera: data
            };
        }
        default:
            return state;
    }
};
