import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Carousel } from 'react-responsive-carousel';
import isEqual from 'lodash/isEqual';
import Magnifier from 'react-magnifier';

import ElementLoader from 'erpcore/components/ElementLoader';
import Svg from 'erpcore/components/Svg';

import { actions as galleryActions } from 'erpcore/components/Gallery/Gallery.reducer';
import { getGalleryData, getGalleryFetching } from 'erpcore/components/Gallery/Gallery.selectors';
import { getImageSrc } from 'erpcore/utils/utils';
import { getIdFromIri } from 'erpcore/utils/dto';

import './Gallery.scss';

const Gallery = ({
    name,
    imagesIris,
    hasThumbs,
    hasMagnifier,
    hasFullFullscreen,
    fullscreen,
    className,
    hasHeaderContent,
    hasCaption
}) => {
    const dispatch = useDispatch();
    const galleryData = useSelector(state => getGalleryData(state, name)) || {};
    const galleryFetching = useSelector(state => getGalleryFetching(state, name));
    const { data: images } = { ...galleryData };
    const [showThumbs, setShowThumbs] = useState(false);
    const [activeZoom, setActiveZoom] = useState(false);
    const [activeActualFullscreen, setActiveActualFullscreen] = useState(false);
    const [selected, setSelected] = useState(0);
    const previousImagesIris = useRef(null);
    const galleryRef = useRef(null);

    const fetchImages = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: galleryActions.START_FETCHING_IMAGES,
                params: { 'filters[id][in]': imagesIris, pagination: false },
                name
            });
        }).catch(error => ({ error }));
    };

    const handleGallerySizes = () => {
        return false;

        /*
        const height = galleryRef?.current?.clientHeight;

        if (height) {
            // const targetContainer = galleryRef.current.querySelectorAll('.gallery__magnifier');
            const targetContainers = galleryRef.current.querySelectorAll('.gallery__slide');

            if (targetContainers) {
                [...targetContainers].forEach(item => {
                    item.style.maxHeight = `${height}px`;
                });
            }

            const targetImages = galleryRef.current.querySelectorAll(
                '.magnifier-image, .gallery__slide-img'
            );

            if (targetImages) {
                [...targetImages].forEach(item => {
                    item.style.maxHeight = `${height}px`;
                });
            }
        }
        */
    };

    useEffect(() => {
        if (
            // if iris for this gallery have changed (this is also true on mount)
            !isEqual(imagesIris, previousImagesIris.current) &&
            // and if iris don't match redux state for this gallery
            !isEqual(
                images?.map(item => `${item.id}`),
                imagesIris?.map(item => `${getIdFromIri(item)}`)
            )
        ) {
            previousImagesIris.current = imagesIris;
            setSelected(0);
            setShowThumbs(false);
            if (imagesIris?.length > 0) {
                fetchImages().then(() => {
                    handleGallerySizes();
                });
            }
        }
    }, [imagesIris]);

    useEffect(() => {
        handleGallerySizes();
    }, [activeZoom, activeActualFullscreen]);

    useEffect(() => {
        window.addEventListener('resize', handleGallerySizes);

        return () => {
            window.removeEventListener('resize', handleGallerySizes);
        };
    }, []);

    const renderMainSlideImage = (xLargeImageURL, largeImageURL) => {
        if (!hasMagnifier || !activeZoom) {
            if (fullscreen) {
                return (
                    <img
                        alt=""
                        className="gallery__slide-img"
                        srcSet={`${largeImageURL} 1600w,
                                 ${xLargeImageURL} 2560w`}
                        sizes="(max-width: 1600px) 1600px,
                               2560px"
                        src={largeImageURL}
                    />
                );
            }

            return <img alt="" className="gallery__slide-img" src={largeImageURL} />;
        }

        return null;
    };

    const renderSlides = () => {
        return images.map(image => {
            const largeImageURL = getImageSrc(image, 'large');
            const xLargeImageURL = getImageSrc(image, 'x-large');
            const smallImageURL = getImageSrc(image, 'small');
            return (
                <div className="gallery__slide-single" key={smallImageURL}>
                    <img alt="" className="gallery__thumb-placeholder" src={smallImageURL} />
                    <div className="gallery__slide">
                        {!!hasMagnifier && !!activeZoom && (
                            <Magnifier
                                src={largeImageURL}
                                zoomImgSrc={xLargeImageURL}
                                zoomFactor={0.65}
                                mgWidth={300}
                                mgHeight={300}
                                mgShape="square"
                            />
                        )}
                        {renderMainSlideImage(xLargeImageURL, largeImageURL)}
                    </div>
                </div>
            );
        });
    };

    const renderHeaderSlides = () => {
        return images.map(image => {
            if (image?.meta?.headerContent) {
                return (
                    <div
                        className="gallery__header-content-slide"
                        key={image.iri}
                        dangerouslySetInnerHTML={{
                            __html: image.meta.headerContent
                        }}
                    />
                );
            }

            return (
                <div
                    className="gallery__header-content-slide gallery__header-content-slide--empty"
                    key={image.iri}
                />
            );
        });
    };

    const renderCaptionSlides = () => {
        return images.map(image => {
            return (
                <div className="gallery__captions-slide" key={image.iri}>
                    {image?.meta?.caption && hasCaption ? image?.meta?.caption : null}
                </div>
            );
        });
    };

    if ((!images || images?.length < 1) && !galleryFetching) {
        return null;
    }

    const imagesCount = images?.length || 0;

    return (
        <div
            ref={galleryRef}
            className={`gallery${className ? ` ${className}` : ''}${
                !hasThumbs ? ' gallery--no-thumbs' : ''
            }${fullscreen ? ' gallery--fullscreen' : ''}${
                activeActualFullscreen ? ' gallery--actual-fullscreen' : ''
            }${showThumbs ? ' gallery--thumbs-active' : ''}`}
        >
            {galleryFetching ? (
                <ElementLoader overlay />
            ) : (
                <>
                    {!!hasThumbs && imagesCount > 1 && (
                        <button
                            type="button"
                            onClick={() => setShowThumbs(!showThumbs)}
                            className="gallery__thumbnail-toggle"
                        >
                            <Svg icon="gallery" />
                        </button>
                    )}
                    {!!hasMagnifier && (
                        <button
                            type="button"
                            onClick={() => setActiveZoom(!activeZoom)}
                            className={`gallery__zoom-toggle ${
                                activeZoom ? 'gallery__zoom-toggle--active' : ''
                            }`}
                        >
                            <Svg icon="zoom" />
                        </button>
                    )}
                    {!!hasFullFullscreen && (
                        <button
                            type="button"
                            onClick={() => setActiveActualFullscreen(!activeActualFullscreen)}
                            className={`gallery__fullscreen-toggle ${
                                activeActualFullscreen ? 'gallery__fullscreen-toggle--active' : ''
                            }`}
                        >
                            <Svg icon={activeActualFullscreen ? 'fullscreenOff' : 'fullscreenOn'} />
                        </button>
                    )}
                    {!!hasHeaderContent && !fullscreen && !activeActualFullscreen && (
                        <Carousel
                            className="gallery__header-content"
                            showIndicators={false}
                            showStatus={false}
                            showArrows={false}
                            showThumbs={false}
                            selectedItem={selected}
                            dynamicHeight
                        >
                            {renderHeaderSlides()}
                        </Carousel>
                    )}
                    <Carousel
                        className="gallery__main"
                        showIndicators={false}
                        showStatus={false}
                        selectedItem={selected}
                        onClickThumb={e => {
                            setSelected(e);
                        }}
                        onChange={slideIndex => {
                            setSelected(slideIndex);
                        }}
                        useKeyboardArrows
                        emulateTouch
                    >
                        {renderSlides()}
                    </Carousel>
                    {!!hasCaption && !fullscreen && !activeActualFullscreen && (
                        <Carousel
                            className="gallery__captions"
                            showIndicators={false}
                            showStatus={false}
                            showArrows={false}
                            showThumbs={false}
                            selectedItem={selected}
                        >
                            {renderCaptionSlides()}
                        </Carousel>
                    )}
                </>
            )}
        </div>
    );
};

Gallery.defaultProps = {
    name: null,
    imagesIris: [],
    hasThumbs: true,
    hasMagnifier: true,
    hasFullFullscreen: true,
    fullscreen: false,
    className: null,
    hasHeaderContent: true,
    hasCaption: true
};

Gallery.propTypes = {
    name: PropTypes.string,
    imagesIris: PropTypes.oneOfType([PropTypes.array]),
    hasThumbs: PropTypes.bool,
    hasMagnifier: PropTypes.bool,
    hasFullFullscreen: PropTypes.bool,
    fullscreen: PropTypes.bool,
    className: PropTypes.string,
    hasHeaderContent: PropTypes.bool,
    hasCaption: PropTypes.bool
};

export default Gallery;
