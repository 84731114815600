import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import './DateTime.scss';
import Input from '../Input';

/**
 *
 * @param input
 * @param meta
 * @param fieldProps
 * @param fieldAttr
 * @param field
 * @param outputNoTimeZone {boolean} if true; value is saved as formatted string,
 *                                   otherwise value is saved as Date string with timezone
 * @return {*}
 * @constructor
 */
const DateTime = ({ input, meta, fieldProps, fieldAttr, field, outputNoTimeZone }) => {
    //  Preparing specific date format
    let dateFormat = 'M/d/yyyy'; // react-datepicker formatting
    let dateFormatMoment = outputNoTimeZone ? 'M/D/YYYY' : ''; // moment formatting
    if (fieldProps?.showTimeSelect) {
        dateFormat = 'M/d/yyyy   h:mm aa'; // react-datepicker formatting
        dateFormatMoment = outputNoTimeZone ? 'M/D/YYYY   h:mm a' : ''; // moment formatting

        if (fieldProps?.showTimeSelectOnly) {
            dateFormat = 'h:mm aa'; // react-datepicker formatting
            dateFormatMoment = outputNoTimeZone ? 'h:mm a' : ''; // moment formatting
        }
    }

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="input--datepicker"
        >
            <DatePicker
                className="input__field"
                {...fieldAttr}
                {...fieldProps}
                onBlur={() => input.onBlur()}
                dateFormat={dateFormat}
                selected={input.value ? new Date(input.value) : ''}
                // if outputNoTimeZone === true -> value is saved as formatted string, otherwise as Date string with timezone
                onChange={value => input.onChange(moment(value).format(dateFormatMoment))}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </Input>
    );
};

DateTime.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    outputNoTimeZone: true
};

DateTime.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    outputNoTimeZone: PropTypes.bool
};

export default DateTime;
