export const actions = {
    START_FETCHING_PROJECTS: 'START_FETCHING_PROJECTS',
    FETCHING_SUCCESSFUL_PROJECTS: 'FETCHING_SUCCESSFUL_PROJECTS',
    FETCHING_FAILED_PROJECTS: 'FETCHING_FAILED_PROJECTS',

    START_CREATE_PROJECT: 'START_CREATE_PROJECT',
    CREATE_PROJECT_SUCCESFUL: 'CREATE_PROJECT_SUCCESFUL',
    CREATE_PROJECT_FAILED: 'CREATE_PROJECT_FAILED',
    START_UPDATE_PROJECT: 'START_UPDATE_PROJECT',
    UPDATE_PROJECT_SUCCESSFUL: 'UPDATE_PROJECT_SUCCESSFUL',
    UPDATE_PROJECT_FAILED: 'UPDATE_PROJECT_FAILED',
    START_FETCHING_PROJECT: 'START_FETCHING_PROJECT',
    FETCHING_PROJECT_SUCCESSFUL: 'FETCHING_PROJECT_SUCCESSFUL',
    FETCHING_PROJECT_FAILED: 'FETCHING_PROJECT_FAILED',
    STORE_PROJECT_DATA: 'STORE_PROJECT_DATA',
    START_DELETE_PROJECT: 'START_DELETE_PROJECT',
    DELETE_PROJECT_SUCCESSFUL: 'DELETE_PROJECT_SUCCESSFUL',
    DELETE_PROJECT_FAILED: 'DELETE_PROJECT_FAILED',

    START_FETCHING_DOCUMENTS: 'START_FETCHING_DOCUMENTS',
    FETCHING_DOCUMENTS_SUCCESSFUL: 'FETCHING_DOCUMENTS_SUCCESSFUL',
    FETCHING_DOCUMENTS_FAILED: 'FETCHING_DOCUMENTS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_PROJECTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_DOCUMENTS: {
            return {
                ...state,
                singleFetching: true,
                [iri]: {
                    ...state?.[iri],
                    fetching: true
                }
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_PROJECT: {
            return {
                ...state,
                singleFetching: true,
                [iri]: {
                    ...state?.[iri],
                    fetching: true
                }
            };
        }
        case actions.FETCHING_DOCUMENTS_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                [iri]: {
                    ...state?.[iri],
                    fetching: false,
                    documentsData: response?.data
                }
            };
        }
        case actions.FETCHING_PROJECT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                [iri]: {
                    ...state?.[iri],
                    fetching: false
                }
            };
        }
        case actions.FETCHING_PROJECT_FAILED: {
            return {
                ...state,
                singleFetching: false,
                [iri]: {
                    ...state?.[iri],
                    fetching: false
                }
            };
        }
        case actions.FETCHING_DOCUMENTS_FAILED: {
            return {
                ...state,
                singleFetching: false,
                [iri]: {
                    ...state?.[iri],
                    fetching: false
                }
            };
        }
        case actions.STORE_PROJECT_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        default:
            return state;
    }
};
