const getImagesIrisFromObjects = arrayOfObjects => {
    if (!arrayOfObjects || arrayOfObjects?.length < 1) {
        return null;
    }

    const imagesIris = [];

    arrayOfObjects.map(object => {
        return object.iri ? imagesIris.push(object.iri) : null;
    });

    return imagesIris;
};

export default getImagesIrisFromObjects;
