import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import Svg from 'erpcore/components/Svg';
import { getPresentationConnectionUsers } from 'erpcore/screens/Presentation/Presentation.selectors';
import './ProspectCounter.scss';

const ProspectCounter = ({ type, getMyConnection }) => {
    const presentationConnectionUsers = useSelector(getPresentationConnectionUsers);
    const [waitingProspectCount, setWaitingProspectCount] = useState(0);
    const [
        waitingProspectCountConnectedAfterMe,
        setWaitingProspectCountConnectedAfterMe
    ] = useState(0);
    const waitingProspectCountRef = useRef(0);
    const waitingProspectCountConnectedAfterMeRef = useRef(0);
    const [plusUsersCount, setPlusUsersCount] = useState(0);
    const [minusUsersCount, setMinusUsersCount] = useState(0);
    const [isPlusNotificationActive, setIsPlusNotificationActive] = useState(false);
    const [isMinusNotificationActive, setIsMinusNotificationActive] = useState(false);

    const getWaitingProspectCountExcludingMe = (connectionUsers = []) =>
        connectionUsers.reduce((accumulator, currentValue) => {
            if (
                getMyConnection()?.connectionId !== currentValue?.connectionId &&
                ['prospect', 'guest', 'spectator'].includes(currentValue?.connectionUserType)
            ) {
                return accumulator + 1;
            }
            return accumulator;
        }, 0);

    const getWaitingProspectCountConnectedAfterMe = (connectionUsers = []) =>
        connectionUsers.reduce((accumulator, currentValue) => {
            const myConnection = getMyConnection();
            if (
                myConnection?.connectionId !== currentValue?.connectionId &&
                ['prospect', 'guest', 'spectator'].includes(currentValue?.connectionUserType) &&
                myConnection.creationTime < currentValue.creationTime
            ) {
                return accumulator + 1;
            }
            return accumulator;
        }, 0);

    const setPlusNotificationLifetime = debounce(() => {
        setIsPlusNotificationActive(false);
        setPlusUsersCount(0);
    }, 4000);

    const setMinusNotificationLifetime = debounce(() => {
        setIsMinusNotificationActive(false);
        setMinusUsersCount(0);
    }, 4000);

    useEffect(() => {
        setWaitingProspectCount(getWaitingProspectCountExcludingMe(presentationConnectionUsers));
        setWaitingProspectCountConnectedAfterMe(
            getWaitingProspectCountConnectedAfterMe(presentationConnectionUsers)
        );
    }, [presentationConnectionUsers]);

    useEffect(() => {
        if (waitingProspectCount !== waitingProspectCountRef.current) {
            const countDiff = waitingProspectCount - waitingProspectCountRef.current;
            waitingProspectCountRef.current = waitingProspectCount;
            if (countDiff < 0) {
                setMinusUsersCount(minusUsersCount + countDiff);
                setIsMinusNotificationActive(true);
                setMinusNotificationLifetime();
            }
        }
    }, [waitingProspectCount, minusUsersCount]);

    useEffect(() => {
        if (
            waitingProspectCountConnectedAfterMe !== waitingProspectCountConnectedAfterMeRef.current
        ) {
            const countDiff =
                waitingProspectCountConnectedAfterMe -
                waitingProspectCountConnectedAfterMeRef.current;
            waitingProspectCountConnectedAfterMeRef.current = waitingProspectCountConnectedAfterMe;
            if (countDiff > 0) {
                setPlusUsersCount(plusUsersCount + countDiff);
                setIsPlusNotificationActive(true);
                setPlusNotificationLifetime();
            }
        }
    }, [waitingProspectCountConnectedAfterMe, plusUsersCount]);

    const finalWaitingProspectCount =
        type === 'admin' ? waitingProspectCount : waitingProspectCount + 1;

    return (
        <div className={`presentation-prospect-counter presentation-prospect-counter--${type}`}>
            <p className="presentation-prospect-counter__title">Participants</p>
            <div className="presentation-prospect-counter__content">
                <p className="presentation-prospect-counter__count">{finalWaitingProspectCount}</p>
                <div className="presentation-prospect-counter__notifications">
                    <div
                        className="presentation-prospect-counter__notifications-item presentation-prospect-counter__notifications-item--connected"
                        data-test={isPlusNotificationActive ? '1' : '0'}
                    >
                        <Svg
                            className="presentation-prospect-counter__notifications-item-icon"
                            icon="userGroup"
                        />
                        <p className="presentation-prospect-counter__notifications-item-text">
                            <strong>{plusUsersCount}</strong> {waitingProspectCount > 1 && 'more'}{' '}
                            user
                            {plusUsersCount > 1 && 's'} just joined
                        </p>
                    </div>
                    <div
                        className="presentation-prospect-counter__notifications-item presentation-prospect-counter__notifications-item--disconnected"
                        data-test={isMinusNotificationActive ? '1' : '0'}
                    >
                        <Svg
                            className="presentation-prospect-counter__notifications-item-icon"
                            icon="userGroup"
                        />
                        <p className="presentation-prospect-counter__notifications-item-text">
                            <strong>{Math.abs(minusUsersCount)}</strong>{' '}
                            {Math.abs(minusUsersCount) > 1 && 'more'} user
                            {Math.abs(minusUsersCount) > 1 && 's'} just left
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

ProspectCounter.defaultProps = {
    type: 'client',
    getMyConnection: () => {}
};

ProspectCounter.propTypes = {
    type: PropTypes.oneOf(['client', 'admin']),
    getMyConnection: PropTypes.func
};

export default ProspectCounter;
