import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getIdFromIri } from 'erpcore/utils/dto';

import SignOut from 'erpcore/screens/SignOut';
import SessionProjectsPresentationWrapper from 'erpcore/screens/Projects/components/SessionProjectsPresentationWrapper';
import ProspectPresentationLogin from 'erpcore/screens/Projects/screens/ProspectPresentationLogin';
import SessionProjects from 'erpcore/screens/Projects/screens/SessionProjects';
import ProjectWrapper from 'erpcore/screens/Projects/components/ProjectWrapper';
import Projects from 'erpcore/screens/Projects/screens/Projects';
import ProjectHome from 'erpcore/screens/Projects/screens/ProjectHome';
import ProjectDemoTourOverview from 'erpcore/screens/Projects/screens/ProjectDemoTourOverview';
import ProjectBuildingDesign from 'erpcore/screens/Projects/screens/ProjectBuildingDesign';
import ProjectFeatureAndOptions from 'erpcore/screens/Projects/screens/ProjectFeatureAndOptions';
import ProjectFloorplans from 'erpcore/screens/Projects/screens/ProjectFloorplans';
import ProjectNeighbourhood from 'erpcore/screens/Projects/screens/ProjectNeighbourhood';
import ProjectHomeTour from 'erpcore/screens/Projects/screens/ProjectHomeTour';
import ProjectDeveloper from 'erpcore/screens/Projects/screens/ProjectDeveloper';
import ProjectPurchaseCalculator from 'erpcore/screens/Projects/screens/ProjectPurchaseCalculator';
import ProjectDocuments from 'erpcore/screens/Projects/screens/ProjectDocuments';

import PresentationAdmin from 'erpcore/screens/Presentation/screens/PresentationAdmin';
import PresentationClient from 'erpcore/screens/Presentation/screens/PresentationClient';

// Render the public router when the user is not signed in
const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;

export const getProjectRoutePaths = (route = '') => {
    return [
        `${routePrefix}/project/:project${route}`,
        `${routePrefix}/session/presentation/:session/project/:project${route}`,
        `${routePrefix}/presentation/:prospectHash/project/:project${route}`
    ];
};

/**
 *
 * @param project {string|number} optional, Project ID or IRI
 * @param session {string|number} optional, Session ID or IRI
 * @param prospectHash {string|number} optional, prospectHash
 * @param route {string} optional, will be added at the end of string
 * @return {string|null} Project URL
 */
export const getEntityUrl = ({
    project = null,
    session = null,
    prospectHash = null,
    route = ''
}) => {
    if (session && project) {
        return `${routePrefix}/session/presentation/${getIdFromIri(session)}/project/${getIdFromIri(
            project
        )}${route}`;
    }

    if (prospectHash && project) {
        return `${routePrefix}/presentation/${prospectHash}/project/${getIdFromIri(
            project
        )}${route}`;
    }

    if (session) {
        return `${routePrefix}/session/presentation/${getIdFromIri(session)}${route}`;
    }

    if (prospectHash) {
        return `${routePrefix}/presentation/${prospectHash}`;
    }

    if (project) {
        return `${routePrefix}/project/${getIdFromIri(project)}${route}`;
    }

    if (route) {
        return `${routePrefix}${route}`;
    }

    return `${routePrefix}/`;
};

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const dispatch = useDispatch();
    const allowedToClearNotifications = useRef(false);
    const { pathname, search } = location;
    const isSignedIn = useSelector(getSignedIn);

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                clearNotificationManagerPageNotification();
            }
            allowedToClearNotifications.current = true;
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamicly udating body className
            let cssClass = 'page-homepage';
            if (pathname && pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = 'page-404';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    return (
        <Switch>
            <Route exact path={`${routePrefix}/`} component={Projects} />
            <Route
                exact
                path={`${routePrefix}/session/prospect/:prospectHash`}
                component={PresentationClient}
            />
            <Route
                exact
                path={`${routePrefix}/session/guest/:guestHash`}
                component={PresentationClient}
            />
            <Route
                exact
                path={`${routePrefix}/session/spectator/:spectatorHash`}
                component={PresentationClient}
            />
            {!isSignedIn && (
                <Route
                    exact={false}
                    path={`${routePrefix}/presentation/:prospectHash`}
                    component={ProspectPresentationLogin}
                />
            )}
            {isSignedIn && (
                <>
                    <Route path={`${routePrefix}/sign-out`} exact component={SignOut} />
                    <Route
                        exact
                        path={`${routePrefix}/session/admin/:sessionId`}
                        component={PresentationAdmin}
                    />
                    <Route
                        exact={false}
                        path={[
                            ...getProjectRoutePaths(),
                            `${routePrefix}/session/presentation/:session`,
                            `${routePrefix}/presentation/:prospectHash`
                        ]}
                        render={routeProps => (
                            <SessionProjectsPresentationWrapper {...routeProps}>
                                <Route
                                    exact
                                    path={[
                                        `${routePrefix}/session/presentation/:session`,
                                        `${routePrefix}/presentation/:prospectHash`
                                    ]}
                                    component={SessionProjects}
                                />
                                <ProjectWrapper>
                                    <>
                                        <Route
                                            exact
                                            path={getProjectRoutePaths()}
                                            component={ProjectHome}
                                        />
                                        <Route
                                            exact
                                            path={getProjectRoutePaths('/demo-tour-overview')}
                                            component={ProjectDemoTourOverview}
                                        />
                                        <Route
                                            path={getProjectRoutePaths('/building-design')}
                                            component={ProjectBuildingDesign}
                                        />
                                        <Route
                                            path={getProjectRoutePaths('/neighbourhood')}
                                            component={ProjectNeighbourhood}
                                        />
                                        <Route
                                            path={getProjectRoutePaths('/floorplans')}
                                            component={ProjectFloorplans}
                                        />
                                        <Route
                                            path={getProjectRoutePaths('/home-tour')}
                                            component={ProjectHomeTour}
                                        />
                                        <Route
                                            path={getProjectRoutePaths('/features-and-options')}
                                            component={ProjectFeatureAndOptions}
                                        />
                                        <Route
                                            exact
                                            path={getProjectRoutePaths('/developer')}
                                            component={ProjectDeveloper}
                                        />
                                        <Route
                                            exact
                                            path={getProjectRoutePaths('/documents')}
                                            component={ProjectDocuments}
                                        />
                                        <Route
                                            path={getProjectRoutePaths('/purchase-calculator')}
                                            component={ProjectPurchaseCalculator}
                                        />
                                    </>
                                </ProjectWrapper>
                            </SessionProjectsPresentationWrapper>
                        )}
                    />
                </>
            )}
            <Redirect
                to={{
                    pathname: `${routePrefix}/`,
                    state: { from: location }
                }}
            />
        </Switch>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
