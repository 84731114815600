import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import isEmpty from 'lodash/isEmpty';
import { getSectionFromPath } from 'erpcore/utils/navigationManager';
import {
    getPresentationProspectLoginUpdated,
    getPresentationRtcData,
    getPresentationSessionDataByHash
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { preloadScript, createSession } from 'opentok-react';
import PresentationForbidden from 'erpcore/components/Presentation/components/PresentationForbidden';
import PresentationExpired from 'erpcore/components/Presentation/components/PresentationExpired';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as organizationActions } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.reducer';
import { getIdFromIri } from 'erpcore/utils/dto';

/**
 *
 * All project screens are wrapped inside this Component. It is done within RouterManager.
 */
function SessionProjectsPresentationWrapper({ children }) {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const location = useLocation();
    const pathnameRef = useRef(null);
    const [viewIncrementSentToErp, setViewIncrementSentToErp] = useState(false);
    const [sessionGeneralError, setSessionGeneralError] = useState(undefined);
    const [sessionExists, setSessionExists] = useState(undefined);
    const [sessionExpired, setSessionExpired] = useState(undefined);
    const [sessionExpiredData, setSessionExpiredData] = useState(undefined);
    const [sessionHashExists, setSessionHashExists] = useState(undefined);
    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);
    const [sessionHelper, setSessionHelper] = useState(null);
    const prospectHash = match?.params?.prospectHash;
    const sessionDatabyHash =
        useSelector(state => getPresentationSessionDataByHash(state, prospectHash)) || {};
    const sessionIri = match?.params?.session ? `/api/sessions/${match.params.session}` : null;
    const projectIri = match?.params?.project ? `/api/projects/${match.params.project}` : null;
    const projectIriRef = useRef(null);
    const rtcData = useSelector(getPresentationRtcData);
    const [currentPathname, setCurrentPathname] = useState(null);
    const currentPathnameRef = useRef(null);
    const prospectLoginUpdated = useSelector(state => getPresentationProspectLoginUpdated(state));
    const meData = useSelector(getMeData) || {};
    const userType = meData?._type;

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    const getOtherAdminConnections = () => {
        const output = [];

        const test = [];

        if (sessionHelper?.session) {
            const myConnection = sessionHelper?.session?.connection;

            sessionHelper.session.connections.forEach(connection => {
                test.push(connection);
                const connectionCustomData = getConnectionCustomData(connection);
                if (
                    connectionCustomData?.connectionUserType === 'admin' &&
                    connection.connectionId !== myConnection.connectionId
                ) {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const handleSessionErrorState = error => {
        const { code, detail } = { ...error };
        if (code === 'session.notFoundByHash') {
            setSessionExists(false);
        } else if (code === 'session.recapExpired') {
            setSessionExists(true);
            setSessionExpiredData(detail);
            setSessionExpired(true);
        } else {
            setSessionGeneralError(true);
        }
    };

    const emitSectionSignal = pathname => {
        if (sessionHelper?.session) {
            const otherAdminConnections = getOtherAdminConnections();
            if (otherAdminConnections?.length) {
                const section = getSectionFromPath(pathname);
                otherAdminConnections.forEach(connection => {
                    const data = {
                        section
                    };
                    sessionHelper.session.signal({
                        to: connection,
                        data: JSON.stringify(data),
                        type: `presentationSection`
                    });
                });
            }
        }
    };

    const emitCurrentProjectSignal = iri => {
        if (sessionHelper?.session) {
            const otherAdminConnections = getOtherAdminConnections();
            if (otherAdminConnections?.length) {
                otherAdminConnections.forEach(connection => {
                    const data = {
                        currentProject: iri
                    };
                    sessionHelper.session.signal({
                        to: connection,
                        data: JSON.stringify(data),
                        type: `presentationCurrentProject`
                    });
                });
            }
        }
    };

    const fetchSessionDataByHash = hash => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                hash
            });
        });
    };

    const fetchSessionData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                iri
            });
        });
    };

    const incrementProspectPostPresentationViewCount = (iri, token) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_INCREMENT_PROSPECT_POST_PRESENTATION_VIEW_COUNT,
                iri,
                token
            });
        }).catch(error => ({ error }));
    };

    const fetchRtcData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_PRESENTATION_RTC_DATA,
                sessionId: getIdFromIri(iri),
                connectionData: {
                    connectionUserType: 'shareScreen'
                }
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (location?.pathname !== pathnameRef.current) {
            setCurrentPathname(location?.pathname);
        }
        pathnameRef.current = location?.pathname;
    }, [location]);

    useEffect(() => {
        if (sessionExists && sessionHelper?.session && !sessionEventsCreated) {
            // someone connected (including myself)
            sessionHelper.session.on('connectionCreated', () => {
                emitSectionSignal(currentPathnameRef?.current);
                emitCurrentProjectSignal(projectIriRef?.current);
            });

            // I have connected
            sessionHelper.session.on('sessionConnected', () => {
                emitSectionSignal(currentPathnameRef?.current);
                emitCurrentProjectSignal(projectIriRef?.current);
            });

            // someone disconnected (including myself)
            sessionHelper.session.on('connectionDestroyed', () => {
                //
            });

            setSessionEventsCreated(true);
        }
    }, [sessionHelper]);

    useEffect(() => {
        currentPathnameRef.current = currentPathname;
        if (sessionExists && sessionHelper?.session) {
            emitSectionSignal(currentPathname);
        }
    }, [currentPathname]);

    useEffect(() => {
        if (projectIriRef.current !== projectIri) {
            projectIriRef.current = projectIri;
            if (sessionExists && sessionHelper?.session) {
                emitCurrentProjectSignal(projectIri);
            }
        }
    }, [projectIri]);

    useEffect(() => {
        if (sessionExists && !isEmpty(rtcData) && !sessionHelper) {
            setSessionHelper(
                createSession({
                    ...rtcData
                })
            );
        }
    }, [rtcData]);

    useEffect(() => {
        if (sessionExists) {
            dispatch({
                type: presentationActions.SET_PRESENTATION_ROOM_IDENTIFIER,
                roomIdentifier: sessionIri
            });
            if (isEmpty(rtcData)) {
                fetchRtcData(sessionIri);
            }
        }
    }, [sessionExists]);

    // if session provided in route
    useEffect(() => {
        if (sessionIri) {
            fetchSessionData(sessionIri)
                .then(() => setSessionExists(true))
                .catch(error => handleSessionErrorState(error));
        }
    }, [sessionIri]);

    useEffect(() => {
        if (sessionDatabyHash?.iri) {
            fetchSessionData(sessionDatabyHash?.iri);
            if (!viewIncrementSentToErp) {
                setViewIncrementSentToErp(true);
                incrementProspectPostPresentationViewCount(
                    sessionDatabyHash?.iri,
                    sessionDatabyHash?.token
                );
            }
        }
    }, [sessionDatabyHash]);

    // if hash provided in route
    useEffect(() => {
        if (prospectHash) {
            fetchSessionDataByHash(prospectHash)
                .then(() => setSessionHashExists())
                .catch(error => handleSessionErrorState(error));
        }
    }, [prospectHash]);

    const fetchOrganizationSettings = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCHING_ORGANIZATION_SETTINGS
            });
        }).catch(error => ({ error }));
    };

    const signOut = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_OUT
            });
        }).catch(error => ({ error }));
    };

    // if user is prospect and hasn't automatically logged in on page load, then sign out
    // other route will automatically sign him in, and will set 'prospectLoginUpdated' to true
    useEffect(() => {
        if (userType === 'prospect' && !prospectLoginUpdated) {
            signOut().then(() => {
                fetchOrganizationSettings();
            });
        }
    }, [prospectLoginUpdated]);

    //  if session doesnt even exists
    if (sessionExists === false || sessionHashExists === false) {
        return <PresentationForbidden title="This session is not available!" />;
    }

    //  If session existed but expired
    if (sessionExpired === true) {
        return (
            <PresentationExpired title="This Session Has Expired" sessionData={sessionExpiredData}>
                <p>
                    Thank you for taking the time to revisit your interactive Home Tour. <br />
                    Your personalized post tour link has expired.
                </p>
                <p>
                    If you would like to book an additional Interactive Home Tour or have questions
                    about the community of interest to you, please contact our Sales Representative
                    listed below.
                </p>
                <p>We look foward to connecting with you again soon.</p>
            </PresentationExpired>
        );
    }

    // other session error response
    if (sessionGeneralError === true) {
        return <PresentationForbidden title="An unknown error occurred!" />;
    }

    return <>{children}</>;
}
SessionProjectsPresentationWrapper.defaultProps = {
    children: null
};
SessionProjectsPresentationWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.node])
};

export default preloadScript(SessionProjectsPresentationWrapper);
