import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, reset } from 'redux-form';
import Form, { TextEditor } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { getPresentationCurrentProject } from 'erpcore/screens/Presentation/Presentation.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import './PresentationNotes.scss';

const PresentationNotes = ({
    sessionHelper,
    submitting,
    sessionProspect,
    handleSubmit,
    invalid,
    pristine,
    sessionData
}) => {
    const currentProject = useSelector(getPresentationCurrentProject);
    const [sessionEventsCreated, setSessionEventsCreated] = useState(false);
    const dispatch = useDispatch();

    const fetchSessionData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                iri: sessionData?.iri
            });
        }).catch(error => ({ error }));
    };

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    const getOtherAdminConnections = () => {
        const output = [];

        if (sessionHelper?.session) {
            const myConnection = sessionHelper?.session?.connection;

            sessionHelper.session.connections.forEach(connection => {
                const connectionCustomData = getConnectionCustomData(connection);
                if (
                    connectionCustomData?.connectionUserType === 'admin' &&
                    connection.connectionId !== myConnection.connectionId
                ) {
                    output.push(connection);
                }
            });
        }

        return output;
    };

    const emitNotesSubmitted = notes => {
        if (sessionHelper?.session) {
            const targetConnections = getOtherAdminConnections();
            if (targetConnections?.length) {
                targetConnections.forEach(connection => {
                    const data = {
                        notes
                    };
                    sessionHelper.session.signal({
                        to: connection,
                        data: JSON.stringify(data),
                        type: `notesSubmitted`
                    });
                });
            }
        }
    };

    const onSubmit = formData => {
        const notes = [];
        if (sessionProspect?.notes?.length) {
            notes.push(...sessionProspect?.notes);
        }

        notes.push({
            ...formData,
            project: currentProject,
            date: new Date()
        });

        return new Promise((resolve, reject) => {
            dispatch({
                type: presentationActions.START_CREATE_PROSPECT_NOTES,
                notes,
                promise: { resolve, reject },
                sessionIri: sessionData?.iri,
                sessionProspectIri: sessionProspect?.iri
            });
        })
            .then(() => {
                emitNotesSubmitted(notes);
                fetchSessionData();
                dispatch(reset('PresentationNotesForm'));
            })
            .catch(error => {
                return error;
            });
    };

    useEffect(() => {
        if (sessionHelper?.session && !sessionEventsCreated) {
            // eslint-disable-next-line no-unused-vars
            sessionHelper.session.on('signal:notesSubmitted', () => {
                fetchSessionData();
            });
            setSessionEventsCreated(true);
        }
    }, [sessionHelper]);

    return (
        <div className="presentation-notes">
            <h4 className="presentation-administration__section-content-title">
                <strong> Add Prospect Notes</strong>
            </h4>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Field
                    name="comment"
                    editorProps={{
                        toolbar: {
                            options: ['inline', 'list']
                        }
                    }}
                    fieldAttr={{
                        id: 'comment'
                    }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />

                <Button
                    loading={submitting}
                    size="small"
                    submit
                    disabled={pristine || invalid}
                    label="ADD NOTES"
                />
            </Form>
        </div>
    );
};

PresentationNotes.defaultProps = {
    sessionHelper: null,
    sessionProspect: null,
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitting: false,
    sessionData: {}
};

PresentationNotes.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object]),
    sessionProspect: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    sessionData: PropTypes.oneOfType([PropTypes.object])
};

export default reduxForm({
    form: 'PresentationNotesForm'
})(PresentationNotes);
