import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import Embed from 'erpcore/components/Embed';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Gallery from 'erpcore/components/Gallery';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';
import { getProjectRoutePaths, getEntityUrl } from 'erpcore/utils/RouterManager';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';

export const isAvailable = projectData => {
    const {
        neighbourhood_gallery: neighbourhoodGallery,
        neighbourhood_google_maps: neighbourhoodGoogleMaps,
        neighbourhood_google_street: neighbourhoodGoogleStreet
    } = { ...projectData };

    return (
        (!!neighbourhoodGallery && !!neighbourhoodGallery?.length) ||
        !!neighbourhoodGoogleMaps ||
        !!neighbourhoodGoogleStreet
    );
};

const ProjectNeighbourhood = () => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    const pageTitle = 'Neighbourhood';

    const {
        neighbourhood_google_maps: neighbourhoodGoogleMaps,
        neighbourhood_google_street: neighbourhoodGoogleStreet
    } = { ...projectData };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="neighbourhood" />
            <MobileQuickNav section="neighbourhood" />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <PageContent.Columns>
                    <PageContent.SideMenu>
                        <SubNavigation>
                            {!!projectData.neighbourhood_gallery && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/neighbourhood')}
                                    label="Neighbourhood Gallery"
                                />
                            )}
                            {!!neighbourhoodGoogleMaps && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/neighbourhood/google-maps')}
                                    label="Google Maps"
                                />
                            )}
                            {!!neighbourhoodGoogleStreet && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/neighbourhood/google-street-view')}
                                    label="Google Street View"
                                />
                            )}
                        </SubNavigation>
                    </PageContent.SideMenu>
                    <PageContent.Column>
                        <Switch>
                            {!!projectData.neighbourhood_gallery && (
                                <Route exact path={getProjectRoutePaths('/neighbourhood')}>
                                    <Gallery
                                        name="neighbourhoodGallery"
                                        imagesIris={getImagesIrisFromObjects(
                                            projectData.neighbourhood_gallery
                                        )}
                                    />
                                </Route>
                            )}
                            {!!neighbourhoodGoogleMaps && (
                                <Route
                                    exact
                                    path={getProjectRoutePaths('/neighbourhood/google-maps')}
                                >
                                    <Embed code={neighbourhoodGoogleMaps} />
                                </Route>
                            )}
                            {!!neighbourhoodGoogleStreet && (
                                <Route
                                    exact
                                    path={getProjectRoutePaths('/neighbourhood/google-street-view')}
                                >
                                    <Embed code={neighbourhoodGoogleStreet} />
                                </Route>
                            )}
                        </Switch>
                    </PageContent.Column>
                </PageContent.Columns>
            </PageContent>
        </LayoutManager>
    );
};

ProjectNeighbourhood.defaultProps = {};

ProjectNeighbourhood.propTypes = {};

export default ProjectNeighbourhood;
