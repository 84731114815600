import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import FloatingNotifications from 'erpcore/components/Layout/FloatingNotifications';
import Footer from 'erpcore/components/Layout/Footer';
import './Main.scss';

const Main = ({ children, className, displayFooter }) => {
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const footerText = organizationSettings?.settings?.footerDisclaimer;
    return (
        <>
            <main className={`main ${className || ''}`}>
                <PageNotifications />
                {children}
                {displayFooter && (
                    <Footer>
                        <p>{footerText}</p>
                    </Footer>
                )}
            </main>
            <div className="invisible-fix-animation" />
            <FloatingNotifications />
        </>
    );
};

Main.defaultProps = {
    children: null,
    className: '',
    displayFooter: true
};

Main.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    displayFooter: PropTypes.bool
};

export default Main;
