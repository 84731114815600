import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { getPresentationSessionDataByHash } from 'erpcore/screens/Presentation/Presentation.selectors';

import 'erpcore/screens/Projects/screens/Projects/Projects.scss';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import presentationAdminLayout from 'erpcore/utils/LayoutManager/presentationAdminLayout';
import PresentationForbidden from 'erpcore/components/Presentation/components/PresentationForbidden';
import PresentationExpired from 'erpcore/components/Presentation/components/PresentationExpired';
import PresentationLoading from 'erpcore/components/Presentation/components/PresentationLoading';

const ProspectPresentationLogin = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const prospectHash = match?.params?.prospectHash;
    const sessionDatabyHash =
        useSelector(state => getPresentationSessionDataByHash(state, prospectHash)) || {};
    const [sessionGeneralError, setSessionGeneralError] = useState(undefined);
    const [hasAccess, setHasAccess] = useState(undefined);
    const [sessionExists, setSessionExists] = useState(undefined);
    const [sessionExpired, setSessionExpired] = useState(undefined);
    const [sessionExpiredData, setSessionExpiredData] = useState(undefined);

    const title = 'Prospect Presentation';

    const handleSessionErrorState = error => {
        const { code, detail } = { ...error };
        if (code === 'session.notFoundByHash') {
            setSessionExists(false);
        } else if (code === 'session.recapExpired') {
            setSessionExists(true);
            setSessionExpiredData(detail);
            setSessionExpired(true);
        } else {
            setSessionGeneralError(true);
        }
        setHasAccess(true);
    };

    const signIn = token => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_IN_WITH_TOKEN,
                token,
                isProspect: true
            })
        );
    };

    const fetchSessionDataByHash = hash => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                hash
            });
        });
    };

    useEffect(() => {
        if (sessionDatabyHash?.iri) {
            if (sessionDatabyHash?.token) {
                signIn(sessionDatabyHash?.token).catch(() => {
                    setHasAccess(false);
                });
            } else {
                setHasAccess(false);
            }
        }
    }, [sessionDatabyHash]);

    useEffect(() => {
        if (prospectHash && !sessionDatabyHash?.iri) {
            fetchSessionDataByHash(prospectHash).catch(error => handleSessionErrorState(error));
        }
    }, [prospectHash]);

    //  If session existed but expired
    if (sessionExpired === true) {
        return (
            <PresentationExpired title="This Session Has Expired" sessionData={sessionExpiredData}>
                <p>
                    Thank you for taking the time to revisit your interactive Home Tour. <br />
                    Your personalized post tour link has expired.
                </p>
                <p>
                    If you would like to book an additional Interactive Home Tour or have questions
                    about the community of interest to you, please contact our Sales Representative
                    listed below.
                </p>
                <p>We look forward to connecting with you again soon.</p>
            </PresentationExpired>
        );
    }

    //  if session doesnt even exists
    if (sessionExists === false) {
        return <PresentationForbidden title="This session is not available!" />;
    }

    // other session error response
    if (sessionGeneralError === true) {
        return <PresentationForbidden title="An unknown error occurred!" />;
    }

    if (hasAccess === false) {
        return (
            <LayoutManager
                className="main--presentation main--presentation-client"
                slot="main"
                layoutType="override"
                forwardProps={{ displayFooter: false }}
                layout={presentationAdminLayout}
            >
                <HeadMeta title={title} />
                <PresentationForbidden message="You don't have permission to participate in this session!" />
            </LayoutManager>
        );
    }

    return <PresentationLoading />;
};

export default ProspectPresentationLogin;
