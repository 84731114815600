import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, matchPath, NavLink, Link } from 'react-router-dom';

import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';
import { getEntityUrl } from 'erpcore/utils/RouterManager';
import { getPresentationSessionDataByHash } from 'erpcore/screens/Presentation/Presentation.selectors';
import './MobileMenu.scss';
import Image from 'erpcore/components/Image';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getProjectSectionsData } from 'erpcore/utils/navigationManager';

const MobileMenu = () => {
    const match = useRouteMatch();
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const meData = useSelector(getMeData) || {};
    const userType = meData?._type;
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const sessionDatabyHash =
        useSelector(state =>
            getPresentationSessionDataByHash(state, match?.params?.prospectHash)
        ) || {};

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    const projectSections = getProjectSectionsData(
        projectData,
        match?.params?.prospectHash,
        sessionDatabyHash
    );

    const getMenuItemClass = (pathname, additionalCssClass) => {
        let menuItemClassName = `mobile-menu__item ${additionalCssClass || ''}`;

        const matched = matchPath(pathname, match);
        if (
            // standard check
            matched?.isExact ||
            // or if pathname param equals project home and demo-tour-overview is current page
            (pathname === projectUrl() && match.url === projectUrl('/demo-tour-overview'))
        ) {
            menuItemClassName = `mobile-menu__item ${additionalCssClass || ''} active`;
        }

        return menuItemClassName;
    };

    const renderProject = () => {
        if (!projectData?.iri) return null;

        const {
            name,
            development_project_logo: developmentProjectLogo,
            street,
            city,
            state,
            full_address: fullAddress
        } = {
            ...projectData
        };
        const { name: cityName } = { ...city };
        const { name: stateName } = { ...state };

        let location = fullAddress;
        if (fullAddress && cityName) {
            location = (
                <>
                    {street}
                    <br />
                    {cityName}
                    {stateName ? `, ${stateName}` : ''}
                </>
            );
        }

        return (
            <div className="mobile-menu__project">
                <div className="mobile-menu__project-logo">
                    {!!developmentProjectLogo?.iri && (
                        <Image
                            iri={developmentProjectLogo?.iri}
                            version="small"
                            className="mobile-menu__project-logo-image"
                        />
                    )}
                </div>
                {!!name && <h3 className="mobile-menu__project-title">{name}</h3>}
                {!!location && <p className="mobile-menu__project-location">{location}</p>}
            </div>
        );
    };

    return (
        <div className="mobile-menu">
            {renderProject()}
            <ul className="mobile-menu__main">
                {!!Object.keys(projectSections)?.length &&
                    Object.keys(projectSections).map(sectionKey => {
                        const section = projectSections[sectionKey];
                        if (section?.isNavItem && section?.isAvailable) {
                            return (
                                <li
                                    key={section.route}
                                    className={getMenuItemClass(projectUrl(section.route))}
                                >
                                    <NavLink
                                        to={projectUrl(section.route)}
                                        className="mobile-menu__item-link"
                                        exact
                                    >
                                        {section.title}
                                    </NavLink>
                                </li>
                            );
                        }
                        return null;
                    })}
            </ul>
            <ul className="mobile-menu__aside">
                <li className="mobile-menu__item">
                    <NavLink
                        to={getEntityUrl({
                            session: match?.params?.session,
                            prospectHash: match?.params?.prospectHash
                        })}
                        className="mobile-menu__item-link"
                        exact
                    >
                        Back To Projects
                    </NavLink>
                </li>
                {userType !== 'prospect' && (
                    <li className="mobile-menu__item">
                        <NavLink
                            to={getEntityUrl({
                                route: '/sign-out'
                            })}
                            className="mobile-menu__item-link"
                            exact
                        >
                            Sign Out
                        </NavLink>
                    </li>
                )}
            </ul>
            <Link
                to={getEntityUrl({
                    session: match?.params?.session,
                    prospectHash: match?.params?.prospectHash
                })}
                className="mobile-menu__logo"
            >
                {organizationSettings?.settings?.logoInverted && (
                    <Image iri={organizationSettings?.settings?.logoInverted} />
                )}
            </Link>
        </div>
    );
};

MobileMenu.defaultProps = {};

MobileMenu.propTypes = {};

export default MobileMenu;
