import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import slugify from 'react-slugify';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import PdfViewer from 'erpcore/components/PdfViewer';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Gallery from 'erpcore/components/Gallery';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import FeaturesListSlider from 'erpcore/components/FeaturesListSlider';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import getImagesIrisFromObjects from 'erpcore/components/Gallery/Gallery.utils';
import { getProjectRoutePaths, getEntityUrl } from 'erpcore/utils/RouterManager';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';

export const isAvailable = projectData => {
    const { site_plan_images: sitePlanImages, architectural_plans: architecturalPlans } = {
        ...projectData
    };
    return (
        !!projectData.exterior_rendering_images ||
        !!projectData.amenity_programming_gallery ||
        !!projectData.amenity_programming_features ||
        (!!sitePlanImages && !!sitePlanImages.length) ||
        (!!architecturalPlans && !!architecturalPlans.length)
    );
};

const ProjectBuildingDesign = () => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    const pageTitle = 'Building Design';

    const { site_plan_images: sitePlanImages, architectural_plans: architecturalPlans } = {
        ...projectData
    };

    const allowedToShowArchitecturalPlans = !match?.params?.prospectHash;

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="building-design" />
            <MobileQuickNav section="building-design" />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <PageContent.Columns>
                    <PageContent.SideMenu>
                        <SubNavigation>
                            {!!projectData.exterior_rendering_images && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/building-design')}
                                    label="Exteriors"
                                />
                            )}
                            {!!projectData.amenity_programming_gallery && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/building-design/amenity-programming-gallery')}
                                    label="Amenity Gallery"
                                />
                            )}
                            {!!projectData.amenity_programming_features && (
                                <Menu.Item
                                    exact
                                    to={projectUrl('/building-design/amenity-programming-features')}
                                    label="Amenity Features"
                                />
                            )}
                            {!!sitePlanImages && (
                                <Menu.Submenu label="Site Plan">
                                    {sitePlanImages?.map(item => (
                                        <Menu.SubmenuItem
                                            to={projectUrl(
                                                `/building-design/site-plan/${slugify(item.title)}`
                                            )}
                                            label={item.title}
                                            key={slugify(item.title)}
                                        />
                                    ))}
                                </Menu.Submenu>
                            )}
                            {!!allowedToShowArchitecturalPlans && !!architecturalPlans?.length && (
                                <Menu.Submenu label="Architectural Plan">
                                    {architecturalPlans?.map((item, i) => (
                                        <Menu.SubmenuItem
                                            to={projectUrl(
                                                `/building-design/architectural-plan/${slugify(
                                                    item.title || i + 1
                                                )}`
                                            )}
                                            label={item.title || (i + 1).toString()}
                                            key={item.iri}
                                        />
                                    ))}
                                </Menu.Submenu>
                            )}
                        </SubNavigation>
                    </PageContent.SideMenu>

                    <Switch>
                        <Route exact path={getProjectRoutePaths('/building-design')}>
                            <PageContent.Column>
                                <Gallery
                                    name="exteriorRendering"
                                    imagesIris={getImagesIrisFromObjects(
                                        projectData.exterior_rendering_images
                                    )}
                                />
                            </PageContent.Column>
                        </Route>
                        {sitePlanImages?.map((item, i) => {
                            return (
                                <Route
                                    exact
                                    path={getProjectRoutePaths(
                                        `/building-design/site-plan/${slugify(item.title)}`
                                    )}
                                    key={slugify(item.title)}
                                >
                                    <PageContent.Column>
                                        <Gallery
                                            name={`sitePlanGallery-${i}`}
                                            imagesIris={getImagesIrisFromObjects(item.gallery)}
                                        />
                                    </PageContent.Column>
                                </Route>
                            );
                        })}
                        <Route
                            exact
                            path={getProjectRoutePaths(
                                '/building-design/amenity-programming-features'
                            )}
                        >
                            <PageContent.Column>
                                <FeaturesListSlider
                                    featuresList={projectData.amenity_programming_features}
                                />
                            </PageContent.Column>
                        </Route>
                        <Route
                            exact
                            path={getProjectRoutePaths(
                                '/building-design/amenity-programming-gallery'
                            )}
                        >
                            <PageContent.Column>
                                <Gallery
                                    name="amenityProgrammingGallery"
                                    imagesIris={getImagesIrisFromObjects(
                                        projectData.amenity_programming_gallery
                                    )}
                                />
                            </PageContent.Column>
                        </Route>
                        {!!allowedToShowArchitecturalPlans &&
                            architecturalPlans?.map((item, i) => (
                                <Route
                                    exact
                                    path={getProjectRoutePaths(
                                        `/building-design/architectural-plan/${slugify(
                                            item.title || i + 1
                                        )}`
                                    )}
                                    key={item?.iri}
                                >
                                    <PageContent.Column className="page-content__column--no-padding">
                                        <PdfViewer
                                            name={`architecturalPlans-${slugify(
                                                item.title || i + 1
                                            )}`}
                                            fileIri={item?.iri}
                                        />
                                    </PageContent.Column>
                                </Route>
                            ))}
                    </Switch>
                </PageContent.Columns>
            </PageContent>
        </LayoutManager>
    );
};

ProjectBuildingDesign.defaultProps = {};

ProjectBuildingDesign.propTypes = {};

export default ProjectBuildingDesign;
