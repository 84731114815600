import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import NetworkTest from 'opentok-network-test-js';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageLoader from 'erpcore/components/PageLoader';
import Presentation from 'erpcore/components/Presentation';
import PresentationLoading from 'erpcore/components/Presentation/components/PresentationLoading';
import PresentationForbidden from 'erpcore/components/Presentation/components/PresentationForbidden';

import presentationAdminLayout from 'erpcore/utils/LayoutManager/presentationAdminLayout';
import { actions as presentationActions } from 'erpcore/screens/Presentation/Presentation.reducer';
import {
    getPresentationRtcData,
    getPresentationFetchingRtcData,
    getPresentationRtcError,
    getPresentationSessionData,
    getPresentationFetchingSessionData,
    getPresentationJoinedState,
    getPresentationRtcDataTest
} from 'erpcore/screens/Presentation/Presentation.selectors';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import PresentationJoin from 'erpcore/components/Presentation/components/PresentationJoin';
import { preloadScript } from 'opentok-react';
import { getIdFromIri } from 'erpcore/utils/dto';

const PresentationAdmin = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const sessionId = match?.params?.sessionId;
    const sessionIri = `/api/sessions/${sessionId}`;
    const [sessionTestConnection, setSessionTestConnection] = useState(null);
    const rtcData = useSelector(getPresentationRtcData);
    const rtcDataTest = useSelector(getPresentationRtcDataTest);
    const fetchingRtcData = useSelector(getPresentationFetchingRtcData);
    const rtcError = useSelector(getPresentationRtcError);
    const sessionData = useSelector(state => getPresentationSessionData(state, sessionIri)) || {};
    const sessionFetching = useSelector(getPresentationFetchingSessionData);
    const meData = useSelector(getMeData);
    const [sessionExists, setSessionExists] = useState(undefined);
    const [isPresentationAdmin, setIsPresentationAdmin] = useState(undefined);
    const hasJoined = useSelector(getPresentationJoinedState);
    const hasJoinedRef = useRef(false);

    // const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    // const fetching = useSelector(getProjectFetching);

    // const routePrefix = enviromentVariables.REACT_APP_ROUTE_PREFIX;
    // const projectName = match?.params?.project;

    const pageTitle = 'Presentation Admin';

    const fetchSessionData = id => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_SINGLE_SESSION,
                iri: `/api/sessions/${id}`
            });
        });
    };

    const setSessionData = async id => {
        fetchSessionData(id)
            .then(() => {
                setSessionExists(true);
            })
            .catch(() => {
                setSessionExists(false);
            });
    };

    const fetchRtcData = (iri, isTest = false) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: presentationActions.START_FETCHING_PRESENTATION_RTC_DATA,
                sessionId: getIdFromIri(iri),
                isTest,
                openTokRole: 'moderator',
                connectionData: {
                    connectionUserType: 'admin',
                    user: meData.iri
                }
            });
        });
    };

    useEffect(() => {
        if (
            !hasJoined &&
            sessionData?.iri &&
            meData.iri &&
            isPresentationAdmin === undefined &&
            isEmpty(rtcDataTest)
        ) {
            fetchRtcData(sessionData?.iri, true);
        } else if (
            !!hasJoined &&
            sessionData?.iri &&
            meData.iri &&
            isPresentationAdmin === undefined
        ) {
            const { sales_agent: salesAgent } = { ...sessionData };
            const { iri: salesAgentIri } = { ...salesAgent };
            // if current user is session's sales agent (is presentation admin)
            if (meData.iri === salesAgentIri) {
                setIsPresentationAdmin(true);
                dispatch({
                    type: presentationActions.SET_PRESENTATION_ROOM_IDENTIFIER,
                    roomIdentifier: sessionData?.iri
                });
                if (isEmpty(rtcData)) {
                    fetchRtcData(sessionData?.iri);
                }
            } else {
                setIsPresentationAdmin(false);
            }
        }
    }, [sessionData, meData, hasJoined]);

    useEffect(() => {
        if (hasJoined && !hasJoinedRef.current && sessionTestConnection) {
            sessionTestConnection.stop();
        }
        if (hasJoined !== hasJoinedRef.current) {
            hasJoinedRef.current = hasJoined;
        }
    }, [hasJoined]);

    useEffect(() => {
        if (!isEmpty(rtcDataTest) && !sessionTestConnection) {
            setSessionTestConnection(
                new NetworkTest(window.OT, {
                    ...rtcDataTest
                })
            );
        }
    }, [rtcDataTest]);

    useEffect(() => {
        if (sessionId && !sessionData?.iri) {
            setSessionData(sessionId);
        }
    }, [sessionId]);

    if (!fetchingRtcData && !sessionFetching && rtcError === true) {
        return (
            <PresentationForbidden title="WebRTC server error!">
                <Button label="Retry" onClick={() => fetchRtcData(sessionData?.iri)} />
            </PresentationForbidden>
        );
    }

    if (sessionExists === false) {
        return <PresentationForbidden title="Error!" message="Session could not be found." />;
    }

    if (isPresentationAdmin === false) {
        return (
            <PresentationForbidden title="You are not assigned as a sales agent for this session!" />
        );
    }

    if (sessionExists === undefined && isPresentationAdmin === undefined) {
        return <PresentationLoading />;
    }

    if (!hasJoined) {
        return (
            <PresentationJoin
                showCameraPublisher
                sessionTestConnection={sessionTestConnection}
                subType="admin"
                sessionData={sessionData}
            />
        );
    }

    return (
        <LayoutManager
            className="main--presentation main--presentation-admin"
            slot="main"
            layoutType="override"
            forwardProps={{ displayFooter: false }}
            layout={presentationAdminLayout}
        >
            <HeadMeta title={pageTitle} />
            <Presentation rtcData={rtcData} type="admin" sessionData={sessionData} />
            {!!fetchingRtcData && <PageLoader />}
        </LayoutManager>
    );
};

PresentationAdmin.defaultProps = {};

PresentationAdmin.propTypes = {};

export default preloadScript(PresentationAdmin);
