import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouteMatch, NavLink } from 'react-router-dom';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Svg from 'erpcore/components/Svg';

import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';
import './ProjectPageHeader.scss';
import { getNextSection, getProjectSectionsData } from 'erpcore/utils/navigationManager';
import { getEntityUrl } from 'erpcore/utils/RouterManager';

const ProjectPageHeader = ({ auto, section, title, link, linkLabel }) => {
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};

    // Manual
    let derivedTitle = title;
    let derivedLink = link;
    let derivedLinkLabel = linkLabel;

    // Auto
    if (auto) {
        const projectSectionedData = getProjectSectionsData(projectData);
        derivedTitle = projectSectionedData?.[section]?.title;

        const nextSection = getNextSection(projectData, section);
        derivedLink =
            nextSection?.route || nextSection?.route === ''
                ? getEntityUrl({
                      project: match?.params?.project,
                      session: match?.params?.session,
                      prospectHash: match?.params?.prospectHash,
                      route: nextSection.route
                  })
                : null;
        derivedLinkLabel = nextSection?.title;
    }

    const renderLink = () => {
        if (derivedLink && derivedLinkLabel) {
            return (
                <NavLink to={derivedLink} className="project-page-header__link">
                    <span className="project-page-header__link-label">{derivedLinkLabel}</span>
                    <Svg icon="arrow" />
                </NavLink>
            );
        }

        return null;
    };

    return (
        <PageHeader title={derivedTitle} link={renderLink()}>
            <div className="project-page-header">
                <p className="project-page-header__name">{projectData.name}</p>
                <p className="project-page-header__address">{projectData.street}</p>
            </div>
        </PageHeader>
    );
};

ProjectPageHeader.defaultProps = {
    auto: false,
    section: null,
    title: null,
    link: null,
    linkLabel: null
};

ProjectPageHeader.propTypes = {
    auto: PropTypes.bool,
    section: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    linkLabel: PropTypes.string
};

export default ProjectPageHeader;
