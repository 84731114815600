import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { OTPublisher, OTSubscriber } from 'opentok-react';
import Button from 'erpcore/components/Button';
import './PresentationScreenPublisher.scss';
import Svg from 'erpcore/components/Svg';
import {
    getPresentationOthersScreenSize,
    getPresentationProspectSidebarOpened
} from 'erpcore/screens/Presentation/Presentation.selectors';

const PresentationScreenPublisher = ({
    sessionHelper,
    isScreenshareActive,
    getMyConnection,
    publishScreenshare,
    getOtherNonAdminConnections,
    connectionType
}) => {
    const otScreenshare = useRef();
    const userScreenRef = useRef();
    const othersScreenSize = useSelector(getPresentationOthersScreenSize);
    const prospectSidebarOpened = useSelector(getPresentationProspectSidebarOpened);
    const [captureStreamMedia, setCaptureStreamMedia] = useState(null);
    const [videoTrack, setVideoTrack] = useState(false);
    const [audioTrack, setAudioTrack] = useState(false);
    const [deviceSupportsScreenShare, setDeviceSupportsScreenShare] = useState(true);
    const [otherAdminScreenStreams, setOtherAdminScreenStreams] = useState([]);
    const requestScreenShareRef = useRef(false);
    const isScreenshareActiveRef = useRef(null);

    const getConnectionCustomData = connection => {
        return connection?.data ? JSON.parse(connection?.data) : null;
    };

    const getOtherAdminScreenStreams = () => {
        const streams = sessionHelper?.streams;
        if (streams?.length) {
            const otherStreams = streams.filter(stream => {
                const streamConnectionCustomData = getConnectionCustomData(stream?.connection);
                return (
                    stream?.videoType !== 'camera' &&
                    stream?.name === 'screenshare' &&
                    streamConnectionCustomData?.connectionUserType === 'admin'
                );
            });
            return otherStreams;
        }
        return null;
    };

    const runSetOtherAdminScreenStreams = () => {
        setOtherAdminScreenStreams(getOtherAdminScreenStreams());
    };

    // eslint-disable-next-line no-unused-vars
    const getScreenshare = () => {
        return otScreenshare?.current?.getPublisher();
    };

    const setMonitorSize = (size = []) => {
        if (size?.length !== 2 || !userScreenRef?.current) {
            return null;
        }

        userScreenRef.current.style.padding = `0 0 ${(size[1] / size[0]) * 100}%`;

        return null;
    };

    useEffect(() => {
        if (othersScreenSize?.videoSpaceWidth && othersScreenSize?.videoSpaceHeight)
            setMonitorSize([
                othersScreenSize?.videoSpaceWidth || 1280,
                othersScreenSize?.videoSpaceHeight || 800
            ]);
    }, [othersScreenSize]);

    useEffect(() => {
        if (window.OT) {
            window.OT.checkScreenSharingCapability(response => {
                setDeviceSupportsScreenShare(!!response?.supported);
            });
        }

        const interval = setInterval(() => {
            runSetOtherAdminScreenStreams();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isScreenshareActive !== isScreenshareActiveRef?.current) {
            isScreenshareActiveRef.current = isScreenshareActive;
        }
    }, [isScreenshareActive]);

    const isOtherAdminSharing = !!otherAdminScreenStreams?.length;

    const requestScreenShare = !!isScreenshareActive && !isOtherAdminSharing;

    async function startCapture() {
        let captureStream = null;

        try {
            captureStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: {
                    echoCancellation: false,
                    googAudioMirroring: true
                }
            });
            const videoTracks = captureStream?.getVideoTracks();
            const audioTracks = captureStream?.getAudioTracks();

            if (!audioTracks?.length && !videoTracks?.length) {
                publishScreenshare(false);
            } else {
                if (audioTracks?.length) {
                    setAudioTrack(audioTracks[0]);
                    // getScreenshare().setAudioTrack(audioTracks[0]);
                }
                captureStream.oninactive = () => {
                    if (isScreenshareActiveRef.current) {
                        publishScreenshare(false);
                    }
                };
                captureStream.onremovetrack = () => {
                    if (isScreenshareActiveRef.current) {
                        publishScreenshare(false);
                    }
                };
                if (videoTracks?.length) {
                    videoTracks[0].addEventListener('ended', () => {
                        if (isScreenshareActiveRef.current) {
                            publishScreenshare(false);
                        }
                    });
                    videoTracks[0].addEventListener('inactive', () => {
                        if (isScreenshareActiveRef.current) {
                            publishScreenshare(false);
                        }
                    });
                    captureStream.addEventListener('inactive', () => {
                        if (isScreenshareActiveRef.current) {
                            publishScreenshare(false);
                        }
                    });
                    setVideoTrack(videoTracks[0]);
                }
                setCaptureStreamMedia(captureStream);
            }
        } catch (err) {
            publishScreenshare(false);
            setCaptureStreamMedia(null);
            setAudioTrack(null);
            setVideoTrack(null);
        }
        return captureStream;
    }

    useEffect(() => {
        if (requestScreenShare !== requestScreenShareRef?.current) {
            requestScreenShareRef.current = requestScreenShare;

            if (requestScreenShare) {
                startCapture();
            } else {
                if (captureStreamMedia) {
                    try {
                        captureStreamMedia.getTracks().forEach(track => {
                            track.stop();
                        });
                    } catch (e) {
                        //
                    }
                    /*
                    captureStreamMedia.getVideoTracks().forEach(track => {

                    });
                    captureStreamMedia.getAudioTracks().forEach(track => {
                        try {
                            track.stop();
                        } catch (e) {
                            //
                        }
                    });
                    */
                }
                /*
                if (audioTrack) {
                    try {
                        audioTrack.stop();
                    } catch (e) {
                        //
                    }
                }
                if (videoTrack) {
                    try {
                        videoTrack.stop();
                    } catch (e) {
                        //
                    }
                }
                */
                setCaptureStreamMedia(null);
                setAudioTrack(null);
                setVideoTrack(null);
            }
        }
    }, [requestScreenShare]);

    if (sessionHelper) {
        return (
            <div className="presentation-screen-publisher">
                {connectionType === 'one-to-one' && (
                    <>
                        <h3 className="presentation-screen-publisher__title">Prospect Screen</h3>
                        {!!getOtherNonAdminConnections()?.length && (
                            <div className="presentation-screen-publisher__info">
                                <p className="presentation-screen-publisher__info-item">
                                    Window size:{' '}
                                    {othersScreenSize?.windowWidth &&
                                    othersScreenSize?.windowHeight ? (
                                        <strong>
                                            {othersScreenSize.windowWidth}X
                                            {othersScreenSize.windowHeight}px
                                        </strong>
                                    ) : (
                                        <strong>unknown</strong>
                                    )}
                                </p>
                                <p className="presentation-screen-publisher__info-item">
                                    Relevant size:{' '}
                                    {othersScreenSize?.videoSpaceWidth &&
                                    othersScreenSize?.videoSpaceHeight ? (
                                        <strong>
                                            {othersScreenSize.videoSpaceWidth}X
                                            {othersScreenSize.videoSpaceHeight}px
                                        </strong>
                                    ) : (
                                        <strong>unknown</strong>
                                    )}
                                </p>
                                <p className="presentation-screen-publisher__info-item">
                                    Sidebar toggled:{' '}
                                    {prospectSidebarOpened === null ||
                                    prospectSidebarOpened === undefined ? (
                                        <strong>unknown</strong>
                                    ) : (
                                        <strong>{prospectSidebarOpened ? 'On' : 'Off'}</strong>
                                    )}
                                </p>
                            </div>
                        )}
                    </>
                )}
                <div className="presentation-screen-publisher__screen">
                    <div
                        className="presentation-screen-publisher__screen-border"
                        data-sidebar={prospectSidebarOpened ? '1' : '0'}
                    >
                        <div
                            ref={userScreenRef}
                            className="presentation-screen-publisher__screen-video"
                        >
                            {/* render screenshare publisher */}
                            {!!isScreenshareActive && !isOtherAdminSharing && (
                                <OTPublisher
                                    className="presentation-screen-publisher__screen-video-view"
                                    session={sessionHelper.session}
                                    ref={otScreenshare}
                                    eventHandlers={{
                                        /*
                                        videoDimensionsChanged: data => {
                                            console.log('media videoDimensionsChanged');
                                            console.log(data);
                                        },
                                        mediaStopped: data => {
                                            console.log('media mediaStopped');
                                            console.log(data);
                                        },
                                        */
                                        streamDestroyed: data => {
                                            const { stream } = { ...data };
                                            const { connection, name, videoType } = { ...stream };

                                            if (
                                                name === 'screenshare' &&
                                                videoType !== 'camera' &&
                                                getMyConnection()?.connectionId === connection.id
                                            ) {
                                                publishScreenshare(false);
                                            }
                                        }
                                    }}
                                    properties={{
                                        name: 'screenshare',
                                        width: '100%',
                                        height: '100%',
                                        fitMode: 'contain',
                                        videoSource: videoTrack,
                                        audioSource: audioTrack,
                                        enableStereo: true,
                                        audioBitrate: 64000,
                                        disableAudioProcessing: true,
                                        publishVideo: true,
                                        publishAudio: true,
                                        showControls: false
                                    }}
                                />
                            )}
                            {/* render screenshare subscriber */}
                            {isOtherAdminSharing && !!otherAdminScreenStreams?.length && (
                                <OTSubscriber
                                    className="presentation-screen-publisher__screen-video-view"
                                    session={sessionHelper.session}
                                    stream={otherAdminScreenStreams[0]}
                                    properties={{
                                        name: 'screenshare-view',
                                        width: '100%',
                                        height: '100%',
                                        fitMode: 'contain',
                                        subscribeToAudio: false,
                                        audioVolume: 0,
                                        // insertDefaultUI: false,
                                        showControls: false
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="presentation-screen-publisher__screen-monitor">
                        <Svg icon="monitor" />
                    </div>
                    {!!isOtherAdminSharing && !!deviceSupportsScreenShare && (
                        <p className="presentation-screen-publisher__screen-message">
                            Screen share is being published from another window.
                        </p>
                    )}
                    {!isOtherAdminSharing && !deviceSupportsScreenShare && (
                        <p className="presentation-screen-publisher__screen-message">
                            Screen Sharing is not supported on this device.
                        </p>
                    )}
                    {!isOtherAdminSharing && !!deviceSupportsScreenShare && (
                        <Button
                            className="presentation-screen-publisher__screen-start presentation-screen-publisher__screen-start--action-start"
                            size="small"
                            label={isScreenshareActive ? 'Stop Screen Share' : 'Start Screen Share'}
                            onClick={() => publishScreenshare()}
                        />
                    )}
                    {!!isOtherAdminSharing && !!deviceSupportsScreenShare && (
                        <Button
                            className="presentation-screen-publisher__screen-start presentation-screen-publisher__screen-start--action-take-over"
                            size="small"
                            label="Take over Screen Share"
                            onClick={() => publishScreenshare()}
                        />
                    )}
                </div>
            </div>
        );
    }

    return null;
};

PresentationScreenPublisher.defaultProps = {
    sessionHelper: null,
    isScreenshareActive: false,
    getMyConnection: () => {},
    publishScreenshare: () => {},
    getOtherNonAdminConnections: () => {},
    connectionType: null
};

PresentationScreenPublisher.propTypes = {
    sessionHelper: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isScreenshareActive: PropTypes.bool,
    getMyConnection: PropTypes.oneOfType([PropTypes.func]),
    publishScreenshare: PropTypes.oneOfType([PropTypes.func]),
    getOtherNonAdminConnections: PropTypes.oneOfType([PropTypes.func]),
    connectionType: PropTypes.string
};

export default PresentationScreenPublisher;
