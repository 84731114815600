import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getPresentationConnectionUsers } from 'erpcore/screens/Presentation/Presentation.selectors';

import './ProspectList.scss';
import StatusCircle from 'erpcore/components/StatusCircle';

const ProspectList = ({ sessionData }) => {
    const presentationConnectionUsers = useSelector(getPresentationConnectionUsers);

    const { session_prospects: sessionProspects } = {
        ...sessionData
    };

    if (sessionProspects?.length) {
        const sortedProspects = [...sessionProspects]
            .sort((a, b) => {
                const { hash: aHash } = { ...a };
                const { hash: bHash } = { ...b };
                const aIsOnline = !!presentationConnectionUsers.find(connectionUser => {
                    return (
                        connectionUser.user === aHash &&
                        ['prospect', 'guest', 'spectator'].includes(
                            connectionUser?.connectionUserType
                        )
                    );
                });
                const bIsOnline = !!presentationConnectionUsers.find(connectionUser => {
                    return (
                        connectionUser.user === bHash &&
                        ['prospect', 'guest', 'spectator'].includes(
                            connectionUser?.connectionUserType
                        )
                    );
                });

                if (aIsOnline && !bIsOnline) return -1;

                if (!aIsOnline && bIsOnline) return 1;

                return 0;
            })
            .map(item => {
                const { prospect, hash } = { ...item };
                const { first_name: firstName = '', last_name: lastName = '' } = {
                    ...prospect
                };
                const isOnline = !!presentationConnectionUsers.find(connectionUser => {
                    return (
                        connectionUser.user === hash &&
                        ['prospect', 'guest', 'spectator'].includes(
                            connectionUser?.connectionUserType
                        )
                    );
                });
                return {
                    name: `${firstName} ${lastName}`,
                    isOnline
                };
            });

        return (
            <div className="prospect-list">
                {sortedProspects.map(item => {
                    return (
                        <div className="prospect-list__item">
                            <p className="prospect-list__item-name">{item?.name}</p>
                            <StatusCircle type={item?.isOnline ? 'approved' : 'rejected'} />{' '}
                        </div>
                    );
                })}
            </div>
        );
    }

    return null;
};

ProspectList.defaultProps = {
    sessionData: null
};

ProspectList.propTypes = {
    sessionData: PropTypes.oneOfType([PropTypes.object])
};

export default ProspectList;
