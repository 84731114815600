import React from 'react';
import PageContent from 'erpcore/components/Layout/PageContent';
import SubNavigation from 'erpcore/components/Layout/SubNavigation';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import slugify from 'react-slugify';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { getEntityUrl, getProjectRoutePaths } from 'erpcore/utils/RouterManager';
import Gallery from 'erpcore/components/Gallery';
import PropTypes from 'prop-types';

const ProjectFloorplansFromUnits = ({ projectData }) => {
    const match = useRouteMatch();
    let { key_plans: keyPlans = [], unit_types: unitTypes = [] } = { ...projectData };

    keyPlans = keyPlans.filter(keyPlan => !keyPlan.deleted);
    unitTypes = unitTypes.filter(unitType => !unitType.deleted);

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    const sortedFloorplansPerUnitType = (() => {
        const units = {};

        if (unitTypes?.length) {
            unitTypes.forEach(unit => {
                const { unit_type: unitType, floor_plan: floorPlan, name: unitTypeName } = {
                    ...unit
                };
                const unitTypeIri = unitType?.iri;

                if (!units?.[unitTypeIri]) {
                    Object.assign(units, {
                        [unitTypeIri]: {
                            unitInfo: { ...unitType },
                            floorPlans: []
                        }
                    });
                }

                if (units?.[unitTypeIri]) {
                    units[unitTypeIri].floorPlans.push({ ...floorPlan, unitTypeName });
                }
            });

            return units;
        }

        return units;
    })();

    return (
        <PageContent.Columns>
            <PageContent.SideMenu>
                <SubNavigation>
                    {!!keyPlans.length && (
                        <Menu.Submenu
                            label="Site plan"
                            className="main-navigation__menu-item--open"
                        >
                            {keyPlans?.map((item, index) => (
                                <Menu.SubmenuItem
                                    to={projectUrl(
                                        `/floorplans${
                                            index > 0 ? `/site-plan/${slugify(item.name)}` : ''
                                        }`
                                    )}
                                    label={item.name}
                                    key={slugify(item.name)}
                                    exact
                                />
                            ))}
                        </Menu.Submenu>
                    )}
                    {Object.keys(sortedFloorplansPerUnitType)?.map(item => (
                        <Menu.Submenu label={sortedFloorplansPerUnitType?.[item]?.unitInfo?.name}>
                            {sortedFloorplansPerUnitType?.[item]?.floorPlans?.map(floorPlan => (
                                <Menu.SubmenuItem
                                    exact
                                    to={projectUrl(
                                        `/floorplans/${slugify(floorPlan?.unitTypeName)}`
                                    )}
                                    label={floorPlan?.unitTypeName}
                                    key={slugify(floorPlan?.iri)}
                                />
                            ))}
                        </Menu.Submenu>
                    ))}
                </SubNavigation>
            </PageContent.SideMenu>
            <PageContent.Column>
                <Switch>
                    {keyPlans?.map((item, index) => {
                        const { key_plan: keyPlan } = { ...item };
                        return (
                            <Route
                                exact
                                path={getProjectRoutePaths(
                                    `/floorplans${
                                        index > 0 ? `/site-plan/${slugify(item.name)}` : ''
                                    }`
                                )}
                                key={slugify(item.name)}
                            >
                                <Gallery
                                    name={`sitePlanGallery-${index}`}
                                    imagesIris={[keyPlan?.iri]}
                                />
                            </Route>
                        );
                    })}
                    {Object.keys(sortedFloorplansPerUnitType)?.map((item, index1) => {
                        return sortedFloorplansPerUnitType?.[item]?.floorPlans?.map(
                            (floorPlan, index2) => (
                                <Route
                                    exact
                                    path={getProjectRoutePaths(
                                        `/floorplans/${slugify(floorPlan.unitTypeName)}`
                                    )}
                                    key={slugify(floorPlan.unitTypeName)}
                                >
                                    <Gallery
                                        name={`florplans-${index1}-${index2}`}
                                        imagesIris={[floorPlan?.iri]}
                                    />
                                </Route>
                            )
                        );
                    })}
                </Switch>
            </PageContent.Column>
        </PageContent.Columns>
    );
};

ProjectFloorplansFromUnits.defaultProps = {
    projectData: {}
};

ProjectFloorplansFromUnits.propTypes = {
    projectData: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectFloorplansFromUnits;
