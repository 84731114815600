import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { OTSubscriber } from 'opentok-react';
import './PresentationCameraSubscriber.scss';
import Tooltip from 'erpcore/components/Tooltip';
import Svg from 'erpcore/components/Svg';

const PresentationCameraSubscriber = ({
    style,
    type,
    sessionHelper,
    stream,
    subscriberClassName,
    isPinned,
    setPinnedStream,
    unsetPinnedStream
}) => {
    const emitMuteSelf = connection => {
        if (sessionHelper?.session) {
            sessionHelper.session.signal({
                to: connection,
                data: '',
                type: 'muteSelf'
            });
        }
    };

    return (
        <div
            className={`presentation-camera ${subscriberClassName}`}
            data-stream-id={stream?.id}
            style={style}
        >
            <OTSubscriber
                className="presentation-camera__ot"
                session={sessionHelper.session}
                stream={stream}
                properties={{
                    style: {
                        buttonDisplayMode: 'off',
                        nameDisplayMode: 'on'
                    }
                }}
            />
            {type === 'admin' && (
                <div className="presentation-camera__controls">
                    <Tooltip
                        className="presentation-camera__controls-tooltip-pin"
                        direction="up"
                        content={isPinned ? 'Unpin' : 'Pin'}
                    >
                        <button
                            className="presentation-camera__controls-button"
                            type="button"
                            onClick={() => (isPinned ? unsetPinnedStream() : setPinnedStream())}
                            aria-label={isPinned ? 'Unpin' : 'Pin'}
                        >
                            <Svg
                                className="presentation-camera__controls-button-icon"
                                icon={isPinned ? 'pinOff' : 'pin'}
                            />
                        </button>
                    </Tooltip>
                    <Tooltip
                        direction="up"
                        content={stream?.hasAudio ? 'Mute' : "You can't unmute someone else"}
                    >
                        {stream?.hasAudio && (
                            <button
                                className="presentation-camera__controls-button"
                                type="button"
                                onClick={() => emitMuteSelf(stream?.connection)}
                                aria-label="Mute"
                            >
                                <Svg
                                    className="presentation-camera__controls-button-icon"
                                    icon="mic"
                                />
                            </button>
                        )}
                        {!stream?.hasAudio && (
                            <div
                                className="presentation-camera__controls-button presentation-camera__controls-button--non-clickable"
                                aria-label="You can't unmute someone else"
                            >
                                <Svg
                                    className="presentation-camera__controls-button-icon"
                                    icon="micOff"
                                />
                            </div>
                        )}
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

PresentationCameraSubscriber.defaultProps = {
    style: {},
    type: null,
    sessionHelper: null,
    stream: null,
    subscriberClassName: null,
    isPinned: false,
    setPinnedStream: () => {},
    unsetPinnedStream: () => {}
};

PresentationCameraSubscriber.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object]),
    type: PropTypes.oneOf(['client', 'admin']),
    sessionHelper: PropTypes.oneOfType([PropTypes.object]),
    stream: PropTypes.oneOfType([PropTypes.object]),
    subscriberClassName: PropTypes.string,
    isPinned: PropTypes.bool,
    setPinnedStream: PropTypes.func,
    unsetPinnedStream: PropTypes.func
};

export default memo(PresentationCameraSubscriber);
