import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';

import ProjectPageHeader from 'erpcore/screens/Projects/components/ProjectPageHeader';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { getImageSrc } from 'erpcore/utils/utils';
import MobileQuickNav from 'erpcore/components/Layout/Sidebar/components/MobileQuickNav';
import Image from 'erpcore/components/Image';

// eslint-disable-next-line no-unused-vars
export const isAvailable = projectData => {
    return true;
};

const ProjectDeveloper = () => {
    const reducerName = 'projects';
    const actionName = 'PROJECTS';

    const match = useRouteMatch();
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const fetching = useSelector(getProjectFetching);
    const { client = {} } = { ...projectData };

    const fetchProjects = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params: {
                    pagination: false
                },
                entity: actionName,
                name: reducerName,
                endpoint: `/api/projects?include=client,client.logoImage.versions,city,state,country,featuredImage,featuredImage.versions&filters[client][in][]=${projectData?.client?.iri}`
            });
        }).catch(error => {
            return error;
        });
    };

    useEffect(() => {
        if (projectData?.client?.iri) {
            fetchProjects();
        }
    }, [projectData?.client?.iri]);

    const pageTitle = 'Developer';

    const { logo_image: logoImage = '' } = client;
    const clientLogoImage = getImageSrc(logoImage, 'medium');

    const activeProjects = listing.data.filter(project => {
        return project.is_active === true;
    });

    const previousProjects = listing.data.filter(project => {
        return project.is_active !== true;
    });

    const renderProjectCard = project => {
        const { featured_image: featuredImage = {} } = project;

        return (
            <div className="projects__item projects__item--no-link" key={project.id}>
                {featuredImage?.iri ? (
                    <Image
                        isContainer
                        version="medium"
                        iri={featuredImage?.iri}
                        className="projects__item-image"
                        fallback={<div className="projects__item-image" />}
                    />
                ) : (
                    <div className="projects__item-image" />
                )}
                <div className="projects__item-content">
                    <span className="projects__item-location">
                        {project?.city?.name}
                        {project?.state?.name ? `/${project?.state?.name}` : ''}
                    </span>
                    <span className="projects__item-name">{project.name}</span>
                    <span className="projects__item-type">{project?.street}</span>
                </div>
            </div>
        );
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={pageTitle} />
            <ProjectPageHeader auto section="developer" />
            <MobileQuickNav section="developer" />
            <PageContent>
                {(fetching === true || listingFetching === true) && <PageLoader content />}
                <div className="projects">
                    {clientLogoImage && (
                        <div className="projects__developer-logo">
                            <img src={clientLogoImage} alt={client?.name} />
                        </div>
                    )}
                    <p
                        className="projects__subtitle"
                        dangerouslySetInnerHTML={{
                            __html: client?.description
                        }}
                    ></p>
                    {client.website && (
                        <p>
                            <a href={client.website} target="_blank" rel="noopener noreferrer">
                                Developer Website
                            </a>
                        </p>
                    )}
                    {activeProjects.length > 0 && (
                        <>
                            <p className="projects__subtitle">Active Projects</p>
                            <div className="projects__item-list">
                                {!listingFetching &&
                                    activeProjects.map(project => {
                                        return renderProjectCard(project);
                                    })}
                            </div>
                        </>
                    )}
                    {previousProjects.length > 0 && (
                        <>
                            <p className="projects__subtitle">Previous Projects</p>
                            <div className="projects__item-list">
                                {!listingFetching &&
                                    previousProjects.map(project => {
                                        return renderProjectCard(project);
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProjectDeveloper.defaultProps = {};

ProjectDeveloper.propTypes = {};

export default ProjectDeveloper;
