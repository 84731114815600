import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, matchPath, NavLink } from 'react-router-dom';

import Svg from 'erpcore/components/Svg';

import LayoutManager from 'erpcore/utils/LayoutManager';
import { getSidebarShowDeck } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';
import { actions as layoutManagerActions } from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import { getProjectData } from 'erpcore/screens/Projects/Projects.selectors';
import { getEntityUrl } from 'erpcore/utils/RouterManager';
import { getPresentationSessionDataByHash } from 'erpcore/screens/Presentation/Presentation.selectors';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getProjectSectionsData } from 'erpcore/utils/navigationManager';
import MobileMenu from './components/MobileMenu/MobileMenu';
import './Sidebar.scss';

const Sidebar = ({ className, layout }) => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const showDeck = useSelector(getSidebarShowDeck);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const meData = useSelector(getMeData) || {};
    const userType = meData?._type;
    const projectIri = `/api/projects/${match?.params?.project}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const sessionDatabyHash =
        useSelector(state =>
            getPresentationSessionDataByHash(state, match?.params?.prospectHash)
        ) || {};

    const projectSections = getProjectSectionsData(
        projectData,
        match?.params?.prospectHash,
        sessionDatabyHash
    );

    const projectUrl = route => {
        return getEntityUrl({
            project: match?.params?.project,
            session: match?.params?.session,
            prospectHash: match?.params?.prospectHash,
            route
        });
    };

    /**
     * Used in multiple functions to update Redux state value
     * @param {boolean} value
     */
    const updateSidebarDeckState = value => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: layoutManagerActions.UPDATE_SIDEBAR_DECK_STATE,
                response: value
            })
        ).catch(error => {
            return console.error(error);
        });
    };

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Set Deck to hidden/colsed on mobile
        const isMobile = window && window.innerWidth < 1030;
        if (isMobile) {
            updateSidebarDeckState(false);
        }
    }, []);

    /**
     * Used for the click on the overlay
    const hideAll = () => {
        setShowProfileMenu(false);
        return updateSidebarDeckState(false);
    };
     */

    /**
     * Used by the hamburger button
     */
    const handleDeck = () => {
        setShowProfileMenu(false);
        return updateSidebarDeckState(!showDeck);
    };

    const getMenuItemClass = (pathname, additionalCssClass) => {
        let menuItemClassName = `sidebar__bolt-top-menu-icon ${additionalCssClass || ''}`;

        const matched = matchPath(pathname, match);
        if (
            // standard check
            matched?.isExact ||
            // or if pathname param equals project home and demo-tour-overview is current page
            (pathname === projectUrl() && match.url === projectUrl('/demo-tour-overview'))
        ) {
            menuItemClassName = `sidebar__bolt-top-menu-icon ${additionalCssClass || ''} active`;
        }

        return menuItemClassName;
    };

    return (
        <aside
            className={`sidebar ${showDeck ? 'sidebar--show-deck' : ''} ${
                showProfileMenu ? 'sidebar--profile-opened' : ''
            } ${className || ''}`}
        >
            {/*
            <button
                type="button"
                className="sidebar__mobile-overlay"
                onClick={() => hideAll()}
                onKeyPress={() => hideAll()}
            />
            */}
            <div className="sidebar__content">
                <MobileMenu />
                <div className="sidebar__bolt">
                    <ul className="sidebar__bolt-top">
                        <li className="sidebar__bolt-top-hamburger">
                            <button
                                type="button"
                                className="sidebar__button sidebar__button--hamburger"
                                onClick={() => handleDeck()}
                                onKeyPress={() => handleDeck()}
                            >
                                <Svg icon="menu" />
                            </button>
                        </li>
                        {!!Object.keys(projectSections)?.length &&
                            Object.keys(projectSections).map((sectionKey, i) => {
                                const section = projectSections[sectionKey];
                                if (section?.isNavItem && section?.isAvailable) {
                                    return (
                                        <li
                                            key={section.route}
                                            className={getMenuItemClass(
                                                projectUrl(section.route),
                                                i === 0
                                                    ? 'sidebar__bolt-top-menu-icon--first'
                                                    : null
                                            )}
                                        >
                                            <NavLink
                                                exact={i === 0}
                                                to={projectUrl(section.route)}
                                                className="sidebar__button"
                                            >
                                                <Svg icon={section.icon} />
                                                <span className="sidebar__bolt-top-menu-icon-text">
                                                    {section.title}
                                                </span>
                                            </NavLink>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                    </ul>
                    <ul className="sidebar__bolt-bottom">
                        <li className="sidebar__bolt-bottom-item">
                            <NavLink
                                to={getEntityUrl({
                                    session: match?.params?.session,
                                    prospectHash: match?.params?.prospectHash
                                })}
                                className="sidebar__button"
                                exact
                            >
                                <Svg icon="arrowLeft" />
                                <span className="sidebar__bolt-top-menu-icon-text">
                                    Back To Projects
                                </span>
                            </NavLink>
                        </li>
                        {userType !== 'prospect' && (
                            <li className="sidebar__bolt-bottom-item">
                                <NavLink
                                    to={getEntityUrl({
                                        route: '/sign-out'
                                    })}
                                    className="sidebar__button"
                                    exact
                                >
                                    <Svg icon="signOut" />
                                    <span className="sidebar__bolt-top-menu-icon-text">
                                        Sign Out
                                    </span>
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="sidebar__deck">
                    <LayoutManager layout={layout} />
                </div>
            </div>
        </aside>
    );
};

Sidebar.defaultProps = {
    layout: null,
    className: ''
};

Sidebar.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object]),
    className: PropTypes.string
};

export default Sidebar;
